import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Quoet from "./components/Quoet";
import NewMembers from "./components/NewMembers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/LoginRoute";
import Hero from "./pages/Hero";
import LoginRoute from "./pages/LoginRoute";
import Otp from "./components/Otp";
import OtpVerification from "./pages/OtpVerification";
import LoginRoute2 from "./pages/LoginRoute2";
import CreateAccPG from "./pages/CreateAccPG";
import CmpProfPG from "./pages/CmpProfPG";
import CmpContPG from "./pages/CmpContPG";
import DocPG from "./pages/DocPG";
import VerificationPage from "./pages/VerificationPage";
import CreateQutPG from "./pages/CreateQutPG";
import Dashboard from "./layouts/Dashboard";
import TranspotDashboard from "./layouts/TransporterDashboard";
import MyProfile from "./pages/MyProfile";
import EditProfile from "./pages/EditProfile";
import RequestQuoteCompany from "./pages/RequestQuoteCompany";
import RequestQuote from "./pages/RequestQuote";
import Directory from "./pages/Directory";
import Overview from "./pages/Overview";
import QuotationDetails from "./pages/QuotationDetails";
import CreateAcc from "./components/CreateAcc";
import CreateQuoteLCL from "./components/CreateQuoteLCL";
import CreateQuoteAir from "./components/CreateQuoteAir";
import QuotationList from "./pages/QuotationList";
import GivenQuotationList from "./pages/GivenQuotationList";
import ModalQuotationDetails from "./components/ModalQuotationDetails";
import Logout from "./pages/Logout";
import CreateQuotation from "./pages/CreateQuotation";
import ConfirmQuotation from "./pages/confirmQuotation";
import QuotationRequest from "../src/pages/QuotationRequest";
import Notification from "../src/pages/Notification";
import InquiryHub from "../src/pages/InquiryHub";
import DirectoryPage from "../src/pages/DirectoryPage";
import ForgetPassword from '../src/pages/ForgetPassword';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/notification" element={<Notification />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/inquiry-hub" element={<InquiryHub />}></Route>
        <Route path="/directory" element={<DirectoryPage />}></Route>
        <Route
          path="/inquiry-hub/:qr_id/details"
          element={<ModalQuotationDetails />}
        ></Route>
        <Route path="/overview" element={<Overview />}></Route>
        <Route path="/login" element={<LoginRoute />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/otp-verification" element={<OtpVerification />}></Route>
        <Route path="/create-account" element={<CreateAccPG />}></Route>
        <Route path="/company-profile" element={<CmpProfPG />}></Route>
        <Route path="/company-contact" element={<CmpContPG />}></Route>
        <Route path="/document" element={<DocPG />}></Route>
        <Route
          path="/quotation-request/:qr_id/create-quotation"
          element={<CreateQuotation />}
        ></Route>
        <Route path="/create-quote-fcl" element={<CreateQutPG />}></Route>
        <Route path="/create-quote-air" element={<CreateQuoteAir />}></Route>
        <Route path="/create-quote-lcl" element={<CreateQuoteLCL />}></Route>
        <Route path="/verify/:action/:token" element={<VerificationPage />} />

        {/* dashboard */}
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="profile" element={<MyProfile />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path=":qr_id/quotation" element={<QuotationList />} />
          <Route path="request-quote" element={<RequestQuote />} />
          <Route path="quotation-request" element={<QuotationRequest />} />
          <Route path="directory" element={<Directory />} />
          <Route path="quotation" element={<GivenQuotationList />} />
        </Route>
        <Route
          path="/quotation-request-deatils"
          element={<ModalQuotationDetails />}
        />
      </Routes>
    </>
  );
}

export default App;
