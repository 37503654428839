    import React, { useState, useEffect } from 'react'
    import Select from 'react-select'
    import makeAnimated from 'react-select/animated';


    const Dropdown = ({ value, options, className, onChange}) => {
        const animatedComponents = makeAnimated();
        const [selectedOptions, setSelectedOptions] = useState(null);

        // const handleSelectChange = (event) => {
        //     const selectedOption = options.find(option => option.value == event.target.value);
        //     onChange(selectedOption);
        // };


        const [selectedOption, setSelectedOption] = useState(value);
        const [otherInput, setOtherInput] = useState('');

        const handleSelectChange = (event) => {
            const selectedValue = event.target.value;
            setSelectedOption(selectedValue);

            // If "Other" is selected, handle accordingly
            if (selectedValue === 'other') {
                setOtherInput(''); // Reset input field
            } else {
                const selectedOption = options.find(option => option.value === selectedValue);
                onChange(selectedOption);
            }
        };

        const handleOtherInputChange = (event) => {
            setOtherInput(event.target.value);
        };




        return (
            // <div className='w-full'  >
            //     <select name="selectedFruit"
            //         className={className}
            //         onChange={handleSelectChange}>
            //         {options.map((option) => (
            //             <option key={option.value} value={option.value}>
            //                 {option.label}
            //             </option>
            //         ))}
            //     </select>
            //     {/* <Select
            //         options={options}
            //         components={animatedComponents}
            //         isMulti
            //     /> */}

            // </div>


            <div className='w-full'>
                <select
                    name="selectedOption"
                    className={className}
                    value={selectedOption}
                    onChange={handleSelectChange}
                >
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {/* {selectedOption === 'Other' && (
                    <div>
                        <input
                            type="text"
                            value={otherInput}
                            onChange={handleOtherInputChange}
                            placeholder="Enter other option"
                        />
                    </div>
                )} */}
            </div>
        )
    }

    export default Dropdown