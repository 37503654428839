import React from 'react'
import Otp from '../components/Otp'
import logo from '../assets/LogoFinal.png'
import { Outlet, useNavigate } from 'react-router-dom'

const OtpVerification = () => {
    const navigate = useNavigate();
    return (
        <div className='w-screen h-screen flex flex-col gap-[90px] py-[20px] px-[20px] xs:gap-[90px] sm:gap-[100px]' >
            <div onClick={() => navigate("/")} className="flex w-full flex-row  sm:justify-start ">
                <div className="flex flex-row items-center justify-center ">
                    <img src={logo} alt="logo" className='w-[24px] h-[24px]' />
                    <h1 className='text-logoText text-[17px] font-bold '>FreightOpedia</h1>
                </div>
            </div>

            <Otp />
            <Outlet />


        </div>
    )
}

export default OtpVerification