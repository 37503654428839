import React from 'react'

import { GoArrowRight } from "react-icons/go";
import Button from './Button';
import { FiMail } from "react-icons/fi";
import { MdOutlinePhone } from "react-icons/md";


const Footer = () => {
    return (

        // <div className='bg-[url("/src/assets/footer.jpg")] w-full h-full md:h-[435px] bg-no-repeat bg-center bg-cover  '>
        //     <div className="flex flex-col gap-5 relative">
        //         <div className="absolute inset-0 bg-black bg-opacity-60 md:w-full md:h-[435px]">
        //             <div className="md:px-[200px] flex flex-col md:gap-[100px]">


        //                 <div className="flex md:flex-row justify-between items-start mt-[50px]">
        //                     <div className="flex flex-col gap-3">
        //                         <h1 className='font text-white text-[45px] text-opacity-100 tracking-widest leading-[45px]  '>FREIGHTOPEDIA</h1>
        //                         <p className='text-[18px] text-[#ffffffcc] tracking-wider '>At Freightopedia, we believe in providing our <br /> clients with personalized and efficient <br /> logistics solutions that meet their unique <br /> needs.</p>
        //                     </div>



        //                     <div className="flex flex-col text-white ">
        //                         <h1>Contact us</h1>
        //                         <div className="flex flex-row items-center  gap-2">
        //                             <FiMail />
        //                             <h1>Connect.freightopedia@gmail.com</h1>
        //                         </div>

        //                         <div className="flex flex-row items-center  gap-2">
        //                             <MdOutlinePhone />
        //                             <h1>+919082345757</h1>
        //                         </div>

        //                     </div>
        //                 </div>

        //                 <div className="flex flex-col md:flex-row justify-between border-dashed border-t-[2px] py-5 px-[2px]  ">
        //                     <p className='font text-[18px] text-white font-medium'>COPYRIGHT © 2024 <span className='font-bold' >FREIGHTOPEDIA</span> </p>
        //                     <p className='font text-[18px] text-white font-medium'>DESIGNED BY JAY RAJPUT</p>
        //                 </div>
        //             </div>
        //         </div>



        //     </div>


        // </div>
        // w-full h-[430px] relative md:h-[250px]
        <div className='bg-[url("/src/assets/footer.jpg")] w-full h-fit bg-no-repeat bg-center bg-cover '>
            <div className="h-full bg-black bg-opacity-60 px-5 py-5 ">
                <div className=" flex flex-col gap-5 lg:mx-10 xl:mx-20">
                    <div className="flex flex-col items-center md:flex-row md:justify-between md:items-start md:w-full gap-8 ">
                        <div className="flex flex-col items-center justify-center gap-3 md:items-start ">
                            <h1 className='text-[20px] lg:text-[45px]  text-white  text-opacity-100 tracking-widest  font-bold'>FREIGHTOPEDIA</h1>
                            <p className=' text-center md:text-start text-[#ffffffcc] tracking-wider md:w-[390px] '>At Freightopedia, we believe in providing our  clients with personalized and efficient logistics solutions that meet their unique  needs.</p>
                        </div>

                        <div className="flex flex-col items-center justify-center md:items-start md:justify-start gap-2 text-white  ">
                            <h1 className='text-[20px]'>Contact us</h1>
                            <div className="flex flex-row items-center  gap-2">
                                <FiMail />
                                <h1 className='font-bold' >freightopedia@freightopedia.world</h1>
                            </div>
                            <div className="flex flex-row items-center  gap-2">
                                <MdOutlinePhone />
                                <h1 className='font-bold'>+917303007513</h1>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center md:flex-row justify-between border-dashed border-t-[2px] py-5 px-[2px]  ">
                        <p className='font text-[18px] text-white font-medium text-center md:text-start '>COPYRIGHT © 2024 <span className='font-bold' >FREIGHTOPEDIA</span> </p>
                        {/* <p className='font text-[18px] text-white font-medium text-center md:text-start '>DESIGNED BY JAY RAJPUT</p> */}
                    </div>
                </div>
            </div>

            {/* <div className="flex flex-col md:flex-row justify-between border-dashed border-t-[2px] py-5 px-[2px]  ">
                <p className='font text-[18px] text-white font-medium'>COPYRIGHT © 2024 <span className='font-bold' >FREIGHTOPEDIA</span> </p>
                <p className='font text-[18px] text-white font-medium'>DESIGNED BY JAY RAJPUT</p>
            </div> */}


        </div>
    )
}

export default Footer