import React from 'react'

const AboutUs = () => {
  return (
    <div className=' bg-cover bg-center w-5/6 rounded-md h-screen mx-[100px] bg-[url("https://s3-alpha-sig.figma.com/img/ad4e/fa78/923b12db16d1107da9cafa2e697af149?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FNPYGwoFgFDU8phfBxUEk66OLFITeYqKG9eQuytCBoWlbCzexlLIMBK8veGEIkkDGUqluai1xrZf01d3BkOK0TJT60kUyLl5tbqPZ3W179tBM68arwBMszbb6CH5WKrq1v-2iu2163BjszNPWaHvdbTjTAh3GzB4wur7a1yeti6aGgC9mOUxVygZNGYKMa9gr5RqXUW6UH2Ql7Ct9sVFWU6mFKAhgVfKyopPidA9HU4YMIJIkiZzOfTPEBKb9SJLBQcvkoeQll8-9e~5JiRP9qPCkwAj8DUH3pOUg0i1L8YPa3ejHD2wjvHA2xKi6XBlCzWxntMZ~Xb~H61-Ers1hA__")]'>
      <div className=" w-full h-full rounded-md flex flex-col justify-center bg-black/75">
        {/* <div className="w-full h-full bg-black/75 rounded-md "></div> */}
        <div className="text-white flex flex-col gap-5 justify-center px-[100px]">
          <h1 className="text-[25px]">About us</h1>
          <h1 className='font-bold text-[50px] leading-[70px]'>We Provide <span className='text-[#4EAEFC]'>Safe</span> And <br /> <span className='text-[#4EAEFC]'>Reliable</span> Cargo Solution</h1>
          <p>Our logistics service provides comprehensive cargo solutions, <br/> efficiently managing the entire supply chain from origin to <br/> destination with precision and reliability.</p>
        </div>
      </div>
    </div>
  )
}

export default AboutUs