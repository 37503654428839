import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/LogoFinal.png'
import { Link as RouterLink } from 'react-router-dom';

import forgotPassImg from '../assets/forgotPassword2.jpeg'
import Button from '../components/Button';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';

const ForgetPassword = () => {
    const [email, setEmail] = useState();
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [formError, setFormError] = useState("");
    const [loading, setLoading] = useState(false);


    const handleLogin = () => {
        let valid = true;
        if (!email) {
            setEmailError("Email is required");
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Email address is invalid");
            valid = false;
        } else {
            setEmailError("");
        }

        if (valid) {
            setLoading(true);
            // alert("Email is :" + email)

            axios.post(`https://api.freightopedia.world/api/v1/accounts/forgot-password`, {
                email: email,
            })
                .then(response => {
                    setLoading(false);
                    // handle success
                    alert(response.data.message);

                    if (response.data.success) {
                        window.location = "/login"
                    }
                })
                .catch(error => {
                    setLoading(false);
                    // handle error
                    console.log(error);
                    setFormError("Login failed. Please check your credentials.");
                });
        }
    };
    return (
        <>
            {loading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100  flex items-center justify-center">
                    <div className="w-fit flex flex-col items-center justify-center gap-3 ">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />


                        <h1 className='text-[#142AA2]' >Loading</h1>
                    </div>
                </div>
            }
            <div className='w-screen h-screen flex flex-col gap-[90px] py-[20px] px-[20px] xs:gap-[90px] sm:gap-[100px]' >
                <div onClick={() => navigate("/")} className="flex w-full flex-row  sm:justify-start cursor-pointer ">
                    <div className="flex flex-row items-center justify-center ">
                        <img src={logo} alt="logo" className='w-[18px] h-[20px]' />
                        <h1 className='text-logoText text-[17px] font-bold '>FreightOpedia</h1>
                    </div>
                </div>

                {/* <Login /> */}
                <div className="w-full h-full justify-center p-5 flex flex-row gap-10">
                    <div className="">
                        <img src={forgotPassImg} alt="" className='h-[500px] ' />
                    </div>

                    <div className="rounded-[10px]  px-[15px] text-[20px] flex flex-col gap-5 py-[30px] ">
                        <h1 className='font-bold text-[30px] leading-[33px]' >Forgot <br /> Your Password ? </h1>
                        <div className="flex flex-col gap-2  w-[350px]">
                            <label htmlFor="" className='text-[13px]' >Your Email</label>
                            <input type="text"
                                placeholder='email@gmail.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='border-[#DDDDDD] border-[1px] bg-[#F6F6F6] outline-none text-[13px] placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-1 w-full' />
                            {emailError && <span className='text-red-500 text-[12px]'>{emailError}</span>}
                            <Button onClick={handleLogin} bgColor=" w-full py-2 px-5  lg:px-10 rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">Reset Password</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword