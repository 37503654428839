import React, { useRef, useState } from 'react'
import Button from './Button'
import { GoArrowRight } from "react-icons/go";
import uploadFile from '../assets/uploadFile.png'
import axios from 'axios';
import pdfIcon from '../assets/pdfIcon.png'
import jpgIcon from '../assets/jpgIcon.png'
import uploadICon2 from '../assets/uploadd.jpeg'
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';


const Doc = () => {
    const [filesData, setFilesData] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const inputRefs = {
        adhar_document: useRef(null),
        pan_document: useRef(null),
    };

    const [fileNames, setFileNames] = useState({
        adhar_document: [],
        pan_document: [],
    });



    const handleImageClick = (section) => {
        inputRefs[section].current.click();
    };


    const handleImageChange = (event, section) => {
        const newFiles = Array.from(event.target.files);

        

        const newFileNames = newFiles.map(file => file.name);

        setFileNames(prevFileNames => ({
            ...prevFileNames,
            [section]: prevFileNames[section] ? [...prevFileNames[section], ...newFileNames] : newFileNames
        }));


        const newFileData = newFiles.map(file => file);
        setFilesData(prevFileData => ([
            ...prevFileData, ...newFileData
        ]));


    };


    const renderFileSection = (section) => (
        <div className="flex flex-col items-center justify-center cursor-pointer w-full">
            <div
                onClick={() => handleImageClick(section)}
                className="p-5 w-full border-[#49B5FD] border-dashed border-[1px] flex flex-col items-center justify-center gap-2"
            >
                <img src={uploadFile} alt="" className='w-[50px]' />
                <h1 className='text-[10px]' ><span className='underline'>choose file</span></h1>
            </div>
            <input
                type="file"
                name="files"
                ref={inputRefs[section]}
                onChange={(event) => handleImageChange(event, section)}
                className="cursor-pointer"
                style={{ display: 'none' }}
                multiple
            />
            <div className="flex flex-row justify-between items-center w-full ">
                <h1 className='text-[10px] text-[#595959]' >Supported formats: PDF, JPG, PNG, XLS</h1>
                <h1 className=' text-[10px] text-[#595959]' >Maximum Size: 25 MB </h1>
            </div>
            {fileNames[section].length > 0 && (
                <div className="mt-2 space-y-3  text-[13px]  py-2 px-[10px] ">
                    {fileNames[section].map((fileName, index) => (
                        // <h2 key={index} className="text-[12px]">{fileName}</h2>
                        <div key={index}>
                            <div className="flex flex-row items-center gap-2">
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className='font-semibold'> <span className='font-bold' > ({index + 1})</span> {fileName}</h1>
                                    {/* <h1>{data.DocSize}</h1> */}
                                </div>

                            </div>

                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    const uploadFiles = async () => {
        if (!fileNames.adhar_document.length || !fileNames.pan_document.length) {
            alert("Please fill both file fields");
            return;
        }else {
            setLoading(true);
        }
        await axios.post(`https://api.freightopedia.world/api/v1/accounts/kyc-document`, { documents: filesData }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                // handle success
                if (response.data.success) {
                    setLoading(false);
                    alert("file uploads successfully")
                    navigate("/dashboard")

                    navigate("/dashboard/request-quote")
                }
            })
            .catch(error => {
                // handle error

            });
    }


    return (
        <>
            {loading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100  flex items-center justify-center">
                    <div className="w-fit flex flex-col items-center justify-center gap-3 ">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <h1 className='text-[#142AA2]' >Loading</h1>
                    </div>
                </div>
            }

            <div className=' px- xs:px-12 sm:px-28 sm2:px-32  md:px-36  md2:px-48 lg:flex lg:flex-row  lg:justify-center lg:px-0  xs:pb-10 '>

                <div className="rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] flex flex-col gap-5 py-[30px]  lg:w-[450px] ">
                    <div>
                        <div>
                            <div className="flex flex-col gap-5">

                                <div className="flex flex-col gap-5">
                                    <div onClick={() => navigate("/dashboard")} className="flex justify-end cursor-pointer">
                                        <h1 className='text-[#49B5FD] text-[15px] font-medium ' >Skip</h1>
                                    </div>
                                    <div className="bg-[#49b5fd45]  w-full py-1 rounded-full"></div>
                                    <div className="">
                                        <h1 className='font-semibold text-[20px]'>For KYC Upload Document</h1>
                                        <p className='text-[13px] leading-[18px]' >Upload your document here for
                                            <br />
                                            your profile</p>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div className="">
                                        <h1 className='text-[16px] font-medium'>Document 1</h1>
                                        <p className='text-[12px] text-[#595959]'>upload your adhaar card  here</p>
                                    </div>

                                    <div className="flex flex-col items-center justify-center cursor-pointer">
                                        {renderFileSection('adhar_document')}
                                    </div>
                                </div>


                                <div className="flex flex-col gap-2">
                                    <div className="">
                                        <h1 className='text-[16px] font-medium'>Document 2</h1>
                                        <p className='text-[12px] text-[#595959]'>upload your adhaar card  here</p>
                                    </div>

                                    <div className="flex flex-col items-center justify-center cursor-pointer">
                                        {renderFileSection('pan_document')}
                                    </div>
                                </div>
                                <Button onClick={uploadFiles} bgColor=" mt-4 w-full h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Continue <span><GoArrowRight size={20} /></span> </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Doc