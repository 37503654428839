import React, { useEffect } from 'react'
import logo from '../assets/LogoFinal.png'
import Phone from '../components/Phone'
import { Outlet } from 'react-router-dom'
import { Button } from 'react-scroll'


const Logout = () => {

  useEffect(() => {
    localStorage.clear()
    window.location = "/"
  }, []);

  return (
    <div>
      <Button>
        Logout
      </Button>
    </div>
  )
}

export default Logout
