import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Button from '../components/Button';
import { Circles } from 'react-loader-spinner';
import logo from '../assets/LogoFinal.png'
import { message } from 'antd';


const VerificationPage = () => {
  const { action, token } = useParams();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    if (action === 'signup') {
      axios.post(`https://api.freightopedia.world/api/v1/accounts/verify-user`, {
        token: token,
        action: action
      })
        .then(response => {
          // handle success
          console.log(response);
          if (response.data.success) {
            alert(response.data.message)
            console.log(response);
            console.log("authToken :: ", response.data.authToken.access);
            localStorage.setItem("authToken", response.data.authToken.access);
            localStorage.setItem("isLoggedIn", true);

            navigate('/company-profile')
          }

        })
        .catch(error => {
          // handle error
          console.log(error);

        });
    }

    console.log(`Action: ${action}`);
    console.log(`Token: ${token}`);
  }, [action, token]);


  const handleChangePassword = () => {
    setLoading(true);
    console.log("token is:", token);
    console.log("action is:", action);
    axios.post(`https://api.freightopedia.world/api/v1/accounts/verify-user`, {
      token: token,
      action: action,
      password: password
    })
      .then(response => {
        console.log("response:" ,response.data)
        // handle success
        if (response.data.success) {
          setLoading(false);
          alert(response.data.message)
          navigate('/login')
        }else if (response.data.success === false) {
          setLoading(false);
          alert(response.data.message)
          // navigate('/login')
        }

      })
      .catch(error => {
        // handle error
      });
  }

  return (
    <>
      {loading &&
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100  flex items-center justify-center">
          <div className="w-fit flex flex-col items-center justify-center gap-3 ">
            <Circles
              height="80"
              width="80"
              color="#142AA2"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <h1 className='text-[#142AA2]' >Loading</h1>
          </div>
        </div>
      }

      <div className='h-screen' >
        <div className=' h-full  flex flex-col  gap-[40px] py-[20px] px-[20px] xs:gap-[90px] sm:gap-[50px]' >
          <div onClick={() => navigate("/")} className=" flex w-full flex-row  sm:justify-start  ">
            <div className="flex flex-row items-center justify-center ">
              <img src={logo} alt="logo" className='w-[24px] h-[24px]' />
              <h1 className='text-logoText text-[17px] font-bold '>FreightOpedia</h1>
            </div>
          </div>

          {
            action === 'forgot-password' && (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className=' w-full flex flex-col rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] py-[30px]  xs:w-[450px] ' >
                  <h1 className='font-bold text-[22px] xs:text-[30px] leading-[30px] xs:leading-[60px]' >Create new password</h1>
                  <h1 className='text-[15px] text-gray-500 ' >Your new password must be different <br /> from previous used password </h1>

                  <div className="pt-10 w-full ">
                    <h1 className='text-[10px] text-gray-500 ' >Password</h1>
                    <input
                      type="text"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter new Password"
                      className='border-[#DDDDDD] text-[13px] my-2 rounded-[5px] outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-3 mt-[5px] w-full'
                    />
                    <h1 className='text-[10px] text-gray-500 ' >Must be at least 8 character</h1>

                    <div className="flex justify-center  w-full ">
                      <Button onClick={handleChangePassword} bgColor="mt-4 w-[300px] xs:w-[420px] h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Save</Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default VerificationPage

