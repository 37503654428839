import React from 'react'
import logo from '../assets/LogoFinal.png'
import Doc from '../components/Doc'
import { useNavigate } from 'react-router-dom';

const DocPG = () => {
  const navigate = useNavigate();
  return (
    <div className='  flex flex-col justify-between gap-[40px] py-[20px] px-[20px] xs:gap-[90px] sm:gap-[50px]' >
    <div onClick={() => navigate("/")} className=" flex w-full flex-row  sm:justify-start  ">
        <div className="flex flex-row items-center justify-center ">
            <img src={logo} alt="logo" className='w-[24px] h-[24px]' />
            <h1 className='text-logoText text-[17px] font-bold '>FreightOpedia</h1>
        </div>
    </div>

    <Doc />


</div>
  )
}

export default DocPG