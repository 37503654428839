import React, { useState } from 'react'
import Button from './Button'
import { FaPhoneAlt } from "react-icons/fa";
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Phone from './Phone';
import { Circles } from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Login = () => {
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);

  const [view, setView] = useState('default');
  const handlePhoneLogin = () => {
    setView('phone');
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    let valid = true;

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      setLoading(true);

      axios.post(`https://api.freightopedia.world/api/v1/accounts/login`, {
        email: email,
        password: password
      })
        .then(response => {
          setLoading(false);
          // handle success


          if (response.data.success) {

            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("authToken", response.data.authToken.access);
            localStorage.setItem("loggedInUser", response.data.data.user_type);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            setFormError("");
            window.location = "/dashboard/request-quote"
          } else {
            setFormError(response.data.message);
          }

        })
        .catch(error => {
          setLoading(false);
          // handle error

          setFormError("Login failed. Please check your credentials.");
        });
    }
  };

  const isLoggedIn = localStorage.getItem("isLoggedIn")


  return (
    <>
      {
        isLoggedIn ? window.location = "/" : ""
      }

      {view === 'default' && (
        <div className=' px-14 xs:px-16 sm:px-28 sm2:px-32  md:px-36  md2:px-48 lg:flex lg:flex-row  lg:justify-center lg:px-0  xs:pb-10 '>

          <div className="rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] flex flex-col gap-5 py-[30px]  lg:w-[500px] ">
            <div className="">
              <h1 className='font-bold' >Log into your account</h1>
              <h1 className='text-[15px]' > Welcome back!</h1>
            </div>

            <div className="">
              <div className="">
                {/* email , pass , loginBtn */}
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-col ">
                      <label htmlFor="" className='text-[13px] leading-[35px]' >Your Email</label>
                      <input type="text"
                        placeholder='email@gmail.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='border-[#DDDDDD] border-[1px] bg-[#F6F6F6] outline-none text-[13px] placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-1' />
                      {emailError && <span className='text-red-500 text-[12px]'>{emailError}</span>}
                    </div>

                    <div className="flex flex-col ">
                      <label htmlFor="" className='text-[13px] leading-[35px]' >Your Password</label>
                      <input type="password"
                        placeholder='Pass@123'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='border-[#DDDDDD] outline-none border-[1px] text-[13px] bg-[#F6F6F6] px-[10px] py-1' />
                      {passwordError && <span className='text-red-500 text-[12px]'>{passwordError}</span>}
                    </div>
                  </div>

                  <Button onClick={handleLogin} bgColor="w-full h-[45px]  rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary" >
                    {loading ? 'Logging in...' : 'Login'}
                  </Button>
                  {/* {loading && <div>Loading...</div>} */}
                  {loading &&
                    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                      <div className="w-fit">
                        <Circles
                          height="80"
                          width="80"
                          color="#142AA2"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </div>
                  }
                  {formError && <span className='text-red-500 text-[12px]'>{formError}</span>}
                </div>

                <div className="flex flex-row gap-5 pt-[30px] justify-between  ">
                  <div className="border-[#DDDDDD] border-t-[1px] mt-[10px] p-5 w-full  "></div>
                  {/* <h1 className='text-[13px]' >or</h1> */}
                  {/* <div className="border-[#DDDDDD] border-t-[1px] mt-[10px] p-5 w-full  "></div> */}
                </div>

                <div className="flex flex-col gap-5">
                  {/* <Button onClick={handlePhoneLogin}
                    bgColor=" w-full h-[45px] text-black  bg-[#F6F6F6] border-[1px] border-[#DDDDDD] rounded-[5px] text-[14px] font-medium  flex flex-row items-center justify-center gap-[10px]" > <FaPhoneAlt className='text-[#142AA2]' /> Continue with phone</Button> */}
                  <h1 className='text-[13px] text-center ' >Don't have an account yet? <span className='text-[#246BFD] underline '> <a href="/create-account">Sign up</a> </span>  </h1>
                  <h1 className='text-[13px] text-center ' ><span className='text-orange-600 underline '> <a href="/forget-password">Forgot Password?</a> </span>  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {view === 'phone' && <Phone />} */}
    </>
  )
}

export default Login