import React, { useEffect, useState } from 'react'
import { CgArrowLongRight } from "react-icons/cg";
import Dropdown from '../components/Dropdown';
import { useParams } from 'react-router-dom';
import ModalQuotationDetails from '../components/ModalQuotationDetails'

import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { NavLink } from 'react-router-dom';
import Button from '../components/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import { Circles } from 'react-loader-spinner';


const CreateQuotation = () => {

    const userData = JSON.parse(localStorage.getItem('userData'))

    const { qr_id } = useParams();
    // const defaultOption = { value: 'Select', label: 'Select' };

    const [errors, setErrors] = useState({});
    const [uom, setUom] = useState('CONTAINER');
    const [tax, setTax] = useState('include');
    const [taxOtherValue, setTaxOtherValue] = useState('');
    const [taxOption, setTaxOption] = useState('');

    const [currency, setCurrency] = useState('AFN');
    const [item, setItem] = useState("");
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");
    const [exchangeRate, setExchangeRate] = useState("");
    const [discount, setDiscount] = useState(0);
    const [amount, setAmount] = useState(0);
    const [taxableAmount, setTaxableAmount] = useState("");
    const [remark, setRemark] = useState("");
    const [quotatioData, setQuotatioData] = useState([]);
    const [totalTaxableAmount, setTotalTaxableAmount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeQuotationRequest, setActiveQuotationRequest] = useState('');
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem('isLoggedIn')


    // useEffect(() => {
    //     calculateFields()
    // }, [quantity, rate, exchangeRate, discount])


    // const calculateFields = () => {
    //     if (quantity !== "" && exchangeRate !== "" && rate !== "") {
    //         setAmount(parseInt(quantity) * parseInt(exchangeRate) * parseInt(rate))
    //     }

    //     if ((quantity !== "" && exchangeRate !== "" && rate !== "") && discount !== "") {
    //         let temp = parseInt(quantity) * parseInt(exchangeRate) * parseInt(rate)

    //         if (taxOtherValue !== "") {
    //             let taxValue = temp - (temp * parseInt(discount) / 100)
    //             setTaxableAmount(taxValue + (taxValue * parseInt(taxOtherValue) / 100))
    //         } else {
    //             setTaxableAmount(temp - (temp * parseInt(discount) / 100))
    //         }
    //     }

    // }

    // const resetToDefault = () => {
    //     setUom(defaultOption.label);
    //     setCurrency(defaultOption.label);
    // };

    useEffect(() => {
        calculateFields();
    }, [quantity, rate, exchangeRate, discount, taxOtherValue, taxOption]);

    const calculateFields = () => {
        if (quantity !== "" && exchangeRate !== "" && rate !== "") {
            const tempAmount = parseInt(quantity) * parseInt(exchangeRate) * parseInt(rate);
            setAmount(tempAmount);

            let taxableValue = tempAmount;

            if (discount !== "") {
                let discountValue = tempAmount * parseInt(discount) / 100;
                taxableValue -= discountValue;
            }

            if (taxOption === "include" || taxOption === "zero") {
                // Do not add tax if the option is "include" or "zero"
                setTaxableAmount(taxableValue);
            } else {
                if (taxOtherValue !== "") {
                    taxableValue += taxableValue * parseInt(taxOtherValue) / 100;
                }
                setTaxableAmount(taxableValue);
            }
        }
    }



    const handleQuantity = (val) => {
        setQuantity(val)
        
    }

    const handleExchangeRate = (val) => {
        setExchangeRate(val)
        
    }

    const handleRate = (val) => {
        setRate(val)
        
    }

    const handleAmount = (val) => {
        setAmount(val)
        
    }

    const handleDiscount = (val) => {
        setDiscount(val)
        
    }

    const handleTaxableAmount = (val) => {
        setTaxableAmount(val)
        
    }




    const handleUom = (option) => {
        setUom(option.label)
        
    }
    const handleCurrency = (option) => {
        setCurrency(option.label)
    }

    const handleTax = (option) => {
        setTax(option.label)
        setTaxOption(option.label)
        // if (tax !== 'Tax') {
        //     setTaxOtherValue('')
        // }
        if (option.label === 'Include' || option.label === 'Zero') {
            setTaxOtherValue(''); // Set tax value to empty if "include" or "zero" is selected
        }
    }

    const handleTaxOtherValue = (e) => {
        setTaxOtherValue(e.target.value)
    };

    const UOM = [
        // { label: "Select ", value: 0 },
        { label: "CONTAINER", value: "CONTAINER" },
        { label: "SHIPMENT", value: "SHIPMENT" },
        { label: "CBM", value: "CBM" },
        { label: "BL", value: "BL" },
        { label: "AWB", value: "AWB" },
        { label: "DOCUMENT", value: "DOCUMENT" },
        { label: "PACKAGE", value: "PACKAGE" },

    ]

    const TAX = [
        // { label: "Select ", value: 0 },
        { label: "Include", value: "Include" },
        { label: "Tax", value: "Tax" },
        { label: "Zero", value: "Zero" },

    ]

    const CURRENCY = [
        // { label: "Select", value: 0 },
        { label: "AFN", value: "AFN" },
        { label: "ALL", value: "ALL" },
        { label: "DZD", value: "DZD" },
        { label: "AOA", value: "AOA" },
        { label: "ARS", value: "ARS" },
        { label: "AMD", value: "AMD" },
        { label: "AWG", value: "AWG" },
        { label: "AUD", value: "AUD" },
        { label: "AZN", value: "AZN" },
        { label: "BSD", value: "BSD" },
        { label: "BHD", value: "BHD" },
        { label: "BDT", value: "BDT" },
        { label: "BBD", value: "BBD" },
        { label: "BYN", value: "BYN" },
        { label: "BZD", value: "BZD" },
        { label: "BMD", value: "BMD" },
        { label: "BTN", value: "BTN" },
        { label: "BOB", value: "BOB" },
        { label: "BAM", value: "BAM" },
        { label: "BWP", value: "BWP" },
        { label: "BRL", value: "BRL" },
        { label: "GBP", value: "GBP" },
        { label: "BND", value: "BND" },
        { label: "BGN", value: "BGN" },
        { label: "BIF", value: "BIF" },
        { label: "KHR", value: "KHR" },
        { label: "CAD", value: "CAD" },
        { label: "CVE", value: "CVE" },
        { label: "KYD", value: "KYD" },
        { label: "XAF", value: "XAF" },
        { label: "XPF", value: "XPF" },
        { label: "CLP", value: "CLP" },
        { label: "CNY", value: "CNY" },
        { label: "COP", value: "COP" },
        { label: "KMF", value: "KMF" },
        { label: "CDF", value: "CDF" },
        { label: "CRC", value: "CRC" },
        { label: "HRK", value: "HRK" },
        { label: "CUC", value: "CUC" },
        { label: "CZK", value: "CZK" },
        { label: "DKK", value: "DKK" },
        { label: "DJF", value: "DJF" },
        { label: "DOP", value: "DOP" },
        { label: "XCD", value: "XCD" },
        { label: "EGP", value: "EGP" },
        { label: "ERN", value: "ERN" },
        { label: "EEK", value: "EEK" },
        { label: "ETB", value: "ETB" },
        { label: "EUR", value: "EUR" },
        { label: "FJD", value: "FJD" },
        { label: "GMD", value: "GMD" },
        { label: "GEL", value: "GEL" },
        { label: "GHS", value: "GHS" },
        { label: "GIP", value: "GIP" },
        { label: "GTQ", value: "GTQ" },
        { label: "GNF", value: "GNF" },
        { label: "GYD", value: "GYD" },
        { label: "HTG", value: "HTG" },
        { label: "HNL", value: "HNL" },
        { label: "HKD", value: "HKD" },
        { label: "HUF", value: "HUF" },
        { label: "ISK", value: "ISK" },
        { label: "INR", value: "INR" },
        { label: "IDR", value: "IDR" },
        { label: "IRR", value: "IRR" },
        { label: "IQD", value: "IQD" },
        { label: "ILS", value: "ILS" },
        { label: "JMD", value: "JMD" },
        { label: "JPY", value: "JPY" },
        { label: "JOD", value: "JOD" },
        { label: "KZT", value: "KZT" },
        { label: "KES", value: "KES" },
        { label: "KWD", value: "KWD" },
        { label: "KGS", value: "KGS" },
        { label: "LAK", value: "LAK" },
        { label: "LVL", value: "LVL" },
        { label: "LBP", value: "LBP" },
        { label: "LSL", value: "LSL" },
        { label: "LRD", value: "LRD" },
        { label: "LYD", value: "LYD" },
        { label: "LTL", value: "LTL" },
        { label: "MOP", value: "MOP" },
        { label: "MKD", value: "MKD" },
        { label: "MGA", value: "MGA" },
        { label: "MWK", value: "MWK" },
        { label: "MYR", value: "MYR" },
        { label: "MVR", value: "MVR" },
        { label: "MRO", value: "MRO" },
        { label: "MUR", value: "MUR" },
        { label: "MXN", value: "MXN" },
        { label: "MDL", value: "MDL" },
        { label: "MNT", value: "MNT" },
        { label: "MAD", value: "MAD" },
        { label: "MZN", value: "MZN" },
        { label: "MMK", value: "MMK" },
        { label: "NAD", value: "NAD" },
        { label: "NPR", value: "NPR" },
        { label: "ANG", value: "ANG" },
        { label: "TWD", value: "TWD" },
        { label: "NZD", value: "NZD" },
        { label: "NIO", value: "NIO" },
        { label: "NGN", value: "NGN" },
        { label: "KPW", value: "KPW" },
        { label: "NOK", value: "NOK" },
        { label: "OMR", value: "OMR" },
        { label: "PKR", value: "PKR" },
        { label: "PAB", value: "PAB" },
        { label: "PGK", value: "PGK" },
        { label: "PYG", value: "PYG" },
        { label: "PEN", value: "PEN" },
        { label: "PHP", value: "PHP" },
        { label: "PLN", value: "PLN" },
        { label: "QAR", value: "QAR" },
        { label: "RON", value: "RON" },
        { label: "RUB", value: "RUB" },
        { label: "RWF", value: "RWF" },
        { label: "SHP", value: "SHP" },
        { label: "WST", value: "WST" },
        { label: "STD", value: "STD" },
        { label: "SAR", value: "SAR" },
        { label: "RSD", value: "RSD" },
        { label: "SCR", value: "SCR" },
        { label: "SLL", value: "SLL" },
        { label: "SGD", value: "SGD" },
        { label: "SKK", value: "SKK" },
        { label: "SBD", value: "SBD" },
        { label: "SOS", value: "SOS" },
        { label: "ZAR", value: "ZAR" },
        { label: "KRW", value: "KRW" },
        { label: "SSP", value: "SSP" },
        { label: "LKR", value: "LKR" },
        { label: "SDG", value: "SDG" },
        { label: "SRD", value: "SRD" },
        { label: "SZL", value: "SZL" },
        { label: "SEK", value: "SEK" },
        { label: "CHF", value: "CHF" },
        { label: "SYP", value: "SYP" },
        { label: "TJS", value: "TJS" },
        { label: "TZS", value: "TZS" },
        { label: "THB", value: "THB" },
        { label: "TOP", value: "TOP" },
        { label: "TTD", value: "TTD" },
        { label: "TND", value: "TND" },
        { label: "TRY", value: "TRY" },
        { label: "TMT", value: "TMT" },
        { label: "UGX", value: "UGX" },
        { label: "UAH", value: "UAH" },
        { label: "AED", value: "AED" },
        { label: "UYU", value: "UYU" },
        { label: "USD", value: "USD" },
        { label: "UZS", value: "UZS" },
        { label: "VUV", value: "VUV" },
        { label: "VEF", value: "VEF" },
        { label: "VND", value: "VND" },
        { label: "YER", value: "YER" },
        { label: "ZMW", value: "ZMW" },
        { label: "ZWL", value: "ZWL" },

    ];


    const handleSubmit = () => {
        

        if (quotatioData.length === 0) {
            alert("Please add at least one item before submitting");
            return;
        }

        setLoading(true);

        let charges = quotatioData.map(({ recordId, ...rest }) => rest);

        axios.post(`https://api.freightopedia.world/api/v1/logistics/quotation`, {
            quotation_request: qr_id,
            remark: remark,
            charges: charges
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                // handle success
                alert(response.data.message)
                
                setLoading(false);
                window.location = "/inquiry-hub"
                // setFormError("");
            })
            .catch(error => {
                // handle error
                
                // setFormError("Something went wrong!");
            });
    }

    const handleInputs = () => {

        let validationErrors = {};

        if (!item) validationErrors.item = "Item is required";
        if (!quantity || isNaN(quantity)) validationErrors.quantity = "Valid quantity is required";
        if (!uom) validationErrors.uom = "UOM is required";
        if (!currency) validationErrors.currency = "Currency is required";
        if (!exchangeRate || isNaN(exchangeRate)) validationErrors.exchangeRate = "Valid exchange rate is required";
        if (!rate || isNaN(rate)) validationErrors.rate = "Valid rate is required";
        if (!amount || isNaN(amount)) validationErrors.amount = "Valid amount is required";
        if (discount && isNaN(discount)) validationErrors.discount = "Valid discount is required";
        if (!tax) validationErrors.tax = "Valid tax is required";
        if (!taxableAmount || isNaN(taxableAmount)) validationErrors.taxableAmount = "Valid taxable amount is required";

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }


        const newItem = {
            recordId: quotatioData.length + 1,
            name: item,
            quantity: quantity,
            uom: uom,
            currency: currency,
            exchange_rate: exchangeRate,
            rate: rate,
            amount: amount,
            discount: discount,
            tax: taxOtherValue ? taxOtherValue : 0,
            taxable_amount: taxableAmount,
        };


        setQuotatioData([...quotatioData, newItem]);

        setTotalTaxableAmount(parseInt(totalTaxableAmount) + parseInt(taxableAmount))

        // Clear input fields
        // resetToDefault();
        setItem('');
        setQuantity('');
        // setUom(0);
        // setCurrency(0);
        setExchangeRate('');
        setRate(0);
        setAmount('');
        setDiscount(0);
        // setTax(0);
        // setTax('');
        setTaxOtherValue('');
        setTaxableAmount('');
        setErrors({});
    };

    const handleDelete = (id) => {
        const temp = quotatioData.filter(item => item.recordId === id);
        const newData = quotatioData.filter(item => item.recordId !== id);
        let val = totalTaxableAmount - temp[0].taxable_amount
        setTotalTaxableAmount(val)
        setQuotatioData(newData);
    };

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const handleViewDetails = () => {
        
        setActiveQuotationRequest(qr_id)
        setIsModalOpen(true);
    }

    return (
        <div className=' p-10 flex flex-col gap-10'>

            {loading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </div>
            }

            {
                isLoggedIn ? "" : window.location = '/login'
            }



            {
                isModalOpen && (
                    <div className='absolute z-50 top-0 left-0'>
                        <ModalQuotationDetails quotationRequest={activeQuotationRequest} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} user={"transporter"} showButton={false} />
                    </div>
                )}

            {/* heading and navlinks */}
            <div className="">
                <div className="flex flex-row items-center justify-start gap-5  ">
                    <h1 className='font-semibold text-[40px] '>Create Quotation</h1>
                    <Button onClick={handleViewDetails} bgColor="md:py-2 md:px-5 rounded-[5px] md:w-[150px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">View Details</Button>
                </div>


                <div className="text-[13px]">
                    <NavLink className=" text-[18px]  " >Inquires hub</NavLink> /
                    <NavLink className=" text-[18px]  " >View Deatils</NavLink> /
                    <NavLink className=" text-[18px]  font-semibold">Create Quotation</NavLink>
                </div>
            </div>


            {/* company name ,  Depature Arrival , freight     */}
            <div className="flex flex-row gap-10 items-end">

                <div className="flex flex-col gap-2">
                    <h1 className='text-[14px]'>Campany Name</h1>
                    <h1 className="font-semibold text-[20px]">{userData.company_name}</h1>
                </div>
            </div>




            {/* input fields */}

            <div className="flex flex-row gap-5 items-start">
                <div className="flex flex-col gap-2 items-start ">
                    <label htmlFor="" className='font-semibold'>Name of Items</label>
                    <input type="  text"
                        className='w-[150px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={item}
                        onChange={(e) => setItem(e.target.value)}
                    />
                    {errors.item && <p style={{ color: 'red', fontSize: '13px' }}>{errors.item}</p>}
                </div>


                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Quantity</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange}
                        className=' w-[80px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={quantity}
                        onChange={(e) => handleQuantity(e.target.value)}
                    />
                    {errors.quantity && <p style={{ color: 'red', fontSize: '13px' }}>{errors.quantity}</p>}
                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>uom</label>
                    {/* <input type="text" value={uom} className=' w-[150px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3' /> */}
                    <Dropdown
                        className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] rounded-sm py-[10px] px-2 w-full min-w-[130px]'
                        options={UOM}
                        value={uom}
                        onChange={handleUom}
                    />
                    {errors.uom && <p style={{ color: 'red', fontSize: '13px' }}>{errors.uom}</p>}
                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Currency</label>
                    <Dropdown
                        className=' w-[80px] outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] rounded-sm py-[10px] px-2'
                        value={currency}
                        options={CURRENCY}
                        onChange={handleCurrency}
                    />
                    {errors.currency && <p style={{ color: 'red', fontSize: '13px' }}>{errors.currency}</p>}
                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Exchange Rate</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange}
                        className='w-[150px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={exchangeRate}
                        onChange={(e) => handleExchangeRate(e.target.value)}
                    />
                    {errors.exchangeRate && <p style={{ color: 'red', fontSize: '13px' }}>{errors.exchangeRate}</p>}
                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Rate</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange}
                        className=' w-[100px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={rate}
                        onChange={(e) => handleRate(e.target.value)}
                    />
                    {errors.rate && <p style={{ color: 'red', fontSize: '13px' }}>{errors.rate}</p>}

                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Amount</label>
                    <input type="number"
                        disabled
                        className=' w-[80px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={amount}
                    // onChange={(e) => setAmount(e.target.value)}
                    />
                    {errors.amount && <p style={{ color: 'red', fontSize: '13px' }}>{errors.amount}</p>}
                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Discount</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange}
                        className=' w-[80px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={discount}
                        onChange={(e) => handleDiscount(e.target.value)}
                    />
                    {errors.discount && <p style={{ color: 'red', fontSize: '13px' }}>{errors.discount}</p>}
                </div>

                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Tax</label>
                    <Dropdown
                        className=' w-[100px] outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] rounded-sm py-[10px] px-2'
                        options={TAX}
                        value={tax}
                        onChange={handleTax}
                    />
                    {
                        tax === 'Tax' && (
                            <div>
                                <input
                                    type="number" onWheel={numberInputOnWheelPreventChange}
                                    value={taxOtherValue}
                                    onChange={handleTaxOtherValue}
                                    pattern="\d*"
                                    onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                    placeholder="Enter Tax value"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                    {errors.tax && <p style={{ color: 'red', fontSize: '13px' }}>{errors.tax}</p>}
                </div>
                <div className="flex flex-col gap-2 items-start">
                    <label htmlFor="" className='font-semibold'>Taxabel Amount</label>
                    <input type="number"
                        disabled
                        className='w-[150px] outline-none bg-[#F6F6F6] border-[#DDDDDD] border-[1px] rounded-sm py-2  px-3'
                        value={taxableAmount}
                    // onChange={(e) => setTaxableAmount(e.target.value)}
                    />
                    {errors.taxableAmount && <p style={{ color: 'red', fontSize: '13px' }}>{errors.taxableAmount}</p>}
                </div>

                <Button onClick={handleInputs} bgColor="mt-8 mr-10  md:py-2 md:px-5 rounded-[5px] md:w-[150px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">Create</Button>


            </div>







            {/* table */}
            <div className="">
                <table className=' w-full text-center table-auto '>
                    <thead className='text-[15px]'>
                        <tr>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[170px] py-1  ' >Name of Items</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[100px] py-1  ' >Quantity</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[100px] py-1  ' >UOM</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[120px] py-1  ' >Currency</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[150px] py-1  ' >Exchange rate</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[100px] py-1  ' >Rate</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[150px] py-1  ' >Amount</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[150px] py-1  ' >Discount</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[80px]  py-1 ' >Tax</th>
                            <th className='font-medium  border-[1px] border-[#D6D6D6] px-2 min-w-[180px] py-1  ' >Taxable Amount</th>
                            <th  > </th>

                        </tr>

                        <tr></tr>
                    </thead>

                    <tbody className=''>

                        {quotatioData.map((item, ind) => (
                            <tr key={ind} className=''>
                                <td className='py-1 text-[#5C5C5C] text-[13px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.name}</td>
                                <td className='py-1 text-[#5C5C5C] text-[13px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.quantity}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.uom}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.currency}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.exchange_rate}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.rate}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.amount}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.discount}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.tax}</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' >{item.taxable_amount}</td>
                                <td className='py-1 px-5' ><Button
                                    onClick={() => handleDelete(item.recordId)}
                                    bgColor="w-[130px] py-1 rounded-[2px] text-[14px] text-white font-medium bg-[#EF0B0B]">Delete</Button></td>

                            </tr>

                        ))}

                        <td className='py-1 text-[#5C5C5C] text-[13px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[13px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1 text-[#5C5C5C] text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 ' ></td>
                        <td className='py-1  text-[15px] w-auto border-[1px] border-[#D6D6D6] px-2 font-semibold ' >{totalTaxableAmount}</td>
                        {/* <td className='py-1 px-5' ><Button bgColor="w-[130px] py-1 rounded-[2px] text-[14px] text-white font-medium bg-[#EF0B0B]">Delete</Button></td> */}

                    </tbody>


                </table>
            </div>


            {/* remark and submit */}
            <div className="">
                <h1>Remark</h1>
                <div className="w-full flex flex-row justify-between">
                    <textarea id="" name="" rows="3"
                        className='bg-[#F6F6F6]  border-[#DDDDDD] border-[1px] px-3  py-1 text-[13px] resize-none outline-none w-2/3 rounded-sm '
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    ></textarea>
                    <div className="flex justify-end items-end ">
                        <Button onClick={handleSubmit} bgColor="md:py-2 md:px-5 rounded-[5px] md:w-[200px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">Submit</Button>
                    </div>
                </div>
            </div>

        </div>
    )


}

export default CreateQuotation