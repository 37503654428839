import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Dropdown from './Dropdown';
import { ImUpload } from "react-icons/im";
import uploadICon2 from '../assets/uploadd.jpeg'
import Button from './Button';
import OpenTop from './OpenTop';
import Refeer from './Refeer';
import Tank from './Tank';
import { MdDateRange } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCheckCircle } from "react-icons/fa";
import { Circles } from 'react-loader-spinner';
import Nav from '../components/Nav'


// Air

const CreateQuoteAir = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn")
    const navigate = useNavigate();
    const datePickerRef = useRef(null);
    const inputRefs = {
        hazardous_document: useRef(null),
        customs_clearance_origin_document: useRef(null),
        customs_clearance_destination_document: useRef(null)
    };
    const [errors, setErrors] = useState({});
    const [depatureCountry, setDepatureCountry] = useState('');
    const [portOfDepature, setPortOfDepature] = useState('');
    const [arrivalCountry, setArrivalCountry] = useState('');
    const [portOfArrival, setPortOfArrival] = useState('');
    const [commodity, setCommodity] = useState('');
    const [hsCode, setHsCode] = useState('');
    const [incoterms, setIncoterms] = useState('');
    const [cargoWeight, setCargoWeight] = useState('');
    const [typeOfCargo, setTypeOfCargo] = useState('');
    const [typeOfCargoWeight, setTypeOfCargoWeight] = useState('Kilos');
    const [typeOfDimesions, setTypeOfDimensions] = useState('CM');
    const [typeOfNonStackable, setTypeOfNonStackable] = useState('');
    const [totalPackages, setTotalPackages] = useState('');
    const [cargoReadyDate, setCargoReadyDate] = useState(new Date());
    const [cbm, setCbm] = useState(0);
    const [dimensionLength, setDimensionLength] = useState('');
    const [dimensionWidth, setDimensionWidth] = useState('');
    const [dimensionHeight, setDimensionHeight] = useState('');
    const [isStackable, setIsStackable] = useState(false);
    const [isNonStackable, setIsNonStackable] = useState(false);
    const [hazardous, setHazardous] = useState(false);
    const [transportation, setTransportation] = useState(false);
    const [transportationOrigin, setTransportationOrigin] = useState(false);
    const [originAddOfOrigin, setOriginAddOfOrigin] = useState('');
    const [originAddOfDestination, setOriginAddOfDestination] = useState('');
    const [originTypeOfWeight, setOriginTypeOfWeight] = useState('');
    const [transportationDestination, setTransportationDestination] = useState(false);
    const [destinationAddress, setDestinationAddress] = useState('');
    const [originAddress, setOriginAddress] = useState('');
    const [destinationTypeOfWeight, setDestinationTypeOfWeight] = useState('');
    const [customClearance, setCustomClearance] = useState(false);
    const [customClearanceOrigin, setCustomClearanceOrigin] = useState(false);
    const [customClearanceDestination, setCustomClearanceDestination] = useState(false);
    const [addtionalNotes, setAddtionalNotes] = useState('');
    const [logInModal, setLogInModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFileAvailable, setIsFileAvailable] = useState(false);
    const [portOfDepartureOther, setPortOfDepartureOther] = useState('');
    const [portOfArrivalOther, setPortOfArrivalOther] = useState('');
    const [incotermOther, setIncotermOther] = useState('');
    const [cargoTypeOther, setCargoTypeOther] = useState('');

    const [fileNames, setFileNames] = useState({
        hazardous_document: [],
        customs_clearance_origin_document: [],
        customs_clearance_destination_document: [],
    });

    const [filesData, setFilesData] = useState({
        hazardous_document: [],
        customs_clearance_origin_document: [],
        customs_clearance_destination_document: [],
    });

    useEffect(() => {
        const calculatedCbm = ((dimensionHeight * dimensionLength * dimensionWidth) / 6000) * totalPackages;
        setCbm(calculatedCbm);
    }, [dimensionHeight, dimensionLength, dimensionWidth, totalPackages]);

    // state for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quoteNo, setQuoteNo] = useState('');


    // const handleDepature = (option) => {
    //     setDepatureCountry(option.label)
    // };

    // const handleArrival = (option) => {
    //     setArrivalCountry(option.label)
    // };


    const handleDepature = (option) => {
        setDepatureCountry(option.label)
        if (depatureCountry !== 'Other') {
            setPortOfDepartureOther('')
        }
    };

    const handleDepatureOther = (e) => {
        setPortOfDepartureOther(e.target.value)
    };


    const handleArrival = (option) => {
        setArrivalCountry(option.label)
        if (arrivalCountry !== 'Other') {
            setPortOfArrivalOther('')
        }
    };

    const handleArrivalOther = (e) => {
        setPortOfArrivalOther(e.target.value)
    };

    const handleIncoterms = (option) => {
        setIncoterms(option.label)
        if (incoterms !== 'Other') {
            setIncotermOther('')
        }
    };

    const handleIncotermsOther = (e) => {
        setIncotermOther(e.target.value)
    };

    const handleTypeOfCargo = (option) => {
        setTypeOfCargo(option.label)
        if (typeOfCargo !== 'Other') {
            setCargoTypeOther('')
        }
    };

    const handleTypeOfCargoOther = (e) => {
        setCargoTypeOther(e.target.value)
    };

    const handleTypeOfCargoWeight = (option) => {
        setTypeOfCargoWeight(option.label)
    };

    const handleTypeOfDimensions = (option) => {
        setTypeOfDimensions(option.label)
    };

    const handleTypeOfNonStackable = (option) => {
        setTypeOfNonStackable(option.label);
    }

    const handleTypeOfOrigin = (option) => {
        setOriginTypeOfWeight(option.label);
    }

    const handleTypeOfDestination = (option) => {
        setDestinationTypeOfWeight(option.label);
    }


    const handleCargoReadyDate = () => {

    };

    const handleIsStackable = () => {
        // setIsStackable(!isStackable)
        if (!isStackable) {
            setIsNonStackable(false);
        }
        setIsStackable(!isStackable);
    };

    const handleIsNonStackable = () => {
        // setIsNonStackable(!isNonStackable)
        if (!isNonStackable) {
            setIsStackable(false);
        }
        setIsNonStackable(!isNonStackable);
    };

    const handlehazardous = () => {
        setHazardous(!hazardous)
    };

    const handleTransportation = () => {
        setTransportation(!transportation)
    };

    const handleTransportationOrigin = () => {
        setTransportationOrigin(!transportationOrigin)
    };

    const handleTransportationDestination = () => {
        setTransportationDestination(!transportationDestination)
    };

    const handleCustomClearance = () => {
        setCustomClearance(!customClearance)
    };

    const handleCustomClearanceOrigin = () => {
        setCustomClearanceOrigin(!customClearanceOrigin)
    };

    const handleCustomClearanceDestination = () => {
        setCustomClearanceDestination(!customClearanceDestination)
    };


    const handleImageClick = (section) => {
        inputRefs[section].current.click();
    };


    const handleImageChange = (event, section) => {
        setIsFileAvailable(true);
        const newFiles = Array.from(event.target.files);
        
        

        const newFileNames = newFiles.map(file => file.name);
        setFileNames(prevFileNames => ({
            ...prevFileNames,
            [section]: [...prevFileNames[section], ...newFileNames]
        }));

        const newFileData = newFiles.map(file => file);
        setFilesData(prevFileNames => ({
            ...prevFileNames,
            [section]: [...prevFileNames[section], ...newFileData]
        }));
    };

    const handleIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };

    const
        numberInputOnWheelPreventChange = (e) => {
            // Prevent the input value change
            e.target.blur()

            // Prevent the page/container scrolling
            e.stopPropagation()

            // Refocus immediately, on the next tick (after the current function is done)
            setTimeout(() => {
                e.target.focus()
            }, 0)
        }

    const renderFileSection = (section) => (
        <div className="flex flex-col items-center justify-center cursor-pointer min-w-2/3 lg:w-1/2  md:flex md:flex-row md:items-center md:gap-5">
            <div
                onClick={() => handleImageClick(section)}
                className="p-5 w-full border-[#49B5FD] border-dashed border-[1px] flex flex-col items-center justify-center gap-2"
            >
                <img src={uploadICon2} alt="" className="w-[50px]" />
                <h1 className="text-[10px]">Select Files</h1>
            </div>
            <input
                type="file"
                name="files"
                ref={inputRefs[section]}
                onChange={(event) => handleImageChange(event, section)}
                className="cursor-pointer"
                style={{ display: 'none' }}
                multiple
            />
            {/* {fileNames[section].length > 0 && (
                <div className="mt-2 space-y-3">
                    {fileNames[section].map((fileName, index) => (
                        <h2 key={index} className="text-[12px]">{fileName}</h2>
                    ))}
                </div>
            )} */}


        </div>
    );

    // const handleFileDelete = (section, index) => {
    //     setFileNames(prevState => ({
    //         ...prevState,
    //         [section]: prevState[section].filter((_, i) => i !== index)
    //     }));
    // };

    const handleFileDelete = (section, index) => {
        setFileNames(prevState => ({
            ...prevState,
            [section]: prevState[section].filter((_, i) => i !== index)
        }));
        setFilesData(prevState => ({
            ...prevState,
            [section]: prevState[section].filter((_, i) => i !== index)
        }));
    };

    // const getRequestBody = async () => {
    //     let validationErrors = {};

    //     let data = {
    //         freight_medium: "air",
    //         departure_country: depatureCountry,
    //         departure: portOfDepature,
    //         arrival_country: arrivalCountry,
    //         arrival: portOfArrival,
    //         commodity: commodity,
    //         hs_code: hsCode,
    //         incoterm: incoterms,
    //         cargo_weight: cargoWeight,
    //         cargo_weight_unit: typeOfCargoWeight,
    //         cargo_type: typeOfCargo,
    //         total_packages: totalPackages,
    //         cbm: cbm,
    //         cargo_ready_date: cargoReadyDate,
    //         addtional_notes: addtionalNotes,
    //         dimensions_length: dimensionLength,
    //         dimensions_width: dimensionWidth,
    //         dimensions_height: dimensionHeight,
    //         dimensions_unit: typeOfDimesions,
    //         stackable: isStackable,
    //         non_stackable: isNonStackable,
    //         hazardous: hazardous,
    //         transportation: transportation,
    //         transportation_origin: transportationOrigin,
    //         transportation_origin_pickup_address: originAddOfOrigin,
    //         transportation_origin_drop_address: originAddOfDestination,
    //         transportation_destination: transportationDestination,
    //         transportation_destination_pickup_address: originAddress,
    //         transportation_destination_drop_address: destinationAddress,
    //         customs_clearance: customClearance,
    //         customs_clearance_origin: customClearanceOrigin,
    //         customs_clearance_destination: customClearanceDestination
    //     }

    //     if (!data.departure_country) errors.push('Departure country is required');
    //     if (!data.departure) errors.push('Departure port is required');
    //     if (!data.arrival_country) errors.push('Arrival country is required');
    //     if (!data.arrival) errors.push('Arrival port is required');
    //     if (!data.commodity) errors.push('Commodity is required');
    //     if (!data.hs_code) errors.push('HS code is required');
    //     if (!data.incoterm) errors.push('Incoterm is required');
    //     if (!data.cargo_weight) errors.push('Cargo weight is required');
    //     if (!data.cargo_weight_unit) errors.push('Cargo weight unit is required');
    //     if (!data.cargo_type) errors.push('Cargo type is required');
    //     if (!data.total_packages) errors.push('Total packages is required');
    //     if (!data.cbm) errors.push('CBM is required');
    //     if (!data.cargo_ready_date) errors.push('Cargo ready date is required');
    //     if (!data.dimensions_length) errors.push('Dimensions length is required');
    //     if (!data.dimensions_width) errors.push('Dimensions width is required');
    //     if (!data.dimensions_height) errors.push('Dimensions height is required');
    //     if (!data.dimensions_unit) errors.push('Dimensions unit is required');
    //     if (typeof data.stackable !== 'boolean') errors.push('Stackable is required');
    //     if (typeof data.non_stackable !== 'boolean') errors.push('Non-stackable is required');
    //     if (typeof data.hazardous !== 'boolean') errors.push('Hazardous is required');
    //     if (typeof data.transportation !== 'boolean') errors.push('Transportation is required');
    //     if (!data.transportation_origin) errors.push('Transportation origin is required');
    //     if (!data.transportation_origin_pickup_address) errors.push('Origin pickup address is required');
    //     if (!data.transportation_origin_drop_address) errors.push('Origin drop address is required');
    //     if (!data.transportation_destination) errors.push('Transportation destination is required');
    //     if (!data.transportation_destination_pickup_address) errors.push('Destination pickup address is required');
    //     if (!data.transportation_destination_drop_address) errors.push('Destination drop address is required');
    //     if (typeof data.customs_clearance !== 'boolean') errors.push('Customs clearance is required');
    //     if (typeof data.customs_clearance_origin !== 'boolean') errors.push('Customs clearance origin is required');
    //     if (typeof data.customs_clearance_destination !== 'boolean') errors.push('Customs clearance destination is required');

    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //         return { errors: validationErrors };
    //     }

    //     setErrors({});
    //     return { requestBody: data };
    // }


    const getRequestBody = async () => {
        // let validationErrors = {};

        // // Example validation logic
        // if (!depatureCountry) validationErrors.departure_country = 'Departure country is required';
        // if (!portOfDepature) validationErrors.departure = 'Departure port is required';
        // if (!arrivalCountry) validationErrors.arrival_country = 'Arrival country is required';
        // if (!portOfArrival) validationErrors.arrival = 'Arrival port is required';
        // if (!commodity) validationErrors.commodity = 'Commodity is required';
        // if (!hsCode) validationErrors.hs_code = 'HS code is required';
        // if (!incoterms) validationErrors.incoterm = 'Incoterm is required';
        // if (!cargoWeight) validationErrors.cargo_weight = 'Cargo weight is required';
        // if (!typeOfCargoWeight) validationErrors.cargo_weight_unit = 'Cargo weight unit is required';
        // if (!typeOfCargo) validationErrors.cargo_type = 'Cargo type is required';
        // if (!totalPackages) validationErrors.total_packages = 'Total packages is required';
        // if (!cbm) validationErrors.cbm = 'CBM is required';
        // if (!cargoReadyDate) validationErrors.cargo_ready_date = 'Cargo ready date is required';
        // if (!dimensionLength) validationErrors.dimensions_length = 'Dimensions length is required';
        // if (!dimensionWidth) validationErrors.dimensions_width = 'Dimensions width is required';
        // if (!dimensionHeight) validationErrors.dimensions_height = 'Dimensions height is required';
        // if (!typeOfDimesions) validationErrors.dimensions_unit = 'Dimensions unit is required';

        // if (typeof isStackable !== 'boolean') validationErrors.stackable = 'Stackable is required';
        // if (typeof isNonStackable !== 'boolean') validationErrors.non_stackable = 'Non-stackable is required';
        // if (typeof hazardous !== 'boolean') validationErrors.hazardous = 'Hazardous is required';
        // if (typeof transportation !== 'boolean') validationErrors.transportation = 'Transportation is required';
        // if (!transportationOrigin) validationErrors.transportation_origin = 'Transportation origin is required';
        // if (!originAddOfOrigin) validationErrors.transportation_origin_pickup_address = 'Origin pickup address is required';
        // if (!originAddOfDestination) validationErrors.transportation_origin_drop_address = 'Origin drop address is required';
        // if (!transportationDestination) validationErrors.transportation_destination = 'Transportation destination is required';
        // if (!originAddress) validationErrors.transportation_destination_pickup_address = 'Destination pickup address is required';
        // if (!destinationAddress) validationErrors.transportation_destination_drop_address = 'Destination drop address is required';
        // if (typeof customClearance !== 'boolean') validationErrors.customs_clearance = 'Customs clearance is required';
        // if (typeof customClearanceOrigin !== 'boolean') validationErrors.customs_clearance_origin = 'Customs clearance origin is required';
        // if (typeof customClearanceDestination !== 'boolean') validationErrors.customs_clearance_destination = 'Customs clearance destination is required';

        // Set errors in the state
        // setErrors(validationErrors);

        // // If there are validation errors, return with errors
        // if (Object.keys(validationErrors).length > 0) {
        //     return { errors: validationErrors };
        // }

        // If no errors, return requestBody

        let requestBody = {
            freight_medium: "air",
            departure_country: portOfDepartureOther ? portOfDepartureOther : depatureCountry,
            // departure_country: depatureCountry,
            departure: portOfDepature,
            arrival_country: portOfArrivalOther ? portOfArrivalOther : arrivalCountry,
            // arrival_country: arrivalCountry,
            arrival: portOfArrival,
            commodity: commodity,
            incoterm: incotermOther ? incotermOther : incoterms,
            hs_code: hsCode,
            // incoterm: incoterms,
            cargo_weight: cargoWeight,
            cargo_weight_unit: typeOfCargoWeight,
            // cargo_type: typeOfCargo,
            cargo_type: cargoTypeOther ? cargoTypeOther : typeOfCargo,
            total_packages: totalPackages,
            cbm: cbm,
            cargo_ready_date: cargoReadyDate,
            addtional_notes: addtionalNotes,
            dimensions_length: dimensionLength,
            dimensions_width: dimensionWidth,
            dimensions_height: dimensionHeight,
            dimensions_unit: typeOfDimesions,
            stackable: isStackable,
            non_stackable: isNonStackable,
            hazardous: hazardous,
            transportation: transportation,
            transportation_origin: transportationOrigin,
            transportation_origin_pickup_address: originAddOfOrigin,
            transportation_origin_drop_address: originAddOfDestination,
            transportation_destination: transportationDestination,
            transportation_destination_pickup_address: originAddress,
            transportation_destination_drop_address: destinationAddress,
            customs_clearance: customClearance,
            customs_clearance_origin: customClearanceOrigin,
            customs_clearance_destination: customClearanceDestination
        }
        return requestBody;
    };



    // const uploadFiles = async (qr_id) => {

    //     
    //     

    //     await axios.post(`https://api.freightopedia.world/api/v1/logistics/upload/quotation-request-document?qr_id=${qr_id}`, filesData, {
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     })
    //         .then(response => {
    //             // handle success
    //             
    //             

    //             if (response.data.success) {
    //                 
    //                 setIsModalOpen(true);
    //                 
    //             }
    //         })
    //         .catch(error => {
    //             // handle error
    //             
    //         });
    // }


    const uploadFiles = async (qr_id) => {
        if (isFileAvailable) {
            
            
            await axios.post(`https://api.freightopedia.world/api/v1/logistics/upload/quotation-request-document?qr_id=${qr_id}`, filesData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    // handle success
                    
                    
                    if (response.data.success) {
                        
                    }
                })
                .catch(error => {
                    // handle error
                    
                });
        } else {
            
        }
        setLoading(false);
        setIsModalOpen(true);
    }


    // const handleSubmit = async (e) => {
    //     setLoading(true);
    //     e.preventDefault();

    //     const requestBody = await getRequestBody()
    //     

    //     axios.post(`https://api.freightopedia.world/api/v1/logistics/create-quotation-request`, requestBody, {
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    //         }
    //     })
    //         .then(response => {
    //             // handle success
    //             
    //             

    //             if (response.data.success) {
    //                 setLoading(false);
    //                 setQuoteNo(response.data.quote_no)
    //                 setIsModalOpen(true)
    //                 // upload files
    //                 uploadFiles(response.data.quote_id)
    //             }
    //         })
    //         .catch(error => {
    //             // handle error
    //             
    //         });
    // }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // const isLoggedIn = localStorage.getItem("isLoggedIn")

        const requestBody = await getRequestBody()
        

        await axios.post(`https://api.freightopedia.world/api/v1/logistics/create-quotation-request`, requestBody, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(true);
                // handle success
                
                

                if (response.data.success) {
                    setQuoteNo(response.data.quote_no)
                    // upload files
                    uploadFiles(response.data.quote_id)
                }
            })
            .catch(error => {
                // handle error
                
            });

    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const isLoggedIn = localStorage.getItem("isLoggedIn")
    //     const requestBody = await getRequestBody()
    //     

    //     await axios.post(`https://api.freightopedia.world/api/v1/logistics/create-quotation-request`, requestBody, {
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    //         }
    //     })
    //         .then(response => {
    //             // handle success
    //             
    //             

    //             if (response.data.success) {
    //                 setIsModalOpen(true)
    //                 setQuoteNo(response.data.quote_no)

    //                 // upload files
    //                 uploadFiles(response.data.quote_id)
    //             }
    //         }).catch(error => {
    //             // handle error
    //             
    //         });
    // }


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Check if user is logged in
    //     const isLoggedIn = localStorage.getItem("isLoggedIn");

    //     // Validate form data
    //     const { requestBody, errors } = await getRequestBody();

    //     // If there are validation errors, display them and do not proceed with API call
    //     if (errors) {
    //         console.error('Validation errors:', errors);
    //         // Display errors to the user (you can implement this part)
    //         return;
    //     }

    //     try {
    //         // Make API call if no validation errors
    //         const response = await axios.post(
    //             `https://api.freightopedia.world/api/v1/logistics/create-quotation-request`,
    //             requestBody,
    //             {
    //                 headers: {
    //                     'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    //                 }
    //             }
    //         );

    //         // Handle success response
    //         
    //         

    //         if (response.data.success) {
    //             setQuoteNo(response.data.quote_no);

    //             // Upload files
    //             uploadFiles(response.data.quote_id);
    //         }
    //     } catch (error) {
    //         // Handle error
    //         console.error('API Error:', error);
    //         // Display error to the user (you can implement this part)
    //     }
    // };




    // const handleSubmit = async (e) => {

    //     e.preventDefault();

    //     // Validate form data
    //     const { requestBody, errors } = await getRequestBody();

    //     // If there are validation errors, display them and do not proceed with API call
    //     if (errors) {
    //         console.error('Validation errors:', errors);
    //         return; // Exit function
    //     } else {
    //         setLoading(true);
    //     }

    //     try {
    //         // Make API call if no validation errors
    //         const response = await axios.post(
    //             `https://api.freightopedia.world/api/v1/logistics/create-quotation-request`,
    //             requestBody,
    //             {
    //                 headers: {
    //                     'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    //                 }
    //             }
    //         );

    //         // Handle success response
    //         
    //         


    //         if (response.data.success) {
    //             setLoading(false);
    //             // Handle success actions
    //             setQuoteNo(response.data.quote_no)

    //             if (response.data.hazardous === 'true' || response.data.customs_clearance === 'true') {
    //                 
    //                 

    //                 uploadFiles(response.data.quote_id)
    //             } else {
    //                 
    //                 
    //                 setIsModalOpen(true);
    //             }
    //             // upload files

    //         }
    //     } catch (error) {
    //         // Handle API call error
    //         console.error('API Error:', error);
    //     }
    // };


    const Country = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cabo Verde", value: "Cabo Verde" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Congo, Democratic Republic of the", value: "Congo, Democratic Republic of the" },
        { label: "Congo, Republic of the", value: "Congo, Republic of the" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Korea, North", value: "Korea, North" },
        { label: "Korea, South", value: "Korea, South" },
        { label: "Kosovo", value: "Kosovo" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Madagascar", value: "Madagascar" },
        { label: "Malawi", value: "Malawi" },
        { label: "Malaysia", value: "Malaysia" },
        { label: "Maldives", value: "Maldives" },
        { label: "Mali", value: "Mali" },
        { label: "Malta", value: "Malta" },
        { label: "Marshall Islands", value: "Marshall Islands" },
        { label: "Mauritania", value: "Mauritania" },
        { label: "Mauritius", value: "Mauritius" },
        { label: "Mexico", value: "Mexico" },
        { label: "Micronesia", value: "Micronesia" },
        { label: "Moldova", value: "Moldova" },
        { label: "Monaco", value: "Monaco" },
        { label: "Mongolia", value: "Mongolia" },
        { label: "Montenegro", value: "Montenegro" },
        { label: "Morocco", value: "Morocco" },
        { label: "Mozambique", value: "Mozambique" },
        { label: "Myanmar", value: "Myanmar" },
        { label: "Namibia", value: "Namibia" },
        { label: "Nauru", value: "Nauru" },
        { label: "Nepal", value: "Nepal" },
        { label: "Netherlands", value: "Netherlands" },
        { label: "New Zealand", value: "New Zealand" },
        { label: "Nicaragua", value: "Nicaragua" },
        { label: "Niger", value: "Niger" },
        { label: "Nigeria", value: "Nigeria" },
        { label: "North Macedonia", value: "North Macedonia" },
        { label: "Norway", value: "Norway" },
        { label: "Oman", value: "Oman" },
        { label: "Pakistan", value: "Pakistan" },
        { label: "Palau", value: "Palau" },
        { label: "Palestine", value: "Palestine" },
        { label: "Panama", value: "Panama" },
        { label: "Papua New Guinea", value: "Papua New Guinea" },
        { label: "Paraguay", value: "Paraguay" },
        { label: "Peru", value: "Peru" },
        { label: "Philippines", value: "Philippines" },
        { label: "Poland", value: "Poland" },
        { label: "Portugal", value: "Portugal" },
        { label: "Qatar", value: "Qatar" },
        { label: "Romania", value: "Romania" },
        { label: "Russia", value: "Russia" },
        { label: "Rwanda", value: "Rwanda" },
        { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
        { label: "Saint Lucia", value: "Saint Lucia" },
        { label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
        { label: "Samoa", value: "Samoa" },
        { label: "San Marino", value: "San Marino" },
        { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
        { label: "Saudi Arabia", value: "Saudi Arabia" },
        { label: "Senegal", value: "Senegal" },
        { label: "Serbia", value: "Serbia" },
        { label: "Seychelles", value: "Seychelles" },
        { label: "Sierra Leone", value: "Sierra Leone" },
        { label: "Singapore", value: "Singapore" },
        { label: "Slovakia", value: "Slovakia" },
        { label: "Slovenia", value: "Slovenia" },
        { label: "Solomon Islands", value: "Solomon Islands" },
        { label: "Somalia", value: "Somalia" },
        { label: "South Africa", value: "South Africa" },
        { label: "South Sudan", value: "South Sudan" },
        { label: "Spain", value: "Spain" },
        { label: "Sri Lanka", value: "Sri Lanka" },
        { label: "Sudan", value: "Sudan" },
        { label: "Suriname", value: "Suriname" },
        { label: "Sweden", value: "Sweden" },
        { label: "Switzerland", value: "Switzerland" },
        { label: "Syria", value: "Syria" },
        { label: "Taiwan", value: "Taiwan" },
        { label: "Tajikistan", value: "Tajikistan" },
        { label: "Tanzania", value: "Tanzania" },
        { label: "Thailand", value: "Thailand" },
        { label: "Timor-Leste", value: "Timor-Leste" },
        { label: "Togo", value: "Togo" },
        { label: "Tonga", value: "Tonga" },
        { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
        { label: "Tunisia", value: "Tunisia" },
        { label: "Turkey", value: "Turkey" },
        { label: "Turkmenistan", value: "Turkmenistan" },
        { label: "Tuvalu", value: "Tuvalu" },
        { label: "Uganda", value: "Uganda" },
        { label: "Ukraine", value: "Ukraine" },
        { label: "United Arab Emirates", value: "United Arab Emirates" },
        { label: "United Kingdom", value: "United Kingdom" },
        { label: "United States", value: "United States" },
        { label: "Uruguay", value: "Uruguay" },
        { label: "Uzbekistan", value: "Uzbekistan" },
        { label: "Vanuatu", value: "Vanuatu" },
        { label: "Vatican City", value: "Vatican City" },
        { label: "Venezuela", value: "Venezuela" },
        { label: "Vietnam", value: "Vietnam" },
        { label: "Yemen", value: "Yemen" },
        { label: "Zambia", value: "Zambia" },
        { label: "Zimbabwe", value: "Zimbabwe" }
    ];


    const incoterm_option = [
        { label: "Select ", value: 0 },
        { label: "EXW ", value: "EXW" },
        { label: "CIP ", value: "CIP" },
        { label: "FCA ", value: "FCA" },
        { label: "DAP ", value: "DAP" },
        { label: "DPU ", value: "DPU" },
        { label: "CPT ", value: "CPT" },
        { label: "DDP ", value: "DDP" },
        { label: "FAS ", value: "FAS" },
        { label: "FOB ", value: "FOB" },
        { label: "CFR ", value: "CFR" },
        { label: "CIF ", value: "CIF" },
        { label: "Other", value: "Other" }

    ]

    const cargoTypeOption = [
        { label: "Select ", value: 0 },
        { label: "Packages ", value: "Packages" },
        { label: "Pallet ", value: "Pallet" },
        { label: "Bales ", value: "Bales" },
        { label: "Drums ", value: "Drums" },
        { label: "Crates ", value: "Crates" },
        { label: "Bag ", value: "Bag" },
        { label: "Box ", value: "Box" },
        { label: "Other", value: "Other" }

    ]

    const weight = [
        { label: "CM ", value: "cm" },
        { label: "INCHES ", value: "inches" },
        { label: "FEETS ", value: "feets" },
        { label: "METERS ", value: "meters" },
        { label: "Other", value: "Other" }
    ]

    const cargo_weight = [
        { label: "Kilos ", value: "Kilos" },
        { label: "Tonnes ", value: "Tonnes" },
        { label: "Tons ", value: "Tons" },
        { label: "Lbs ", value: "Lbs" },
        { label: "Other", value: "Other" }
    ]


    return (
        <div className='flex flex-col gap-5 md3:px-5  md3:py-8  px-5'>
            <div className="w-full flex justify-center mb-[90px]">
                <Nav />
            </div>
            <h1 className='text-[25px] font-bold leading-[15px] lg:leading-[0px]'>Create Inquiry</h1>
            <div className="text-[13px]">
                <Link to="/dashboard/request-quote" >Request Quote</Link> /
                <Link to="/dashboard/request-quote" >Choose Freight</Link> /
                <Link to="/create-quote-air" >Air Freight</Link>
            </div>
            <div className="">
                <h1 className='text-[17px] font-semibold  lg:text-[22px] ' >Basic Information</h1>
                <p className='text-[11px]' >fill the basic information for Air freight</p>
            </div>

            <div className="flex flex-row gap-5 justify-between items-center  xs:flex-row  xs:justify-normal xs:gap-5 w-full sm:w-full  sm:justify-between md3:w-full md3:gap-5 ">
                <div className="w-1/2 sm:w-1/3  md3:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] ' > Departure </label>
                    <Dropdown className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full' options={Country}
                        onChange={handleDepature}
                    />
                    {
                        depatureCountry === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={portOfDepartureOther}
                                    onChange={handleDepatureOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }

                </div>

                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Name of port</label>
                    <input type="text" placeholder='Name of port' className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={portOfDepature}
                        onChange={(e) => setPortOfDepature(e.target.value)}
                    />

                </div>

                <div className=" sm:w-1/3 md3:w-1/3"></div>
            </div>

            <div className="flex flex-row gap-5 justify-between items-center  xs:flex-row  xs:justify-normal xs:gap-5 w-full sm:w-full  sm:justify-between md3:w-full md3:gap-5 ">


                <div className="w-1/2 sm:w-1/3  md3:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px]' > Arrival </label>
                    <Dropdown className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full ' options={Country}
                        onChange={handleArrival}
                    />
                    {
                        arrivalCountry === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={portOfArrivalOther}
                                    onChange={handleArrivalOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                </div>

                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Name of port</label>
                    <input type="text" placeholder='Name of port' className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={portOfArrival}
                        onChange={(e) => setPortOfArrival(e.target.value)}
                    />
                    {errors.arrival && (
                        <span className="text-red-500 text-[13px]">{errors.arrival}</span>
                    )}
                </div>

                <div className=" sm:w-1/3 md3:w-1/3"></div>
            </div>

            <div className=" flex flex-col sm:flex-row sm:w-full gap-5 xl:items-center  ">
                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Commodity</label>
                    <input type="text" className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={commodity}
                        onChange={(e) => setCommodity(e.target.value)}
                    />
                    {errors.commodity && (
                        <span className="text-red-500 text-[13px]">{errors.commodity}</span>
                    )}
                </div>

                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Hs Code</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange} className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={hsCode}
                        onChange={(e) => setHsCode(e.target.value)}
                    />
                    {errors.hs_code && (
                        <span className="text-red-500 text-[13px]">{errors.hs_code}</span>
                    )}
                </div>

                <div className="sm:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Incoterm</label>
                    <Dropdown options={incoterm_option} className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full '
                        onChange={handleIncoterms}
                    />
                    {
                        incoterms === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={incotermOther}
                                    onChange={handleIncotermsOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="  flex flex-col md3:flex md3:flex-row gap-5 sm:flex-row  sm:w-full ">
                <div className="flex flex-col  sm:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Weight</label>

                    <div className="border-[#DDDDDD] border-[1px] bg-[#F6F6F6] p-1 flex flex-row justify-between items-center w-full  ">
                        <div className="w-full">
                            <input type="number" onWheel={numberInputOnWheelPreventChange} className='py-2 bg-[#F6F6F6] text-[13px]  outline-none w-full'
                                value={cargoWeight}
                                onChange={(e) => setCargoWeight(e.target.value)}
                            />

                        </div>
                        <div className="">
                            <Dropdown options={cargo_weight} value={typeOfCargoWeight} className=' place-self-end py-2 h-full outline-none text-[11px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD]'
                                onChange={handleTypeOfCargoWeight}
                            />

                        </div>


                    </div>
                    <div className="flex flex-row justify-between items-center">
                        {errors.cargo_weight && (
                            <span className="text-red-500 text-[13px]">{errors.cargo_weight}</span>
                        )}
                        {errors.cargo_weight_unit && (
                            <span className="text-red-500 text-[13px]">{errors.cargo_weight_unit}</span>
                        )}
                    </div>
                </div>

                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Total Packages</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange} className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={totalPackages}
                        onChange={(e) => setTotalPackages(e.target.value)}
                    />
                    {errors.total_packages && (
                        <span className="text-red-500 text-[13px]">{errors.total_packages}</span>
                    )}
                </div>

                <div className="sm:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Type </label>
                    <Dropdown options={cargoTypeOption} className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full '
                        onChange={handleTypeOfCargo}
                    />
                    {
                        typeOfCargo === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={cargoTypeOther}
                                    onChange={handleTypeOfCargoOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                </div>
            </div>


            <div className=" flex flex-col sm:flex-row sm:w-full sm:gap-5  md3:w-full md3:gap-5 sm:justify-between  ">
                {/* Dimensions */}
                <div className="flex flex-col sm:w-1/3 md3:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Dimensions</label>
                    <div className="flex flex-row gap-2 justify-between p-1  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] ">
                        <input
                            type="number" onWheel={numberInputOnWheelPreventChange}
                            placeholder='Length'
                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                            value={dimensionLength}
                            onChange={(e) => setDimensionLength(e.target.value)}
                        />

                        <input
                            type="number" onWheel={numberInputOnWheelPreventChange}
                            placeholder='Width'
                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                            value={dimensionWidth}
                            onChange={(e) => setDimensionWidth(e.target.value)}
                        />

                        <input
                            type="number" onWheel={numberInputOnWheelPreventChange}
                            placeholder='Height'
                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                            value={dimensionHeight}
                            onChange={(e) => setDimensionHeight(e.target.value)}
                        />


                        <Dropdown options={weight} className='  outline-none text-[11px] bg-[#F6F6F6]'
                            onChange={handleTypeOfDimensions}
                        />
                    </div>
                    {errors.dimensions_height && (
                        <span className="text-red-500 text-[13px]">{errors.dimensions_height}</span>
                    )}
                    {errors.dimensions_width && (
                        <span className="text-red-500 text-[13px]">{errors.dimensions_width}</span>
                    )}

                    {errors.dimensions_length && (
                        <span className="text-red-500 text-[13px]">{errors.dimensions_length}</span>
                    )}

                    {errors.dimensions_unit && (
                        <span className="text-red-500 text-[13px]">{errors.dimensions_unit}</span>
                    )}
                </div>

                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >CBM</label>
                    <input disabled type="number" onWheel={numberInputOnWheelPreventChange} className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={cbm}
                    />
                    {errors.cbm && (
                        <span className="text-red-500 text-[13px]">{errors.cbm}</span>
                    )}
                </div>

                {/* date picker */}
                <div className="flex flex-col sm:w-1/3 md3:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Ready Date</label>
                    <div className=" flex flex-row  items-center justify-between py-2  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] px-[10px] ">
                        <DatePicker
                            selected={cargoReadyDate}
                            onChange={(date) => setCargoReadyDate(date)}
                            ref={datePickerRef}
                            className='outline-none bg-[#F6F6F6] w-[150px] ' />
                        <MdDateRange onClick={handleIconClick} className='cursor-pointer' />
                    </div>
                    {errors.cargo_ready_date && (
                        <span className="text-red-500 text-[13px]">{errors.cargo_ready_date}</span>
                    )}

                </div>

            </div>


            {/* additional information */}

            <div className="flex flex-col gap-5">

                <div className="">
                    <h1 className='font-bold' >Additional Information</h1>
                    <h1 className='text-[13px]'>fill the Additional information for ocean freight</h1>
                </div>

                {/* stackable , non sackable , hazardous */}

                <div className="  flex flex-col gap-3 sm:flex-row sm:w-full md3:flex-row  md3:w-full  ">

                    <div className="flex flex-col gap-3 sm:w-1/2  md3:w-1/3 ">
                        <div className="flex flex-row items-center gap-1 ">
                            <input
                                type="checkbox"
                                checked={isStackable}
                                onChange={() => handleIsStackable()}
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Stackable</label>
                        </div>


                        <div className="flex flex-row items-center gap-1 ">
                            <input
                                type="checkbox"
                                checked={isNonStackable}
                                onChange={() => handleIsNonStackable()}
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Non - Stackable</label>
                        </div>


                        {isNonStackable && (
                            <div className="">
                                <div className="flex flex-row gap-2 justify-between p-1  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] ">
                                    <input
                                        type="number" onWheel={numberInputOnWheelPreventChange}
                                        placeholder='L'
                                        className='border-[#DDDDDD] text-[13px] w-1/3  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                        value={dimensionLength}
                                        onChange={(e) => setDimensionLength(e.target.value)}
                                    />
                                    {errors.dimensions_length && (
                                        <span className="text-red-500 text-[13px]">{errors.dimensions_length}</span>
                                    )}
                                    <input
                                        type="number" onWheel={numberInputOnWheelPreventChange}
                                        placeholder='W'
                                        className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                        value={dimensionWidth}
                                        onChange={(e) => setDimensionWidth(e.target.value)}
                                    />
                                    {errors.dimensions_width && (
                                        <span className="text-red-500 text-[13px]">{errors.dimensions_width}</span>
                                    )}
                                    <input
                                        type="number" onWheel={numberInputOnWheelPreventChange}
                                        placeholder='H'
                                        className='border-[#DDDDDD] text-[13px] w-1/3  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                        value={dimensionHeight}
                                        onChange={(e) => setDimensionHeight(e.target.value)}
                                    />
                                    {errors.dimensions_height && (
                                        <span className="text-red-500 text-[13px]">{errors.dimensions_height}</span>
                                    )}

                                    <Dropdown options={weight} className='  outline-none text-[11px] bg-[#F6F6F6]'
                                        onChange={handleTypeOfDimensions}
                                    />
                                </div>
                                {errors.dimensions_unit && (
                                    <span className="text-red-500 text-[13px]">{errors.dimensions_unit}</span>
                                )}
                            </div>
                        )}

                    </div>

                    <div className="flex flex-col gap-3 sm:w-1/2 md3:w-1/3">
                        <div className="flex flex-row items-center gap-1  ">
                            <input
                                type="checkbox"
                                checked={hazardous}
                                onChange={() => handlehazardous()}
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Hazardous</label>
                        </div>


                        {hazardous && (
                            renderFileSection('hazardous_document')
                        )}

                    </div>

                    <div className="w-1/3">
                        {fileNames["hazardous_document"].length > 0 && (
                            <div className="mt-2 overflow-auto max-h-[200px] w-full">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border-b-2 border-gray-300 px-4 py-2">Hazardous Document</th>
                                            <th className="border-b-2 border-gray-300 px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileNames["hazardous_document"].map((fileName, index) => (
                                            <tr key={index}>
                                                <td className="border-b px-4 py-2 text-[12px]">{fileName}</td>
                                                <td className="border-b px-4 py-2 text-center">
                                                    <button
                                                        onClick={() => handleFileDelete("hazardous_document", index)}
                                                        className="text-red-600 hover:text-red-800 text-[12px]"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    {/* <div className="md3:w-1/3"></div> */}
                </div>



                {/* Transportation & Custom Clearance  */}
                <div className="gap-3 flex flex-col sm:flex-row sm:w-full md3:flex-row md3:w-full ">
                    {/* transportation */}
                    <div className="flex flex-col gap-3 sm:w-1/3 md3:w-1/3">
                        <div className="flex flex-row items-center gap-1 ">
                            <input
                                type="checkbox"
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                checked={transportation}
                                onChange={() => handleTransportation()}
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Transportation</label>
                        </div>

                        {
                            transportation && (
                                <div className="pl-8">
                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            checked={transportationOrigin}
                                            onChange={() => handleTransportationOrigin()}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Origin </label>
                                    </div>

                                    {transportation && transportationOrigin && (
                                        <div className="">
                                            <div className="flex flex-col gap-3">
                                                <div className="w-full flex flex-row  gap-2">
                                                    <input
                                                        type="text"
                                                        placeholder='Pick up Add.'
                                                        className='border-[#DDDDDD] text-[13px]  w-1/2  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={originAddOfOrigin}
                                                        onChange={(e) => setOriginAddOfOrigin(e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder='Drop Add.'
                                                        className='border-[#DDDDDD] text-[13px] w-1/2 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={originAddOfDestination}
                                                        onChange={(e) => setOriginAddOfDestination(e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            checked={transportationDestination}
                                            onChange={() => handleTransportationDestination()}
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Destination</label>
                                    </div>

                                    {transportation && transportationDestination && (
                                        <div className="">
                                            <div className="flex flex-col gap-3">
                                                <div className="w-full flex flex-row  gap-2">
                                                    <input
                                                        type="text"
                                                        placeholder='Pick up Add.'
                                                        className='border-[#DDDDDD] text-[13px]  w-1/2  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={originAddress}
                                                        onChange={(e) => setOriginAddress(e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder='Drop Add.'
                                                        className='border-[#DDDDDD] text-[13px] w-1/2 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={destinationAddress}
                                                        onChange={(e) => setDestinationAddress(e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    )}


                                </div>
                            )
                        }
                    </div>


                    {/* custom clearance */}
                    <div className="flex flex-col gap-3 sm:w-1/3  md3:w-1/3 ">
                        <div className="flex flex-row items-center gap-1 ">
                            <input
                                type="checkbox"
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                checked={customClearance}
                                onChange={() => handleCustomClearance()}
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Custom Clearance</label>
                        </div>

                        {
                            customClearance && (
                                <div className="pl-8">
                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            checked={customClearanceOrigin}
                                            onChange={() => handleCustomClearanceOrigin()}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Origin </label>
                                    </div>

                                    {customClearance && customClearanceOrigin && (
                                        renderFileSection('customs_clearance_origin_document')
                                    )}

                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            checked={customClearanceDestination}
                                            onChange={() => handleCustomClearanceDestination()}
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Destination</label>
                                    </div>

                                    {customClearance && customClearanceDestination && (
                                        renderFileSection('customs_clearance_destination_document')
                                    )}


                                </div>
                            )
                        }


                    </div>


                    <div className="w-1/3 flex flex-col gap-10 justify-center ">
                        {fileNames["customs_clearance_origin_document"].length > 0 && (
                            <div className="mt-2 overflow-auto max-h-[200px] w-full">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border-b-2 border-gray-300 px-4 py-2">Origin Document</th>
                                            <th className="border-b-2 border-gray-300 px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileNames["customs_clearance_origin_document"].map((fileName, index) => (
                                            <tr key={index}>
                                                <td className="border-b px-4 py-2 text-[12px]">{fileName}</td>
                                                <td className="border-b px-4 py-2 text-center">
                                                    <button
                                                        onClick={() => handleFileDelete("customs_clearance_origin_document", index)}
                                                        className="text-red-600 hover:text-red-800 text-[12px]"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {fileNames["customs_clearance_destination_document"].length > 0 && (
                            <div className="mt-2 overflow-auto max-h-[200px] w-full">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border-b-2 border-gray-300 px-4 py-2">Destination Document</th>
                                            <th className="border-b-2 border-gray-300 px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileNames["customs_clearance_destination_document"].map((fileName, index) => (
                                            <tr key={index}>
                                                <td className="border-b px-4 py-2 text-[12px]">{fileName}</td>
                                                <td className="border-b px-4 py-2 text-center">
                                                    <button
                                                        onClick={() => handleFileDelete("customs_clearance_destination_document", index)}
                                                        className="text-red-600 hover:text-red-800 text-[12px]"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <div className="md3:w-full ">
                {/* Additional notes */}
                <div className="md3:w-full  py-10">
                    <div className="flex flex-col sm:gap-8 md3:w-full md3:gap-8">
                        <div className="flex flex-col  ">
                            <label htmlFor="additional-notes" className='text-[13px] leading-[35px] text-[#525252] '>Additional notes</label>
                            <textarea
                                id="additional-notes"
                                type="text"
                                className='h-[100px] border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] sm:h-[100px] sm:w-2/3 md3:h-[100px] md3:w-2/3'
                                value={addtionalNotes}
                                onChange={(e) => setAddtionalNotes(e.target.value)}
                            />

                        </div>


                    </div>
                    {errors.addtional_notes && (
                        <span className="text-red-500 text-[13px]">{errors.addtional_notes}</span>
                    )}
                </div>
                <div className="w-full flex flex-row  justify-end     ">

                    <div className=" w-full sm:w-1/3 sm:px-8 ">
                        <Button
                            bgColor="h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary md3:h-[50px] md3:rounded-[5px] w-full m-auto "
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>


            {/* modal code */}
            {isModalOpen && (
                <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center' >
                    <div className="bg-white rounded-[10px] p-5 flex flex-col gap-5 items-center justify-center">
                        <FaRegCheckCircle className='text-[120px] text-[#20BE79] ' />
                        <div className="text-center">
                            <h1 className='text-[#20BE79] text-[30px] font-semibold'>Quotation request created</h1>
                            <h1 className='text-[#5E5E5E]' >Your quotation request has been send succesfully</h1>
                        </div>

                        <div className="text-center">
                            <h1 className='text-[#5E5E5E]' >Your Quote number</h1>
                            <h1 className='font-bold' >{quoteNo}</h1>
                        </div>
                        <div className="">
                            <Button bgColor="w-[130px] h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]"
                                onClick={() => {
                                    setIsModalOpen(false)
                                    window.location = "/dashboard/request-quote"
                                }}
                            >
                                Done</Button>
                        </div>


                    </div>
                </div>
            )}

            {/* loading */}
            {loading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </div>
            }
        </div >
    )
}

export default CreateQuoteAir