import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from './Dropdown';
import { ImUpload } from "react-icons/im";
import uploadICon2 from '../assets/uploadd.jpeg'
import Button from './Button';
import OpenTop from './OpenTop';
import Refeer from './Refeer';
import Tank from './Tank';
import { MdDateRange } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCheckCircle } from "react-icons/fa";
import { Circles } from 'react-loader-spinner';
import Nav from '../components/Nav'



const CreateQuote = () => {
    const [errors, setErrors] = useState({});
    const datePickerRef = useRef(null);
    const inputRefs = {
        hazardous_document: useRef(null),
        customs_clearance_origin_document: useRef(null),
        customs_clearance_destination_document: useRef(null),
        tank_document: useRef(null),
    };


    const [portOfLoadingCountry, setPortOfLoadingCountry] = useState('');
    const [portOfDischargeCountry, setPortOfDischargeCountry] = useState('');
    const [loadingPort, setLoadingPort] = useState('')
    const [dischargePort, setDischargePort] = useState('')
    const [containerType, setContainerType] = useState('');
    const [containerQuantity, setContainerQuantity] = useState('');
    const [commodity, setCommodity] = useState('');
    const [hsCode, setHsCode] = useState('');
    const [incoterms, setIncoterms] = useState('');
    const [cargoWeight, setCargoWeight] = useState('');
    const [typeOfCargo, setTypeOfCargo] = useState('');
    const [typeOfCargoWeight, setTypeOfCargoWeight] = useState('Kilos');
    const [typeOfDimesions, setTypeOfDimensions] = useState('CM');
    const [typeOfNonStackable, setTypeOfNonStackable] = useState('');
    const [totalPackages, setTotalPackages] = useState('');
    const [cargoReadyDate, setCargoReadyDate] = useState(new Date());

    const [isStackable, setIsStackable] = useState(false);
    const [isNonStackable, setIsNonStackable] = useState(false);
    const [dimensionLength, setDimensionLength] = useState('');
    const [dimensionWidth, setDimensionWidth] = useState('');
    const [dimensionHeight, setDimensionHeight] = useState('');
    const [hazardous, setHazardous] = useState(false);
    const [transportation, setTransportation] = useState(false);
    const [transportationOrigin, setTransportationOrigin] = useState(false);
    const [originAddOfOrigin, setOriginAddOfOrigin] = useState('');
    const [originAddOfDestination, setOriginAddOfDestination] = useState('');
    const [originTypeOfWeight, setOriginTypeOfWeight] = useState('');
    const [transportationDestination, setTransportationDestination] = useState(false);
    const [destinationAddress, setDestinationAddress] = useState('');
    const [originAddress, setOriginAddress] = useState('');

    const [destinationTypeOfWeight, setDestinationTypeOfWeight] = useState('');
    const [customClearance, setCustomClearance] = useState(false);
    const [customClearanceOrigin, setCustomClearanceOrigin] = useState(false);
    const [customClearanceDestination, setCustomClearanceDestination] = useState(false);
    const [addtionalNotes, setAddtionalNotes] = useState('');
    const [formType, setFormType] = useState('select')
    const [loading, setLoading] = useState(false);
    const [isFileAvailable, setIsFileAvailable] = useState(false);

    const [portOfLoadingOther, setPortOfLoadingOther] = useState('');
    const [portOfDischargeOther, setPortOfDischargeOther] = useState('');
    const [incotermOther, setIncotermOther] = useState('');
    const [cargoTypeOther, setCargoTypeOther] = useState('');


    const [fileNames, setFileNames] = useState({
        hazardous_document: [],
        customs_clearance_origin_document: [],
        customs_clearance_destination_document: [],
        tank_document: [],
    });

    const [filesData, setFilesData] = useState({
        hazardous_document: [],
        customs_clearance_origin_document: [],
        customs_clearance_destination_document: [],
        tank_document: [],
    });

    const handleImageClick = (section) => {
        inputRefs[section].current.click();
    };


    // const handleImageChange = (event, section) => {
    //     setIsFileAvailable(true);
    //     const newFiles = Array.from(event.target.files);
    //     
    //     

    //     const newFileNames = newFiles.map(file => file.name);
    //     setFileNames(prevFileNames => ({
    //         ...prevFileNames,
    //         [section]: [...prevFileNames[section], ...newFileNames]
    //     }));

    //     const newFileData = newFiles.map(file => file);
    //     setFilesData(prevFileNames => ({
    //         ...prevFileNames,
    //         [section]: [...prevFileNames[section], ...newFileData]
    //     }));

    // };

    const handleImageChange = (event, section) => {
        setIsFileAvailable(true);
        const newFiles = Array.from(event.target.files);
        
        

        const newFileNames = newFiles.map(file => file.name);
        setFileNames(prevFileNames => ({
            ...prevFileNames,
            [section]: [...prevFileNames[section], ...newFileNames]
        }));

        const newFileData = newFiles.map(file => file);
        setFilesData(prevFileNames => ({
            ...prevFileNames,
            [section]: [...prevFileNames[section], ...newFileData]
        }));
    };

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }


    // reefer
    const [temperature, setTemperature] = useState('');
    const [ventilation, setVentilation] = useState('');
    const [humidity, setHumidity] = useState('');

    // open-top | flat-rack
    const [outGauge, setOutGauge] = useState(false);
    const [inGauge, setInGauge] = useState(false);

    // state for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quoteNo, setQuoteNo] = useState('');




    const handleContainerType = (option) => {
        setContainerType(option.label)
        

        if (option.label.includes("Dry")) {
            
            setFormType("dry")

        } else if (option.label.includes("Reefer")) {
            
            setFormType("reefer")

        } else if (option.label.includes("Open Top")) {
            
            setFormType("open-top")

        } else if (option.label.includes("Flat Rack")) {
            
            setFormType("flat-rack")

        } else if (option.label.includes("Tank")) {
            
            setFormType("tank")
        } else {
            setFormType("select")
        }
    };


    const handlePortOfLoading = (option) => {
        setPortOfLoadingCountry(option.label)
        if (portOfLoadingCountry !== 'Other') {
            setPortOfLoadingOther('')
        }
    };

    const handlePortOfLoadingOther = (e) => {
        setPortOfLoadingOther(e.target.value)
    };


    const handlePortOfDischarge = (option) => {
        setPortOfDischargeCountry(option.label)
        if (portOfDischargeCountry !== 'Other') {
            setPortOfDischargeOther('')
        }
    };

    const handlePortOfDischargeOther = (e) => {
        setPortOfDischargeOther(e.target.value)
    };

    const handleIncoterms = (option) => {
        setIncoterms(option.label)
        if (incoterms !== 'Other') {
            setIncotermOther('')
        }
    };

    const handleIncotermsOther = (e) => {
        setIncotermOther(e.target.value)
    };

    const handleTypeOfCargo = (option) => {
        setTypeOfCargo(option.label)
        if (typeOfCargo !== 'Other') {
            setCargoTypeOther('')
        }
    };

    const handleTypeOfCargoOther = (e) => {
        setCargoTypeOther(e.target.value)
    };

    const handleTypeOfCargoWeight = (option) => {
        setTypeOfCargoWeight(option.label)
    };

    const handleTypeOfDimensions = (option) => {
        setTypeOfDimensions(option.label)
    };

    const handleTypeOfNonStackable = (option) => {
        setTypeOfNonStackable(option.label);
    }

    const handleTypeOfOrigin = (option) => {
        setOriginTypeOfWeight(option.label);
    }

    const handleTypeOfDestination = (option) => {
        setDestinationTypeOfWeight(option.label);
    }

    const handleCargoReadyDate = () => {

    };

    const handleIsStackable = () => {
        // setIsStackable(!isStackable)
        if (!isStackable) {
            setIsNonStackable(false);
        }
        setIsStackable(!isStackable);
    };

    const handleIsNonStackable = () => {
        // setIsNonStackable(!isNonStackable)
        if (!isNonStackable) {
            setIsStackable(false);
        }
        setIsNonStackable(!isNonStackable);
    };

    const handlehazardous = () => {
        setHazardous(!hazardous)
    };

    const handleTransportation = () => {
        setTransportation(!transportation)
    };

    const handleTransportationOrigin = () => {
        setTransportationOrigin(!transportationOrigin)
    };

    const handleTransportationDestination = () => {
        setTransportationDestination(!transportationDestination)
    };

    const handleCustomClearance = () => {
        setCustomClearance(!customClearance)
    };

    const handleCustomClearanceOrigin = () => {
        setCustomClearanceOrigin(!customClearanceOrigin)
    };

    const handleCustomClearanceDestination = () => {
        setCustomClearanceDestination(!customClearanceDestination)
    };

    const handleInGauge = () => {
        setInGauge(!inGauge);
        if (!inGauge) {
            setOutGauge(false);
        }
        setInGauge(!inGauge);
    }

    const handleOutGauge = () => {

        if (!outGauge) {
            setInGauge(false);
        }
        setOutGauge(!outGauge);
    }

    const handleIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setFocus();
        }
    };


    const renderFileSection = (section) => (
        <div className="flex flex-col items-center justify-center cursor-pointer min-w-2/3 lg:w-1/2  md:flex md:flex-row md:items-center md:gap-5">
            <div
                onClick={() => handleImageClick(section)}
                className="p-5 w-full border-[#49B5FD] border-dashed border-[1px] flex flex-col items-center justify-center gap-2"
            >
                <img src={uploadICon2} alt="" className="w-[50px]" />
                <h1 className="text-[10px]">Select Files </h1>
            </div>
            <input
                type="file"
                name="files"
                ref={inputRefs[section]}
                onChange={(event) => handleImageChange(event, section)}
                className="cursor-pointer"
                style={{ display: 'none' }}
                multiple
            />
            {/* {fileNames[section].length > 0 && (
                <div className="mt-2 space-y-3">
                    {fileNames[section].map((fileName, index) => (
                        <h2 key={index} className="text-[12px]">{fileName}</h2>
                    ))}
                </div>
            )} */}
        </div>

    );


    const getRequestBody = async () => {
        let validationErrors = {};
        if ((formType === "open-top" || formType === "flat-rack") && outGauge) {
            if (!dimensionLength) validationErrors.dimensionLength = "Length is required.";
            if (!dimensionWidth) validationErrors.dimensionWidth = "Width is required.";
            if (!dimensionHeight) validationErrors.dimensionHeight = "Height is required.";
        }
        if (Object.keys(validationErrors).length > 0) {
            return { requestBody: null, errors: validationErrors };
        }


        let requestBody = {
            freight_medium: "ocean",
            freight_type: "FCL",
            form_type: formType,
            // loading_port_country: portOfLoadingCountry,
            loading_port_country: portOfLoadingOther ? portOfLoadingOther : portOfLoadingCountry,
            loading_port: loadingPort,
            // discharge_port_country: portOfDischargeCountry,
            discharge_port_country: portOfDischargeOther ? portOfDischargeOther : portOfDischargeCountry,
            discharge_port: dischargePort,
            container_type: containerType,
            container_quantity: containerQuantity,
            commodity: commodity,
            hs_code: hsCode,
            // incoterm: incoterms,
            incoterm: incotermOther ? incotermOther : incoterms,
            cargo_weight: cargoWeight,
            cargo_weight_unit: typeOfCargoWeight,
            // cargo_type: typeOfCargo,
            cargo_type: cargoTypeOther ? cargoTypeOther : typeOfCargo,
            total_packages: totalPackages,
            cargo_ready_date: cargoReadyDate,
            addtional_notes: addtionalNotes,
            dimensions_length: dimensionLength,
            dimensions_width: dimensionWidth,
            dimensions_height: dimensionHeight,
            dimensions_unit: typeOfDimesions,
            stackable: isStackable,
            non_stackable: isNonStackable,
            hazardous: hazardous,
            transportation: transportation,
            transportation_origin: transportationOrigin,
            transportation_origin_pickup_address: originAddOfOrigin,
            transportation_origin_drop_address: originAddOfDestination,
            transportation_destination: transportationDestination,
            transportation_destination_pickup_address: originAddress,
            transportation_destination_drop_address: destinationAddress,
            customs_clearance: customClearance,
            customs_clearance_origin: customClearanceOrigin,
            customs_clearance_destination: customClearanceDestination,

        };

        if (formType === "reefer") {
            requestBody.temperature = temperature;
            requestBody.ventilation = ventilation;
            requestBody.humidity = humidity;
        }

        if (formType === "open-top") {
            delete requestBody.hazardous;
            requestBody.in_gauge = inGauge;
            requestBody.out_gauge = outGauge;
        }

        if (formType === "flat-rack") {
            delete requestBody.hazardous;
            requestBody.in_gauge = inGauge;
            requestBody.out_gauge = outGauge;
        }

        if (formType === "tank") {
            delete requestBody.container_quantity;
            delete requestBody.total_packages;
            delete requestBody.dimensions;
            delete requestBody.stackable;
            delete requestBody.non_stackable;
        }

        return { requestBody, errors: null };
    }




    const uploadFiles = async (qr_id) => {

        if (isFileAvailable) {
            
            

            await axios.post(`https://api.freightopedia.world/api/v1/logistics/upload/quotation-request-document?qr_id=${qr_id}`, filesData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    // handle success
                    
                    

                    if (response.data.success) {
                        

                    }
                })
                .catch(error => {
                    // handle error
                    
                });
        } else {
            
        }
        setIsModalOpen(true);

    }

    const handleFileDelete = (section, index) => {
        setFileNames(prevState => ({
            ...prevState,
            [section]: prevState[section].filter((_, i) => i !== index)
        }));
        setFilesData(prevState => ({
            ...prevState,
            [section]: prevState[section].filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async (e) => {
        // setLoading(true);
        e.preventDefault();
        const { requestBody, errors } = await getRequestBody();

        // If there are validation errors, display them and do not proceed with API call
        if (errors) {
            console.error('Validation errors:', errors);
            // Optionally, set the errors in the state to display them in the UI
            setErrors(errors);
            return;
        }

        setLoading(true);

        // const requestBody = await getRequestBody()
        

        axios.post(`https://api.freightopedia.world/api/v1/logistics/create-quotation-request`, requestBody, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                // handle success
                
                

                if (response.data.success) {
                    setLoading(false);
                    setQuoteNo(response.data.quote_no)
                    // upload files
                    uploadFiles(response.data.quote_id)
                }
            })
            .catch(error => {
                // handle error
                
            });
    }

    const clearError = (field) => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            delete newErrors[field];
            return newErrors;
        });
    };


    const weight = [
        { label: "CM ", value: "cm" },
        { label: "INCHES ", value: "inches" },
        { label: "FEETS ", value: "feets" },
        { label: "METERS ", value: "meters" },
    ]

    const cargo_weight = [
        { label: "Kilos ", value: "Kilos" },
        { label: "Tonnes ", value: "Tonnes" },
        { label: "Tons ", value: "Tons" },
        { label: "Lbs ", value: "Lbs" },
    ]

    // const container = [
    //     { label: "Select ", value: 0 },
    //     { label: "20' Dry", value: 1 },
    //     { label: "40' Dry", value: 2 },
    //     { label: "40' Dry High", value: 3 },
    //     { label: "45' Dry High", value: 4 },
    //     { label: "20' Reefer", value: 5 },
    //     { label: "40' Reefer", value: 6 },
    //     { label: "20' Open Top", value: 7 },
    //     { label: "40' Open Top", value: 8 },
    //     { label: "20' Flat Rack", value: 9 },
    //     { label: "40' Flat Rack", value: 10 },
    //     { label: "20' Tank", value: 11 },
    // ]


    const container = [
        { label: "Select", value: "Select" },
        { label: "20' Dry", value: "20' Dry" },
        { label: "40' Dry", value: "40' Dry" },
        { label: "40' Dry High", value: "40' Dry High" },
        { label: "45' Dry High", value: "45' Dry High" },
        { label: "20' Reefer", value: "20' Reefer" },
        { label: "40' Reefer", value: "40' Reefer" },
        { label: "20' Open Top", value: "20' Open Top" },
        { label: "40' Open Top", value: "40' Open Top" },
        { label: "20' Flat Rack", value: "20' Flat Rack" },
        { label: "40' Flat Rack", value: "40' Flat Rack" },
        { label: "20' Tank", value: "20' Tank" },
    ];



    const incoterm_option = [
        { label: "Select ", value: 0 },
        { label: "EXW ", value: "EXW" },
        { label: "CIP ", value: "CIP" },
        { label: "FCA ", value: "FCA" },
        { label: "DAP ", value: "DAP" },
        { label: "DPU ", value: "DPU" },
        { label: "CPT ", value: "CPT" },
        { label: "DDP ", value: "DDP" },
        { label: "FAS ", value: "FAS" },
        { label: "FOB ", value: "FOB" },
        { label: "CFR ", value: "CFR" },
        { label: "CIF ", value: "CIF" },
        { label: "Other", value: "Other" },
    ]

    const cargoTypeOption = [
        { label: "Select ", value: 0 },
        { label: "Packages ", value: "Packages" },
        { label: "Pallet ", value: "Pallet" },
        { label: "Bales ", value: "Bales" },
        { label: "Drums ", value: "Drums" },
        { label: "Crates ", value: "Crates" },
        { label: "Bag ", value: "Bag" },
        { label: "Box ", value: "Box" },
        { label: "Other", value: "Other" },

    ]

    const Country = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cabo Verde", value: "Cabo Verde" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Congo, Democratic Republic of the", value: "Congo, Democratic Republic of the" },
        { label: "Congo, Republic of the", value: "Congo, Republic of the" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Korea, North", value: "Korea, North" },
        { label: "Korea, South", value: "Korea, South" },
        { label: "Kosovo", value: "Kosovo" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Madagascar", value: "Madagascar" },
        { label: "Malawi", value: "Malawi" },
        { label: "Malaysia", value: "Malaysia" },
        { label: "Maldives", value: "Maldives" },
        { label: "Mali", value: "Mali" },
        { label: "Malta", value: "Malta" },
        { label: "Marshall Islands", value: "Marshall Islands" },
        { label: "Mauritania", value: "Mauritania" },
        { label: "Mauritius", value: "Mauritius" },
        { label: "Mexico", value: "Mexico" },
        { label: "Micronesia", value: "Micronesia" },
        { label: "Moldova", value: "Moldova" },
        { label: "Monaco", value: "Monaco" },
        { label: "Mongolia", value: "Mongolia" },
        { label: "Montenegro", value: "Montenegro" },
        { label: "Morocco", value: "Morocco" },
        { label: "Mozambique", value: "Mozambique" },
        { label: "Myanmar", value: "Myanmar" },
        { label: "Namibia", value: "Namibia" },
        { label: "Nauru", value: "Nauru" },
        { label: "Nepal", value: "Nepal" },
        { label: "Netherlands", value: "Netherlands" },
        { label: "New Zealand", value: "New Zealand" },
        { label: "Nicaragua", value: "Nicaragua" },
        { label: "Niger", value: "Niger" },
        { label: "Nigeria", value: "Nigeria" },
        { label: "North Macedonia", value: "North Macedonia" },
        { label: "Norway", value: "Norway" },
        { label: "Oman", value: "Oman" },
        { label: "Pakistan", value: "Pakistan" },
        { label: "Palau", value: "Palau" },
        { label: "Palestine", value: "Palestine" },
        { label: "Panama", value: "Panama" },
        { label: "Papua New Guinea", value: "Papua New Guinea" },
        { label: "Paraguay", value: "Paraguay" },
        { label: "Peru", value: "Peru" },
        { label: "Philippines", value: "Philippines" },
        { label: "Poland", value: "Poland" },
        { label: "Portugal", value: "Portugal" },
        { label: "Qatar", value: "Qatar" },
        { label: "Romania", value: "Romania" },
        { label: "Russia", value: "Russia" },
        { label: "Rwanda", value: "Rwanda" },
        { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
        { label: "Saint Lucia", value: "Saint Lucia" },
        { label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
        { label: "Samoa", value: "Samoa" },
        { label: "San Marino", value: "San Marino" },
        { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
        { label: "Saudi Arabia", value: "Saudi Arabia" },
        { label: "Senegal", value: "Senegal" },
        { label: "Serbia", value: "Serbia" },
        { label: "Seychelles", value: "Seychelles" },
        { label: "Sierra Leone", value: "Sierra Leone" },
        { label: "Singapore", value: "Singapore" },
        { label: "Slovakia", value: "Slovakia" },
        { label: "Slovenia", value: "Slovenia" },
        { label: "Solomon Islands", value: "Solomon Islands" },
        { label: "Somalia", value: "Somalia" },
        { label: "South Africa", value: "South Africa" },
        { label: "South Sudan", value: "South Sudan" },
        { label: "Spain", value: "Spain" },
        { label: "Sri Lanka", value: "Sri Lanka" },
        { label: "Sudan", value: "Sudan" },
        { label: "Suriname", value: "Suriname" },
        { label: "Sweden", value: "Sweden" },
        { label: "Switzerland", value: "Switzerland" },
        { label: "Syria", value: "Syria" },
        { label: "Taiwan", value: "Taiwan" },
        { label: "Tajikistan", value: "Tajikistan" },
        { label: "Tanzania", value: "Tanzania" },
        { label: "Thailand", value: "Thailand" },
        { label: "Timor-Leste", value: "Timor-Leste" },
        { label: "Togo", value: "Togo" },
        { label: "Tonga", value: "Tonga" },
        { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
        { label: "Tunisia", value: "Tunisia" },
        { label: "Turkey", value: "Turkey" },
        { label: "Turkmenistan", value: "Turkmenistan" },
        { label: "Tuvalu", value: "Tuvalu" },
        { label: "Uganda", value: "Uganda" },
        { label: "Ukraine", value: "Ukraine" },
        { label: "United Arab Emirates", value: "United Arab Emirates" },
        { label: "United Kingdom", value: "United Kingdom" },
        { label: "United States", value: "United States" },
        { label: "Uruguay", value: "Uruguay" },
        { label: "Uzbekistan", value: "Uzbekistan" },
        { label: "Vanuatu", value: "Vanuatu" },
        { label: "Vatican City", value: "Vatican City" },
        { label: "Venezuela", value: "Venezuela" },
        { label: "Vietnam", value: "Vietnam" },
        { label: "Yemen", value: "Yemen" },
        { label: "Zambia", value: "Zambia" },
        { label: "Zimbabwe", value: "Zimbabwe" }
    ];


    return (
        <div className='flex flex-col gap-5 md3:px-5  md3:py-8 '>
            <div className="w-full flex justify-center mb-[90px]">
                <Nav />
            </div>
            <h1 className='text-[25px] font-bold leading-[15px] lg:leading-[0px]'>Create Inquiry</h1>
            <div className="text-[13px]">
                <Link to="/dashboard/request-quote" >Request Quote</Link> /
                <Link to="/dashboard/request-quote" >Choose Freight</Link> /
                <Link to="/create-quote-fcl" >Ocean Freight (FCL)</Link>
            </div>
            <div className="">
                <h1 className='text-[17px] font-semibold  lg:text-[22px] ' >Basic Information</h1>
                <p className='text-[11px]' >fill the basic information for ocean freight</p>
            </div>

            <div className="flex flex-row gap-5 justify-between items-center  xs:flex-row  xs:justify-normal xs:gap-5 w-full sm:w-full  sm:justify-between md3:w-full md3:gap-5 ">
                <div className="w-1/2 sm:w-1/3  md3:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] ' >Port of Loading </label>
                    <Dropdown className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full' options={Country}
                        onChange={handlePortOfLoading}
                    />
                    {
                        portOfLoadingCountry === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={portOfLoadingOther}
                                    onChange={handlePortOfLoadingOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                </div>


                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Name of port</label>
                    <input type="text" placeholder='Name of port' className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={loadingPort}
                        onChange={(e) => setLoadingPort(e.target.value)}
                    />
                </div>

                <div className=" sm:w-1/3 md3:w-1/3"></div>
            </div>

            <div className="flex flex-row gap-5 justify-between items-center  xs:flex-row  xs:justify-normal xs:gap-5 w-full sm:w-full  sm:justify-between md3:w-full md3:gap-5 ">
                <div className="w-1/2 sm:w-1/3  md3:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Port of discharge </label>
                    <Dropdown className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full ' options={Country}
                        onChange={handlePortOfDischarge}
                    />
                    {
                        portOfDischargeCountry === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={portOfDischargeOther}
                                    onChange={handlePortOfDischargeOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                </div>


                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Name of port</label>
                    <input type="text" placeholder='Name of port' className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={dischargePort}
                        onChange={(e) => setDischargePort(e.target.value)}
                    />
                </div>

                <div className=" sm:w-1/3 md3:w-1/3"></div>
            </div>


            <div className=" flex flex-row   gap-5 xl:items-center  xs:flex-row xs:justify-between xs:gap-5 sm:flex-row sm:w-full sm:gap-5  md3:w-full md3:gap-5 ">
                <div className="w-1/2 sm:w-1/3 md3:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Container Type </label>
                    <Dropdown className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full ' options={container}
                        onChange={handleContainerType}
                    />
                </div>


                {
                    formType !== "tank" && (
                        <div className=" w-1/2 sm:w-1/3 flex flex-col md3:w-1/3">
                            <label htmlFor="" className='text-[13px] leading-[35px]' >Container Quantity  </label>
                            <input type="number" onWheel={numberInputOnWheelPreventChange} className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] w-full '
                                value={containerQuantity}
                                onChange={(e) => setContainerQuantity(e.target.value)}
                            />
                        </div>
                    )
                }


                {/* Tank  */}
                {formType === "tank" ? (
                    // <div className=" sm:w-1/3 md3:w-1/3">
                    //     <div className="flex flex-col items-center justify-center cursor-pointer">
                    //         <div onClick={handleImageClick} className="p-5 w-full border-[#49B5FD] border-dashed border-[1px] flex flex-col items-center justify-center gap-2  ">
                    //             <img src={uploadICon2} alt="" className='w-[50px]' />
                    //             <h1>MSDS</h1>
                    //             <h1 className='text-[10px]' >Drag and Drop file here <span className='underline'>choose file</span></h1>
                    //         </div>
                    //         <input type="file" name='file' ref={inputRef}
                    //             // onChange={(e) => setMsdsDoc(e.target.files[0])}
                    //             className='cursor-pointer' style={{ display: 'none' }} />
                    //         {/* {msdsDoc && (
                    //             <div className="mt-2">
                    //                 <h2 className='text-[12px]'> {msdsDoc}</h2>
                    //             </div>
                    //         )} */}
                    //     </div>
                    // </div>
                    <>
                        <div className="w-1/3">

                            {
                                renderFileSection('tank_document')
                            }

                        </div>
                        <div className=" sm:w-1/3  md3:w-1/3"></div>
                    </>


                ) : <div className=" sm:w-1/3  md3:w-1/3"></div>}


            </div>

            {formType === "reefer" ? (
                <div className="w-full  sm:gap-5 sm:flex sm:flex-row ">
                    <div className='sm:w-1/3  w-full '>
                        <div className="flex flex-row items-center justify-between  w-full  sm:gap-2 space-x-5   ">
                            <div className="flex flex-col w-1/3 sm:w-full ">
                                <label htmlFor="" className='text-[12px] leading-[35px]'>Temp</label>
                                <input
                                    type="number" onWheel={numberInputOnWheelPreventChange}
                                    placeholder='C/F'
                                    onChange={(e) => setTemperature(e.target.value)}
                                    className='border-[#DDDDDD] text-[13px] w-full sm:w-full  py-2 px-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6]  placeholder:text-end placeholder:text-[10px] placeholder:pr-2 '
                                />
                            </div>

                            <div className="flex flex-col w-1/3 sm:w-full ">
                                <label htmlFor="" className='text-[12px] leading-[35px]'>Ventilation</label>
                                <input
                                    type="number" onWheel={numberInputOnWheelPreventChange}
                                    placeholder='%'
                                    onChange={(e) => setVentilation(e.target.value)}
                                    className='border-[#DDDDDD] text-[13px] w-full sm:w-full py-2 px-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] placeholder:text-end placeholder:text-[10px] placeholder:pr-2 '
                                />
                            </div>

                            <div className="flex flex-col w-1/3 sm:w-full">
                                <label htmlFor="" className='text-[12px] leading-[35px]'>Humidity</label>
                                <input
                                    type="number" onWheel={numberInputOnWheelPreventChange}
                                    placeholder='%'
                                    onChange={(e) => setHumidity(e.target.value)}
                                    className='border-[#DDDDDD] text-[13px] w-full sm:w-full  py-2 px-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] placeholder:text-end  placeholder:text-[10px] placeholder:pr-2 '
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3  "></div>
                    <div className="w-1/3  "></div>
                </div>
            ) : null}

            <div className=" flex flex-col sm:flex-row sm:w-full gap-5 xl:items-center  ">
                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Commodity</label>
                    <input type="text" className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={commodity}
                        onChange={(e) => setCommodity(e.target.value)}
                    />
                </div>

                <div className="flex flex-col sm:w-1/3 ">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Hs Code</label>
                    <input type="number" onWheel={numberInputOnWheelPreventChange}

                        className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                        value={hsCode}
                        onChange={(e) => setHsCode(e.target.value)}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>

                <div className="sm:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Incoterm</label>
                    <Dropdown options={incoterm_option} className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full '
                        onChange={handleIncoterms}
                    />
                    {
                        incoterms === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={incotermOther}
                                    onChange={handleIncotermsOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }
                </div>
            </div>

            <div className="  flex flex-col md3:flex md3:flex-row gap-5 sm:flex-row  sm:w-full ">
                <div className="flex flex-col  sm:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Weight</label>
                    <div className="border-[#DDDDDD] border-[1px] bg-[#F6F6F6] p-1 flex flex-row justify-between items-center w-full  ">
                        <div className="w-full">
                            <input type="number" onWheel={numberInputOnWheelPreventChange}

                                className='py-2 bg-[#F6F6F6] text-[13px]  outline-none w-full'
                                value={cargoWeight}
                                onChange={(e) => setCargoWeight(e.target.value)}
                            />
                        </div>
                        <div className="">
                            <Dropdown options={cargo_weight} value={typeOfCargoWeight} className=' place-self-end py-2 h-full outline-none text-[11px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD]'
                                onChange={handleTypeOfCargoWeight}
                            />
                        </div>

                    </div>
                </div>

                {
                    formType !== "tank" && (
                        <div className="flex flex-col sm:w-1/3 ">
                            <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Total Packages</label>
                            <input type="number" onWheel={numberInputOnWheelPreventChange}

                                className='border-[#DDDDDD] text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                value={totalPackages}
                                onChange={(e) => setTotalPackages(e.target.value)}
                            />
                        </div>
                    )
                }

                <div className="sm:w-1/3">
                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Type </label>
                    <Dropdown options={cargoTypeOption} className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full '
                        onChange={handleTypeOfCargo}
                    />
                    {
                        typeOfCargo === 'Other' && (
                            <div>
                                <input
                                    type="text"
                                    value={cargoTypeOther}
                                    onChange={handleTypeOfCargoOther}
                                    placeholder="Enter other option"
                                    className='border-[#DDDDDD] text-[13px]  outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2 mt-[5px] w-full'
                                />
                            </div>
                        )
                    }

                </div>
            </div>

            <div className=" flex flex-col sm:flex-row sm:w-full sm:gap-5  md3:w-full md3:gap-5 sm:justify-between w-full">
                {/* gauge */}
                {formType === "open-top" || formType === "flat-rack" ? (
                    <div className="w-full  flex flex-row gap-8 ">
                        {/* In-Gauge and Out-Gauge */}
                        <div className=" sm:w-1/3 md3:w-1/3">
                            <div className="flex flex-row items-center gap-1 ">
                                <input
                                    type="checkbox"
                                    checked={inGauge}
                                    onChange={() => handleInGauge()}
                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                />
                                <label htmlFor="" className='text-[13px] leading-[35px]'>In gauge</label>
                            </div>


                            {inGauge && (
                                <div className="flex flex-col sm:w-1/3 md3:w-full">
                                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Dimensions</label>
                                    <div className="flex flex-row gap-2 justify-between p-1  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] ">
                                        <input
                                            type="number" onWheel={numberInputOnWheelPreventChange}
                                            placeholder='Length'
                                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                            value={dimensionLength}
                                            onChange={(e) => setDimensionLength(e.target.value)}
                                        />
                                        <input
                                            type="number" onWheel={numberInputOnWheelPreventChange}
                                            placeholder='Width'
                                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                            value={dimensionWidth}
                                            onChange={(e) => setDimensionWidth(e.target.value)}
                                        />
                                        <input
                                            type="number" onWheel={numberInputOnWheelPreventChange}
                                            placeholder='Height'
                                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                            value={dimensionHeight}
                                            onChange={(e) => setDimensionHeight(e.target.value)}
                                        />

                                        <Dropdown options={weight} className='  outline-none text-[11px] bg-[#F6F6F6]'
                                            onChange={handleTypeOfDimensions}
                                        />
                                    </div>

                                </div>
                            )}

                            <div className="flex flex-row items-center gap-1 ">
                                <input
                                    type="checkbox"
                                    checked={outGauge}
                                    onChange={() => handleOutGauge()}
                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                />
                                <label htmlFor="" className='text-[13px] leading-[35px]'>Out gauge</label>
                            </div>

                            {outGauge && (
                                <div className="flex flex-col sm:w-1/3 md3:w-full">
                                    <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Dimensions</label>
                                    <div className="flex flex-row gap-2 justify-between p-1  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] ">
                                        <input
                                            type="number" onWheel={numberInputOnWheelPreventChange}
                                            placeholder='Length'
                                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                            value={dimensionLength}

                                            onChange={(e) => {
                                                setDimensionLength(e.target.value)
                                                clearError('dimensionLength');
                                            }}
                                        />
                                        <input
                                            type="number" onWheel={numberInputOnWheelPreventChange}
                                            placeholder='Width'
                                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                            value={dimensionWidth}
                                            onChange={(e) => {
                                                setDimensionWidth(e.target.value)
                                                clearError('dimensionWidth');
                                            }}
                                        />
                                        <input
                                            type="number" onWheel={numberInputOnWheelPreventChange}
                                            placeholder='Height'
                                            className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                            value={dimensionHeight}
                                            onChange={(e) => {
                                                setDimensionHeight(e.target.value)
                                                clearError('dimensionHeight');
                                            }}
                                        />

                                        <Dropdown options={weight} className='  outline-none text-[11px] bg-[#F6F6F6]'
                                            onChange={handleTypeOfDimensions}
                                        />
                                    </div>

                                    {errors.dimensionLength && (
                                        <div className="text-red-500 text-xs mt-1">{errors.dimensionLength}</div>
                                    )}
                                    {errors.dimensionHeight && (
                                        <div className="text-red-500 text-xs mt-1">{errors.dimensionHeight}</div>
                                    )}
                                    {errors.dimensionWidth && (
                                        <div className="text-red-500 text-xs mt-1">{errors.dimensionWidth}</div>
                                    )}


                                </div>
                            )}




                        </div>


                        <div className="w-1/3 ">
                            < div className="flex flex-col w-full">
                                <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Ready Date</label>
                                <div className=" w-full  flex flex-row  items-center justify-between py-2  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] px-[10px] ">
                                    <DatePicker
                                        selected={cargoReadyDate}
                                        onChange={(date) => setCargoReadyDate(date)}
                                        ref={datePickerRef}
                                        className='outline-none bg-[#F6F6F6] ' />
                                    <MdDateRange onClick={handleIconClick} className='cursor-pointer' />
                                </div>
                            </div>

                            {/* <div className=" sm:w-1/3 md3:w-1/3"></div> */}
                            {/* <div className=" sm:w-1/3 md3:w-1/3"></div> */}
                        </div>


                        <div className=" sm:w-1/3 md3:w-1/3"></div>
                    </div>
                ) : <div className="w-full">
                    < div className="flex flex-col sm:w-1/3 md3:w-1/3">
                        <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Ready Date</label>
                        <div className=" flex flex-row  items-center justify-between py-2  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] px-[10px] ">
                            <DatePicker
                                selected={cargoReadyDate}
                                onChange={(date) => setCargoReadyDate(date)}
                                ref={datePickerRef}
                                className='outline-none bg-[#F6F6F6] w-[150px] ' />
                            <MdDateRange onClick={handleIconClick} className='cursor-pointer' />
                        </div>
                    </div>

                    <div className=" sm:w-1/3 md3:w-1/3"></div>
                    <div className=" sm:w-1/3 md3:w-1/3"></div>
                </div>}

                {/* date picker */}
                {/* {formType !== "open-top" || formType !== "flat-rack" ? (
                    <div className="w-full">
                        < div className="flex flex-col sm:w-1/3 md3:w-1/3">
                            <label htmlFor="" className='text-[13px] leading-[35px] text-[#525252]' >Cargo Ready Date</label>
                            <div className=" flex flex-row  items-center justify-between py-2  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] px-[10px] ">
                                <DatePicker
                                    selected={cargoReadyDate}
                                    onChange={(date) => setCargoReadyDate(date)}
                                    ref={datePickerRef}
                                    className='outline-none bg-[#F6F6F6] w-[150px] ' />
                                <MdDateRange onClick={handleIconClick} className='cursor-pointer' />
                            </div>
                        </div>

                        <div className=" sm:w-1/3 md3:w-1/3"></div>
                        <div className=" sm:w-1/3 md3:w-1/3"></div>
                    </div>

                ) : null} */}


                {/* {formType === "open-top" && formType === "flat-rack" ? (
                    <div className=" sm:w-1/3 md3:w-1/3"></div>
                ) : null} */}



            </div>


            {/* additional information */}

            <div className="flex flex-col gap-5">

                <div className="">
                    <h1 className='font-bold' >Additional Information</h1>
                    <h1 className='text-[13px]'>fill the Additional information for ocean freight</h1>
                </div>

                {/* stackable , non sackable , hazardous */}

                <div className="flex flex-col gap-3 sm:flex-row sm:w-full md3:flex-row md3:items-start  md3:w-full  ">

                    {
                        formType !== "tank" && (
                            <div className="flex flex-col gap-3 sm:w-1/3  md3:w-1/3">
                                <div className="flex flex-row items-center gap-1 ">
                                    <input
                                        type="checkbox"
                                        checked={isStackable}
                                        onChange={() => handleIsStackable()}
                                        className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                    />
                                    <label htmlFor="" className='text-[13px] leading-[35px]'>Stackable</label>
                                </div>

                                <div className="flex flex-row items-center gap-1 ">
                                    <input
                                        type="checkbox"
                                        checked={isNonStackable}
                                        onChange={() => handleIsNonStackable()}
                                        className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                    />
                                    <label htmlFor="" className='text-[13px] leading-[35px]'>Non - Stackable</label>
                                </div>

                                {isNonStackable && (formType === "dry" || formType === "reefer" || formType === "flat-rack") && (
                                    <div className="">
                                        <div className="flex flex-row gap-2 justify-between p-1  border-[#DDDDDD] border-[1px] bg-[#F6F6F6] ">
                                            <input
                                                type="number" onWheel={numberInputOnWheelPreventChange}
                                                placeholder='L'
                                                className='border-[#DDDDDD] text-[13px] w-1/3  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                value={dimensionLength}
                                                onChange={(e) => setDimensionLength(e.target.value)}
                                            />
                                            <input
                                                type="number" onWheel={numberInputOnWheelPreventChange}
                                                placeholder='W'
                                                className='border-[#DDDDDD] text-[13px] w-1/3 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                value={dimensionWidth}
                                                onChange={(e) => setDimensionWidth(e.target.value)}
                                            />
                                            <input
                                                type="number" onWheel={numberInputOnWheelPreventChange}
                                                placeholder='H'
                                                className='border-[#DDDDDD] text-[13px] w-1/3  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                value={dimensionHeight}
                                                onChange={(e) => setDimensionHeight(e.target.value)}
                                            />

                                            <Dropdown options={weight} className='  outline-none text-[11px] bg-[#F6F6F6]'
                                                onChange={handleTypeOfDimensions}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>
                        )
                    }


                    {
                        !(formType === "open-top" || formType === "flat-rack") && (
                            <div className="flex flex-col gap-3 sm:w-1/3 md3:w-1/3">
                                <div className="flex flex-row items-center gap-1  ">
                                    <input
                                        type="checkbox"
                                        checked={hazardous}
                                        onChange={() => handlehazardous()}
                                        className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                    />
                                    <label htmlFor="" className='text-[13px] leading-[35px]'>Hazardous</label>
                                </div>

                                {hazardous && (
                                    renderFileSection('hazardous_document')
                                )}

                            </div>
                        )
                    }


                    <div className="w-1/3">
                        {fileNames["hazardous_document"].length > 0 && (
                            <div className="mt-2 overflow-auto max-h-[200px] w-full">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border-b-2 border-gray-300 px-4 py-2">Hazardous Document</th>
                                            <th className="border-b-2 border-gray-300 px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileNames["hazardous_document"].map((fileName, index) => (
                                            <tr key={index}>
                                                <td className="border-b px-4 py-2 text-[12px]">{fileName}</td>
                                                <td className="border-b px-4 py-2 text-center">
                                                    <button
                                                        onClick={() => handleFileDelete("hazardous_document", index)}
                                                        className="text-red-600 hover:text-red-800 text-[12px]"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>


                    {/* <div className="md3:w-1/3"></div> */}

                </div>


                {/* Transportation & Custom Clearance  */}
                <div className="gap-5 flex flex-col sm:flex-row sm:w-full md3:flex-row md3:w-full ">
                    {/* transportation */}
                    <div className="flex flex-col gap-3 sm:w-1/3 md3:w-1/3 ">
                        <div className="flex flex-row items-center gap-1 ">
                            <input
                                type="checkbox"
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                checked={transportation}
                                onChange={() => handleTransportation()}
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Transportation</label>
                        </div>

                        {
                            transportation && (
                                <div className="pl-8">
                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            checked={transportationOrigin}
                                            onChange={() => handleTransportationOrigin()}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Origin </label>
                                    </div>

                                    {transportation && transportationOrigin && (
                                        <div className="">
                                            <div className="flex flex-col gap-3">
                                                <div className="w-full flex flex-row  gap-2">
                                                    <input
                                                        type="text"
                                                        placeholder='Pick Up Add.'
                                                        className='border-[#DDDDDD] text-[13px]  w-1/2  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={originAddOfOrigin}
                                                        onChange={(e) => setOriginAddOfOrigin(e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder='Drop Add.'
                                                        className='border-[#DDDDDD] text-[13px] w-1/2 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={originAddOfDestination}
                                                        onChange={(e) => setOriginAddOfDestination(e.target.value)}
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            checked={transportationDestination}
                                            onChange={() => handleTransportationDestination()}
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Destination</label>
                                    </div>

                                    {transportation && transportationDestination && (
                                        <div className="">
                                            <div className="flex flex-col gap-3">
                                                <div className="w-full flex flex-row  gap-2">
                                                    <input
                                                        type="text"
                                                        placeholder='Pick Up Add.'
                                                        className='border-[#DDDDDD] text-[13px]  w-1/2  py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={originAddress}
                                                        onChange={(e) => setOriginAddress(e.target.value)}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder='Drop Add.'
                                                        className='border-[#DDDDDD] text-[13px] w-1/2 py-2 rounded-[2px] outline-none border-[1px] bg-[#F6F6F6] px-[10px]'
                                                        value={destinationAddress}
                                                        onChange={(e) => setDestinationAddress(e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    )}


                                </div>
                            )
                        }
                    </div>


                    {/* custom clearance */}
                    <div className="flex flex-col gap-3 sm:w-1/3  md3:w-1/3 ">
                        <div className="flex flex-row items-center gap-1 ">
                            <input
                                type="checkbox"
                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                checked={customClearance}
                                onChange={() => handleCustomClearance()}
                            />
                            <label htmlFor="" className='text-[13px] leading-[35px]'>Custom Clearance</label>
                        </div>

                        {
                            customClearance && (
                                <div className="pl-8">
                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            checked={customClearanceOrigin}
                                            onChange={() => handleCustomClearanceOrigin()}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Origin </label>
                                    </div>

                                    {customClearance && customClearanceOrigin && (
                                        renderFileSection('customs_clearance_origin_document')
                                    )}

                                    <div className="flex flex-row items-center gap-1 ">
                                        <input
                                            type="checkbox"
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            checked={customClearanceDestination}
                                            onChange={() => handleCustomClearanceDestination()}
                                        />
                                        <label htmlFor="" className='text-[13px] leading-[35px]'>Destination</label>
                                    </div>

                                    {customClearance && customClearanceDestination && (
                                        renderFileSection('customs_clearance_destination_document')
                                    )}
                                </div>
                            )
                        }


                    </div>

                    <div className="w-1/3 flex flex-col gap-10 justify-center ">
                        {fileNames["customs_clearance_origin_document"].length > 0 && (
                            <div className="mt-2 overflow-auto max-h-[200px] w-full">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border-b-2 border-gray-300 px-4 py-2">Origin Document</th>
                                            <th className="border-b-2 border-gray-300 px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileNames["customs_clearance_origin_document"].map((fileName, index) => (
                                            <tr key={index}>
                                                <td className="border-b px-4 py-2 text-[12px]">{fileName}</td>
                                                <td className="border-b px-4 py-2 text-center">
                                                    <button
                                                        onClick={() => handleFileDelete("customs_clearance_origin_document", index)}
                                                        className="text-red-600 hover:text-red-800 text-[12px]"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {fileNames["customs_clearance_destination_document"].length > 0 && (
                            <div className="mt-2 overflow-auto max-h-[200px] w-full">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border-b-2 border-gray-300 px-4 py-2">Destination Document</th>
                                            <th className="border-b-2 border-gray-300 px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fileNames["customs_clearance_destination_document"].map((fileName, index) => (
                                            <tr key={index}>
                                                <td className="border-b px-4 py-2 text-[12px]">{fileName}</td>
                                                <td className="border-b px-4 py-2 text-center">
                                                    <button
                                                        onClick={() => handleFileDelete("customs_clearance_destination_document", index)}
                                                        className="text-red-600 hover:text-red-800 text-[12px]"
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <div className="md3:w-full ">
                {/* Additional notes */}
                <div className="md3:w-full  py-10">
                    <div className="flex flex-col sm:gap-8 md3:w-full md3:gap-8">
                        <div className="flex flex-col  ">
                            <label htmlFor="additional-notes" className='text-[13px] leading-[35px] text-[#525252] '>Additional notes</label>
                            <textarea
                                id="additional-notes"
                                type="text" rows="3"
                                className='h-[100px] border-[#DDDDDD] resize-none text-[13px] py-2 outline-none border-[1px] bg-[#F6F6F6] px-[10px] sm:h-[100px] sm:w-2/3 md3:h-[100px] md3:w-2/3'
                                value={addtionalNotes}
                                onChange={(e) => setAddtionalNotes(e.target.value)}
                            />
                        </div>

                    </div>

                </div>
                <div className="w-full flex flex-row  justify-end     ">

                    <div className=" w-full sm:w-1/3 sm:px-8 ">
                        <Button
                            bgColor="h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary md3:h-[50px] md3:rounded-[5px] w-full m-auto "
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>


            {/* modal code */}
            {
                isModalOpen && (
                    <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center' >
                        <div className="bg-white rounded-[10px] p-5 flex flex-col gap-5 items-center justify-center">
                            <FaRegCheckCircle className='text-[120px] text-[#20BE79] ' />
                            <div className="text-center">
                                <h1 className='text-[#20BE79] text-[30px] font-semibold'>Quotation request created</h1>
                                <h1 className='text-[#5E5E5E]' >Your quotation request has been send succesfully</h1>
                            </div>

                            <div className="text-center">
                                <h1 className='text-[#5E5E5E]' >Your Quote number</h1>
                                <h1 className='font-bold' >{quoteNo}</h1>
                            </div>
                            <div className="">
                                <Button bgColor="w-[130px] h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]"
                                    onClick={() => {
                                        setIsModalOpen(false)
                                        window.location = "/dashboard/request-quote"
                                    }}
                                >
                                    Done</Button>
                            </div>

                        </div>
                    </div>
                )
            }

            {loading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </div>
            }
        </div >
    )
}

export default CreateQuote