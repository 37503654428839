import React, { useRef, useState } from 'react'
import Button from '../components/Button'
import axios from 'axios'
import { GoArrowRight } from "react-icons/go";
import { BsPlus } from "react-icons/bs";
const ComContact = () => {
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [location, setLocation] = useState();
    const [links, setLinks] = useState([]);
    const [divs, setDivs] = useState([]);
    const [url, setUrl] = useState('');
    const [text, setText] = useState('');

    const handleUrlChange = (e) => setUrl(e.target.value);
    const handleTextChange = (e) => setText(e.target.value);

    const handleCompanyContact = () => {
        alert("hello")
    }
    const handleLink = () => {
        if (url && text) {
            const newLink = {
                id: links.length,
                url: url,
                text: text
            };
            setLinks([...links, newLink]);
            setUrl('');
            setText('');
        }
    }
    
    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (
        <div className=' px- xs:px-12 sm:px-28 sm2:px-32  md:px-36  md2:px-48 lg:flex lg:flex-row  lg:justify-center lg:px-0  xs:pb-10 '>

            <div className="rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] flex flex-col gap-5 py-[30px]  lg:w-[450px] ">
                <div>
                    <div>
                        <div className="flex flex-col gap-5">

                            <div className="flex flex-col gap-5">
                                <div className="bg-[#49b5fd45]  w-full py-1 rounded-full"></div>
                                <div className="">
                                    <h1 className='font-bold'>Company Contact</h1>
                                    <p className='text-[13px] leading-[18px]' >Enter your basic information here for
                                        <br />
                                        your profile</p>
                                </div>
                            </div>


                            <div className="flex flex-col gap-[10px]">

                                <div className="flex flex-col ">
                                    <label htmlFor="" className='text-[13px] leading-[35px]' >Company Email</label>
                                    <input type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='email@gmail.com' className='border-[#DDDDDD]  text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px]' />
                                </div>

                                <div className="flex flex-col ">
                                    <label htmlFor="" className='text-[13px] leading-[35px]' >Company Phone no.</label>
                                    <input type="Number" onWheel={numberInputOnWheelPreventChange}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        placeholder='Company phone no. ' className='border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px]' />
                                </div>

                                <div className="flex flex-col ">
                                    <label htmlFor="" className='text-[13px] leading-[35px]' >Branch Location</label>
                                    <input type="text"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        placeholder='Entre ethe location' className='border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px]' />
                                </div>

                                <div className="flex flex-col ">
                                    <label htmlFor="" className='text-[13px] leading-[35px]' >Social Media Links</label>
                                    <div className="flex flex-col gap-3">
                                        <input type="text"
                                            value={links}
                                            onChange={(e) => setLinks(e.target.value)}
                                            placeholder='www.github/jayrajput09' className='border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px]' />
                                        <input
                                            type="text"
                                            value={url}
                                            onChange={handleUrlChange}
                                            placeholder="Enter URL"
                                        />
                                        <input
                                            type="text"
                                            value={text}
                                            onChange={handleTextChange}
                                            placeholder="Enter Link Text"
                                        />
                                        <ul>
                                            {links.map(link => (
                                                <li key={link.id}>
                                                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                        {link.text}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <Button onClick={handleLink} bgColor=" mt-4 w-[190px] h-[45px] rounded-[5px] text-[12px] text-white font-medium bg-[#49B5FD] flex flex-row  items-center justify-center " > <span><BsPlus size={20} fontWeight={1000} /></span>Add More links </Button>


                            </div>

                            <Button onClick={handleCompanyContact} bgColor=" mt-4 w-full h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Continue <span><GoArrowRight size={20} /></span> </Button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComContact