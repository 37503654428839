import React, { useRef, useState, useEffect } from 'react'
import Button from '../components/Button'
import axios from 'axios'
import { GoArrowRight } from "react-icons/go";
import logo from '../assets/LogoFinal.png'
import { useNavigate } from 'react-router-dom';
import ComContact from '../components/ComContact'
import { BsPlus } from "react-icons/bs";
import Dropdown from './Dropdown';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { Circles } from 'react-loader-spinner';
const animatedComponents = makeAnimated();



const ComProf = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn")

    const [image, setImage] = useState(null)
    const [companyName, setCompanyName] = useState();
    const [about, setAbout] = useState();
    const [services, setServices] = useState([]);
    const [servicesSector, setServicesSector] = useState([]);
    const [errors, setErrors] = useState({});
    const [companyContactForm, setCompanyContactForm] = useState(false);
    const [phone, setPhone] = useState();
    const [location, setLocation] = useState();
    const [links, setLinks] = useState();

    const [servicesSectorArray, setServicesSectorArray] = useState([]);
    const [servicesArray, setServicesArray] = useState([]);
    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherService, setOtherService] = useState('');
    const [showOtherServiceSectorInput, setShowOtherServiceSectorInput] = useState(false);
    const [otherServiceSector, setOtherServiceSector] = useState('');
    const [country, setCountry] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    // const[loading , setLoading] = useState

    const inputRef = useRef(null)
    const navigate = useNavigate();

    const handleSelectChange = (selectedOptions) => {
        setServicesSector(selectedOptions);

    };

    const handleCompanyContact = () => {
        alert("hello")
    }


    const handleImage = (e) => {
        setImage(e.target.files[0])
    }

    const handleImageClick = () => {
        inputRef.current.click()
    }

    const changeForm = (e) => {
        e.preventDefault()

        const formErrors = validate()
        if (Object.keys(formErrors).length === 0) {
            setCompanyContactForm(true)
        } else {
            setErrors(formErrors);
        }
    }


    const validate = () => {
        let formErrors = {};
        if (!services.length) formErrors.services = 'Service is required';
        if (!servicesSector.length) formErrors.servicesSector = 'Service Sector is required';
        if (!about) formErrors.about = 'About is required';

        // if (about) {
        //     const wordCount = about.split(/\s+/).filter(Boolean).length;
        //     if (wordCount > 50) formErrors.about = 'You can only input up to 50 words.';
        // }

        if (about) {
            const charCount = about.length;
            if (charCount > 500) {
                formErrors.about = 'You can only input up to 500 characters.';
            }
        }

        if (companyContactForm) {
            const phoneRegex = /^\d{10}$/; // Regular expression to check for exactly 10 digits

            if (!phone) {
                formErrors.phone = 'Phone No. is required';
            } else if (!phoneRegex.test(phone)) {
                formErrors.phone = 'Invalid Phone No';
            }
            if (!location) formErrors.location = 'Branch location is required';
            // if (!links) formErrors.links = 'Social media link is required';
        }
        return formErrors;
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            setLoading(true);
            setErrors({})

            const filteredServices = servicesArray.filter(service => service !== "Other");
            const filteredServiceSector = servicesSectorArray.filter(service => service !== "Other");

            const formData = new FormData();
            // formData.append('profile_photo', image);
           
            formData.append('about', about);
            formData.append('services', showOtherInput ? [...filteredServices, otherService] : filteredServices);
            formData.append('service_sector', showOtherServiceSectorInput ? [...filteredServiceSector, otherServiceSector] : filteredServiceSector);
            formData.append('phone', phone);
            formData.append('address', location);
            formData.append('social_media_links', links);
            formData.append('country', country);
            formData.append('country_code', countryCode);

            axios.put('https://api.freightopedia.world/api/v1/accounts/profile', formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`
                }
            }).then((res) => {
                localStorage.setItem("userData", JSON.stringify(res.data.data))
                setLoading(false);
                alert(res.data.message)

                navigate("/document")
            }).catch((error) => {
                    console.error(error);
                    alert("Something went wrong");
                });
        } else {
            setErrors(formErrors);
        }
    };


    const Services = [
        // { label: "select", value: "select" },
        { label: "Air Freight", value: "Air Freight" },
        { label: "Sea Freight", value: "Sea Freight" },
        { label: "Custom Clearance", value: "Custom Clearance" },
        { label: "Transportation", value: "Transportation" },
        { label: "Other", value: "Other" },
    ]

    const ServiceSector = [
        // { label: "select", value: "select" },
        { label: "SeaPort", value: "Sea Port" },
        { label: "AirPort", value: "AirPort" },
        { label: "State", value: "State" },
        { label: "Other", value: "Other" },
    ]

    const Country = [
        { label: "Afghanistan", value: "Afghanistan" },
        { label: "Albania", value: "Albania" },
        { label: "Algeria", value: "Algeria" },
        { label: "Andorra", value: "Andorra" },
        { label: "Angola", value: "Angola" },
        { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
        { label: "Argentina", value: "Argentina" },
        { label: "Armenia", value: "Armenia" },
        { label: "Australia", value: "Australia" },
        { label: "Austria", value: "Austria" },
        { label: "Azerbaijan", value: "Azerbaijan" },
        { label: "Bahamas", value: "Bahamas" },
        { label: "Bahrain", value: "Bahrain" },
        { label: "Bangladesh", value: "Bangladesh" },
        { label: "Barbados", value: "Barbados" },
        { label: "Belarus", value: "Belarus" },
        { label: "Belgium", value: "Belgium" },
        { label: "Belize", value: "Belize" },
        { label: "Benin", value: "Benin" },
        { label: "Bhutan", value: "Bhutan" },
        { label: "Bolivia", value: "Bolivia" },
        { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
        { label: "Botswana", value: "Botswana" },
        { label: "Brazil", value: "Brazil" },
        { label: "Brunei", value: "Brunei" },
        { label: "Bulgaria", value: "Bulgaria" },
        { label: "Burkina Faso", value: "Burkina Faso" },
        { label: "Burundi", value: "Burundi" },
        { label: "Cabo Verde", value: "Cabo Verde" },
        { label: "Cambodia", value: "Cambodia" },
        { label: "Cameroon", value: "Cameroon" },
        { label: "Canada", value: "Canada" },
        { label: "Central African Republic", value: "Central African Republic" },
        { label: "Chad", value: "Chad" },
        { label: "Chile", value: "Chile" },
        { label: "China", value: "China" },
        { label: "Colombia", value: "Colombia" },
        { label: "Comoros", value: "Comoros" },
        { label: "Congo, Democratic Republic of the", value: "Congo, Democratic Republic of the" },
        { label: "Congo, Republic of the", value: "Congo, Republic of the" },
        { label: "Costa Rica", value: "Costa Rica" },
        { label: "Croatia", value: "Croatia" },
        { label: "Cuba", value: "Cuba" },
        { label: "Cyprus", value: "Cyprus" },
        { label: "Czech Republic", value: "Czech Republic" },
        { label: "Denmark", value: "Denmark" },
        { label: "Djibouti", value: "Djibouti" },
        { label: "Dominica", value: "Dominica" },
        { label: "Dominican Republic", value: "Dominican Republic" },
        { label: "Ecuador", value: "Ecuador" },
        { label: "Egypt", value: "Egypt" },
        { label: "El Salvador", value: "El Salvador" },
        { label: "Equatorial Guinea", value: "Equatorial Guinea" },
        { label: "Eritrea", value: "Eritrea" },
        { label: "Estonia", value: "Estonia" },
        { label: "Eswatini", value: "Eswatini" },
        { label: "Ethiopia", value: "Ethiopia" },
        { label: "Fiji", value: "Fiji" },
        { label: "Finland", value: "Finland" },
        { label: "France", value: "France" },
        { label: "Gabon", value: "Gabon" },
        { label: "Gambia", value: "Gambia" },
        { label: "Georgia", value: "Georgia" },
        { label: "Germany", value: "Germany" },
        { label: "Ghana", value: "Ghana" },
        { label: "Greece", value: "Greece" },
        { label: "Grenada", value: "Grenada" },
        { label: "Guatemala", value: "Guatemala" },
        { label: "Guinea", value: "Guinea" },
        { label: "Guinea-Bissau", value: "Guinea-Bissau" },
        { label: "Guyana", value: "Guyana" },
        { label: "Haiti", value: "Haiti" },
        { label: "Honduras", value: "Honduras" },
        { label: "Hungary", value: "Hungary" },
        { label: "Iceland", value: "Iceland" },
        { label: "India", value: "India" },
        { label: "Indonesia", value: "Indonesia" },
        { label: "Iran", value: "Iran" },
        { label: "Iraq", value: "Iraq" },
        { label: "Ireland", value: "Ireland" },
        { label: "Israel", value: "Israel" },
        { label: "Italy", value: "Italy" },
        { label: "Jamaica", value: "Jamaica" },
        { label: "Japan", value: "Japan" },
        { label: "Jordan", value: "Jordan" },
        { label: "Kazakhstan", value: "Kazakhstan" },
        { label: "Kenya", value: "Kenya" },
        { label: "Kiribati", value: "Kiribati" },
        { label: "Korea, North", value: "Korea, North" },
        { label: "Korea, South", value: "Korea, South" },
        { label: "Kosovo", value: "Kosovo" },
        { label: "Kuwait", value: "Kuwait" },
        { label: "Kyrgyzstan", value: "Kyrgyzstan" },
        { label: "Laos", value: "Laos" },
        { label: "Latvia", value: "Latvia" },
        { label: "Lebanon", value: "Lebanon" },
        { label: "Lesotho", value: "Lesotho" },
        { label: "Liberia", value: "Liberia" },
        { label: "Libya", value: "Libya" },
        { label: "Liechtenstein", value: "Liechtenstein" },
        { label: "Lithuania", value: "Lithuania" },
        { label: "Luxembourg", value: "Luxembourg" },
        { label: "Madagascar", value: "Madagascar" },
        { label: "Malawi", value: "Malawi" },
        { label: "Malaysia", value: "Malaysia" },
        { label: "Maldives", value: "Maldives" },
        { label: "Mali", value: "Mali" },
        { label: "Malta", value: "Malta" },
        { label: "Marshall Islands", value: "Marshall Islands" },
        { label: "Mauritania", value: "Mauritania" },
        { label: "Mauritius", value: "Mauritius" },
        { label: "Mexico", value: "Mexico" },
        { label: "Micronesia", value: "Micronesia" },
        { label: "Moldova", value: "Moldova" },
        { label: "Monaco", value: "Monaco" },
        { label: "Mongolia", value: "Mongolia" },
        { label: "Montenegro", value: "Montenegro" },
        { label: "Morocco", value: "Morocco" },
        { label: "Mozambique", value: "Mozambique" },
        { label: "Myanmar", value: "Myanmar" },
        { label: "Namibia", value: "Namibia" },
        { label: "Nauru", value: "Nauru" },
        { label: "Nepal", value: "Nepal" },
        { label: "Netherlands", value: "Netherlands" },
        { label: "New Zealand", value: "New Zealand" },
        { label: "Nicaragua", value: "Nicaragua" },
        { label: "Niger", value: "Niger" },
        { label: "Nigeria", value: "Nigeria" },
        { label: "North Macedonia", value: "North Macedonia" },
        { label: "Norway", value: "Norway" },
        { label: "Oman", value: "Oman" },
        { label: "Pakistan", value: "Pakistan" },
        { label: "Palau", value: "Palau" },
        { label: "Palestine", value: "Palestine" },
        { label: "Panama", value: "Panama" },
        { label: "Papua New Guinea", value: "Papua New Guinea" },
        { label: "Paraguay", value: "Paraguay" },
        { label: "Peru", value: "Peru" },
        { label: "Philippines", value: "Philippines" },
        { label: "Poland", value: "Poland" },
        { label: "Portugal", value: "Portugal" },
        { label: "Qatar", value: "Qatar" },
        { label: "Romania", value: "Romania" },
        { label: "Russia", value: "Russia" },
        { label: "Rwanda", value: "Rwanda" },
        { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
        { label: "Saint Lucia", value: "Saint Lucia" },
        { label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
        { label: "Samoa", value: "Samoa" },
        { label: "San Marino", value: "San Marino" },
        { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
        { label: "Saudi Arabia", value: "Saudi Arabia" },
        { label: "Senegal", value: "Senegal" },
        { label: "Serbia", value: "Serbia" },
        { label: "Seychelles", value: "Seychelles" },
        { label: "Sierra Leone", value: "Sierra Leone" },
        { label: "Singapore", value: "Singapore" },
        { label: "Slovakia", value: "Slovakia" },
        { label: "Slovenia", value: "Slovenia" },
        { label: "Solomon Islands", value: "Solomon Islands" },
        { label: "Somalia", value: "Somalia" },
        { label: "South Africa", value: "South Africa" },
        { label: "South Sudan", value: "South Sudan" },
        { label: "Spain", value: "Spain" },
        { label: "Sri Lanka", value: "Sri Lanka" },
        { label: "Sudan", value: "Sudan" },
        { label: "Suriname", value: "Suriname" },
        { label: "Sweden", value: "Sweden" },
        { label: "Switzerland", value: "Switzerland" },
        { label: "Syria", value: "Syria" },
        { label: "Taiwan", value: "Taiwan" },
        { label: "Tajikistan", value: "Tajikistan" },
        { label: "Tanzania", value: "Tanzania" },
        { label: "Thailand", value: "Thailand" },
        { label: "Timor-Leste", value: "Timor-Leste" },
        { label: "Togo", value: "Togo" },
        { label: "Tonga", value: "Tonga" },
        { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
        { label: "Tunisia", value: "Tunisia" },
        { label: "Turkey", value: "Turkey" },
        { label: "Turkmenistan", value: "Turkmenistan" },
        { label: "Tuvalu", value: "Tuvalu" },
        { label: "Uganda", value: "Uganda" },
        { label: "Ukraine", value: "Ukraine" },
        { label: "United Arab Emirates", value: "United Arab Emirates" },
        { label: "United Kingdom", value: "United Kingdom" },
        { label: "United States", value: "United States" },
        { label: "Uruguay", value: "Uruguay" },
        { label: "Uzbekistan", value: "Uzbekistan" },
        { label: "Vanuatu", value: "Vanuatu" },
        { label: "Vatican City", value: "Vatican City" },
        { label: "Venezuela", value: "Venezuela" },
        { label: "Vietnam", value: "Vietnam" },
        { label: "Yemen", value: "Yemen" },
        { label: "Zambia", value: "Zambia" },
        { label: "Zimbabwe", value: "Zimbabwe" }
    ];


    const handleServiceSector = (option) => {

        const selectedServiceSectors = option.map(opt => opt.label);
        setServicesSectorArray(selectedServiceSectors);
        setServicesSector(option);

        // Show or hide the "Other" input field
        if (selectedServiceSectors.includes("Other")) {
            setShowOtherServiceSectorInput(true);
        } else {
            setShowOtherServiceSectorInput(false);
            setOtherServiceSector('');
        }
    }

    const handleOtherServiceSectorChange = (e) => {
        setOtherServiceSector(e.target.value);
    }



    const handleService = (option) => {

        const selectedServices = option.map(opt => opt.label);
        setServicesArray(selectedServices);
        setServices(option);

        // Show or hide the "Other" input field
        if (selectedServices.includes("Other")) {
            setShowOtherInput(true);
        } else {
            setShowOtherInput(false);
            setOtherService('');
        }
    }

    const handleOtherServiceChange = (e) => {
        setOtherService(e.target.value);
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const handlePortOfLoading = (option) => {
        setCountry(option.label)
    };

    return (
        <>
            {
                !isLoggedIn ? window.location = "/login" : ""
            }
            {
                companyContactForm ?
                    // contact form
                    <div className=' px- xs:px-12 sm:px-28 sm2:px-32  md:px-36  md2:px-48 lg:flex lg:flex-row  lg:justify-center lg:px-0  xs:pb-10 '>

                        <div className="rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] flex flex-col gap-5 py-[30px]  lg:w-[450px] ">
                            <div>
                                <div>
                                    <div className="flex flex-col gap-5">

                                        <div className="flex flex-col gap-5">
                                            {/* <div className="bg-[#49b5fd45]  w-full py-1 rounded-full"></div> */}
                                            <div className="">
                                                <h1 className='font-bold'>Company Contact</h1>
                                                <p className='text-[13px] leading-[18px]' >Enter your basic information here for
                                                    <br />
                                                    your profile</p>
                                            </div>
                                        </div>


                                        <div className="flex flex-col gap-[10px]">

                                            <div className="flex flex-col ">
                                                <label htmlFor="" className='text-[13px] leading-[35px]' >Company Phone no.</label>

                                                <div className="border-[#DDDDDD] text-[13px]  border-[1px] bg-[#F6F6F6] w-full">
                                                    <input type="text"
                                                        placeholder='+91'
                                                        value={countryCode}
                                                        onChange={(e) => setCountryCode(e.target.value)}
                                                        className='border-[#DDDDDD] text-[13px] py-1 px-2 border-[1px] bg-[#F6F6F6] w-[80px] ml-3 outline-none ' />

                                                    <input type="Number" onWheel={numberInputOnWheelPreventChange}
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        placeholder='Company phone no. ' className=' text-[13px] py-3 outline-none  bg-[#F6F6F6] px-[10px]' />
                                                </div>

                                                {errors.phone && <div className="text-red-500 text-xs">{errors.phone}</div>}
                                            </div>

                                            <div className="w-full">
                                                <label htmlFor="" className='text-[13px] leading-[35px] ' >Country</label>
                                                <Dropdown className='outline-none text-[13px] bg-[#F6F6F6] border-[1px] border-[#DDDDDD] py-2 px-2 w-full  ' options={Country}
                                                    onChange={handlePortOfLoading}
                                                />
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor="" className='text-[13px] leading-[35px]' >Branch Location</label>
                                                <input type="text"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    placeholder='Entre ethe location' className='border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px]' />
                                                {errors.location && <div className="text-red-500 text-xs">{errors.location}</div>}
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor="" className='text-[13px] leading-[35px]' >Social Media Links</label>
                                                <div className="flex flex-col gap-3">
                                                    <input type="text"
                                                        value={links}
                                                        onChange={(e) => setLinks(e.target.value)}
                                                        placeholder='www.github/jayrajput09' className='border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px]' />
                                                    {/* {errors.links && <div className="text-red-500 text-xs">{errors.links}</div>} */}
                                                </div>
                                            </div>

                                            {/* <Button onClick={handleLink} bgColor=" mt-4 w-[190px] h-[45px] rounded-[5px] text-[12px] text-white font-medium bg-[#49B5FD] flex flex-row  items-center justify-center " > <span><BsPlus size={20} fontWeight={1000} /></span>Add More links </Button> */}


                                        </div>

                                        <Button onClick={handleSubmit} bgColor=" mt-4 w-full h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Continue <span><GoArrowRight size={20} /></span> </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    // profile form
                    <div className=' px- xs:px-12 sm:px-28 sm2:px-32  md:px-36  md2:px-48 lg:flex lg:flex-row  lg:justify-center lg:px-0  xs:pb-10 '>
                        <div className="rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] flex flex-col gap-5 py-[30px]  lg:w-[450px] ">
                            <div>
                                <div>
                                    <div className="flex flex-col gap-5">
                                        <div className="flex flex-col gap-5">
                                            {/* <div className="bg-[#49b5fd45]  w-full py-1 rounded-full"></div> */}
                                            <div className="">
                                                <h1 className='font-bold'>Basic Information</h1>
                                                <p className='text-[13px]' >Enter your basic information here for <br />
                                                    your profile</p>
                                            </div>
                                        </div>
                                        {/* <div
                                            onClick={handleImageClick}
                                            className="flex flex-col items-center justify-center cursor-pointer">

                                            {image ? <img src={URL.createObjectURL(image)} alt="" className='rounded-full w-[100px] h-[100px] border-[1px] border-[#246BFD]' /> : <div className=" bg-img relative w-[100px] h-[100px] border-[1px] border-[#246BFD] rounded-full overflow-hidden flex items-center justify-center text-white text-[10px] font-bold">
                                                <div className="absolute inset-0 bg-custom-background bg-cover bg-no-repeat"></div>
                                                <div className="absolute inset-0 bg-gray-500 bg-opacity-45"></div>
                                                <h1 className="relative z-10 text-center">Upload your <br /> logo</h1>
                                            </div>}
                                            <input type="file" name='file' accept="image/*" ref={inputRef} onChange={handleImage} className='cursor-pointer' style={{ display: 'none' }} />
                                        </div> */}
                                        <form>
                                            <div className="flex flex-col gap-[10px]">

                                                <div className="flex flex-col">
                                                    <label htmlFor="" className='text-[13px] leading-[35px]' >Services </label>
                                                    <Select
                                                        options={Services}
                                                        components={animatedComponents}
                                                        value={services}
                                                        isMulti={true}
                                                        onChange={handleService}
                                                    />
                                                    {showOtherInput && (
                                                        <input
                                                            type="text"
                                                            placeholder="Please specify other service"
                                                            value={otherService}
                                                            onChange={handleOtherServiceChange}
                                                            className="border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px] mt-2 w-full"
                                                        />


                                                    )}
                                                    {errors.services && <div className="text-red-500 text-xs">{errors.services}</div>}
                                                </div>

                                                <div className="flex flex-col">
                                                    <label htmlFor="" className='text-[13px] leading-[35px]' >Services Sector </label>
                                                    <Select
                                                        options={ServiceSector}
                                                        components={animatedComponents}
                                                        value={servicesSector}
                                                        isMulti={true}
                                                        onChange={handleServiceSector}
                                                    />
                                                    {showOtherServiceSectorInput && (
                                                        <input
                                                            type="text"
                                                            value={otherServiceSector}
                                                            onChange={handleOtherServiceSectorChange}
                                                            placeholder="Enter other service sector"
                                                            className="border-[#DDDDDD] text-[13px] py-3 outline-none border-[1px] bg-[#F6F6F6] px-[10px] mt-2 w-full"
                                                        />
                                                    )}
                                                    {errors.servicesSector && <div className="text-red-500 text-xs">{errors.servicesSector}</div>}

                                                </div>

                                                <div className="flex flex-col ">
                                                    <label htmlFor="" className='text-[13px] leading-[35px]' >About</label>
                                                    <textarea type="text"
                                                        id="about"
                                                        name="about"
                                                        value={about}
                                                        onChange={(e) => setAbout(e.target.value)}
                                                        placeholder='About us' className='border-[#DDDDDD] resize-none h-[150px] text-[15px] outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-1' />
                                                    {errors.about && <div className="text-red-500 text-xs">{errors.about}</div>}
                                                </div>
                                            </div>

                                            <Button onClick={changeForm} bgColor=" mt-4 w-full h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Continue <span><GoArrowRight size={20} /></span> </Button>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            }

            {loading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default ComProf