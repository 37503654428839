import React from 'react'
import logo from '../assets/LogoFinal.png'
import Phone from '../components/Phone'
import { Outlet, useNavigate } from 'react-router-dom'
import Login from '../components/Login'


const LoginRoute = () => {
  const navigate = useNavigate();
  return (
    <div className='w-screen h-screen flex flex-col gap-[90px] py-[20px] px-[20px] xs:gap-[90px] sm:gap-[100px]' >
      <div onClick={() => navigate("/")} className="flex w-full flex-row  sm:justify-start cursor-pointer ">
        <div className="flex flex-row items-center justify-center ">
        <img src={logo} alt="logo" className='w-[18px] h-[20px]' />
          <h1 className='text-logoText text-[17px] font-bold '>FreightOpedia</h1>
        </div>
      </div>

      <Login />


    </div>
  )
}

export default LoginRoute