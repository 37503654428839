import React, { useState, useEffect, useRef } from 'react'
import Button from './Button'
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/QuotationIcon.png'

import { IoMdClose } from "react-icons/io";
import { CgArrowLongRight } from "react-icons/cg";
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { LuPrinter } from "react-icons/lu";
import { MdDateRange } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import ModalCreateQuotation from './ModalCreateQuotation';
import QuotationDetails from '../pages/QuotationDetails'
import axios from 'axios';
import DatePicker from "react-datepicker";
import ShowFile from './ShowFile';
import { TfiEmail } from "react-icons/tfi";
import whatsAppIcon from '../assets/whatsApp.png'
import { PiSmileySadBold } from "react-icons/pi";
import warning from '../assets/warning.jpg'

// import QuotationDetails from './QuotationDetails';

// import { LiaLongArrowAltRightSolid } from "react-icons/lia";




const ModalQuotationDetails = ({ quotationRequest, isModalOpen, setIsModalOpen, user, showButton }) => {
    const navigate = useNavigate();

    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

    const [quotationRequestData, setQuotationRequestData] = useState({})
    const [dimensionsData, setDimensionsData] = useState({})

    const [isQuotationCreated, setIsQuotationCreated] = useState(false)

    const [quotationDetails, setQuotationDetails] = useState(false);
    const [quotation, setQuotation] = useState('');
    const [showFile, setShowFile] = useState(false);
    const [fileLink, setFileLink] = useState('');

    const userData = JSON.parse(localStorage.getItem('userData'))

    // const handleViewFile = (link) => {
    //     
    //     setFileLink(link);
    //     setShowFile(true);
    // }
    // const handleCloseFile = () => {
    //     setShowFile(false);
    // }

    const getQuotationRequest = () => {

        
        axios.get(`https://api.freightopedia.world/api/v1/logistics/quotation-request?qr_id=${quotationRequest}&user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                // handle success
                
                


                if (response.data.success) {
                    
                    const data = response.data.data

                    
                    

                    setQuotationRequestData(data)
                    setIsQuotationCreated(data.quotation_available)
                    

                }


            })
            .catch(error => {
                // handle error
                
            });
    }


    const handleSubmit = () => {


        // if (userData.isLoggedIn == true || !userData.is_verified ) {
        //     setIsVerifyModalOpen(true);
        //     return;
        // }else{
        //      window.location = `/login`
        // }

        if (!userData) {
            // Redirect to login if userData is null or user is not logged in
            window.location = '/login';
        } else if (!userData.is_verified) {
            // If user is logged in but not verified
            setIsVerifyModalOpen(true);
            return;
        }


        if (user === "customer") {
            if (isQuotationCreated) {
                window.location = `/dashboard/${quotationRequest}/quotation`
            }
        } else if (user === "transporter") {
            window.location = `/quotation-request/${quotationRequestData.id}/create-quotation`
        }
    }


    useEffect(() => {
        getQuotationRequest()
    }, [])


    return (
        <div className='fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center' >
            {/* {showFile && (
                <ShowFile link={fileLink} handleCloseFile={handleCloseFile} />
            )} */}

            {/* quotation details */}
            {/* {
                quotationDetails && (
                    <ConfirmQuotation quotationRequest={quotationRequest} quotation={quotation} onClose={onClose} />
                )
            } */}

            {
                isVerifyModalOpen && (
                    <div className='absolute z-50 top-0 left-0'>

                        {/* <ModalQuotationDetails quotationRequest={activeQuotationRequest} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} user={"transporter"} showButton={false} /> */}
                        <div className='fixed inset-0 bg-black bg-opacity-75 backdrop-brightness-100  backdrop-blur-sm flex items-center justify-center' >

                            <div className="w-[700px] h-[500px] p-5 " >
                                <div className="bg-white w-full h-full p-5 flex flex-col ">
                                    <div className="flex items-center justify-end">
                                        <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px] cursor-pointer">
                                            <IoMdClose className='text-[20px]  text-[#B3B3B3] ' />
                                        </div>
                                    </div>


                                    <div className="h-full w-full  items-center justify-center flex flex-col">
                                        <img src={warning} alt="" className='w-[150px] h-[100px]' />
                                        <h1 className='text-[30px] font-bold text-center pb-7'>Oops, you are Not verified user! </h1>

                                        <div className="">
                                            <div className="flex flex-col items-center">
                                                <p>Please verify your account with the admin before proceeding.</p>
                                                <p className='text-center '>freightopedia@freightopedia.world</p>
                                            </div>

                                            <div className="flex flex-row gap-1 items-center justify-center  py-1">
                                                <a target="_blank" href="https://wa.me/7303007513">
                                                    <div className="flex flex-row gap-2 items-center justify-center  py-3 rounded-md">
                                                        <img src={whatsAppIcon} alt="" className='w-[25px] h-[25px]' />
                                                        {/* <h1 className='font-semibold  ' >Verify here</h1> */}
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}


            {/* main quotation request */}

            {/* Air */}

            {
                quotationRequestData.freight_medium === "air" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>

                            </div>

                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">

                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Departure</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.departure_country} {'>'} {quotationRequestData.departure}</h1>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Arrival</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'>{quotationRequestData.arrival_country} {'>'} {quotationRequestData.arrival} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Date</h1>
                                                <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                            </div>
                                        </div>
                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>
                                            <div className="">
                                                <h1>Cargo Unit</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight_unit}</h1>
                                            </div>
                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">
                                            <div className="">
                                                <h1>Total Package</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.total_packages}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>CBM</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cbm}</h1>
                                            </div>

                                            <div className="flex flex-row gap-5">
                                                <div className="flex-col flex gap-2">
                                                    <h1>Length</h1>
                                                    <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px] '>{quotationRequestData.dimensions_length}</h1>
                                                </div>

                                                <div className="flex-col flex gap-2">
                                                    <h1>Width</h1>
                                                    <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_width}</h1>
                                                </div>

                                                <div className="flex-col flex gap-2">
                                                    <h1>Height</h1>
                                                    <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_height}</h1>
                                                </div>
                                            </div>

                                            <div className="">
                                                <h1>Dimension Unit</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.dimensions_unit}</h1>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-full">
                                    {/* stackable and non-stackabel */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Non-Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.non_stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                </div>

                                {/* hazardous */}
                                <div className="w-full flex-col flex gap-3 ">
                                    <div className="flex flex-row gap-3">
                                        <h1>Hazardous</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.hazardous}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    {
                                        quotationRequestData.hazardous && (

                                            <div className="grid grid-cols-3 gap-2">
                                                <>
                                                    {
                                                        quotationRequestData.hazardous_document.map((item, index) => {

                                                            let shortenedName = item.name
                                                            if (shortenedName.length >= 18) {
                                                                shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                            }

                                                            
                                                            return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                    <div className="flex flex-row gap-3 items-center ">
                                                                        <IoDocumentTextOutline size={30} />
                                                                        <div className="">
                                                                            <h1>{shortenedName}</h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="">
                                                                        <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                    </div>
                                                                </div>
                                                            </a>


                                                        })
                                                    }

                                                </>
                                            </div>

                                            // <>
                                            //     {
                                            //         quotationRequestData.hazardous_document.map((item, index) => {
                                            //             
                                            //             return <div className="bg-[#F5F5F5] rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                            //                 <div className="flex flex-row gap-3 items-center ">
                                            //                     <IoDocumentTextOutline size={30} />
                                            //                     <div className="">
                                            //                         <h1>{item.name}</h1>
                                            //                         {/* <p className='text-[#929292] text-[11px]'>1.50 MB</p> */}
                                            //                     </div>
                                            //                 </div>

                                            //                 <div className="">
                                            //                     {/* <Button bgColor="text-[12px] text-[#49B5FD]">View</Button> */}
                                            //                     <a target='_blank' href={item.link} className="text-[12px] text-[#49B5FD]">View</a>
                                            //                 </div>
                                            //             </div>

                                            //         })
                                            //     }

                                            // </>
                                        )
                                    }


                                </div>

                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a href={item.link} target='_blank' download={false} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div>
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                                // return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                //     <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                //         <div className="flex flex-row gap-3 items-center ">
                                                                //             <IoDocumentTextOutline size={30} />
                                                                //             <div className="">
                                                                //                 <h1>{shortenedName}</h1>
                                                                //             </div>
                                                                //         </div>

                                                                //         <div className="">
                                                                //             <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                //         </div>
                                                                //     </div>
                                                                // </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>

                            {/* button div */}

                        </div>

                        <div className=" w-full flex justify-end  pr-8   ">
                            {
                                user === "customer" && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        {
                                            isQuotationCreated ? "View Quotations" : "No Quotations"
                                        }
                                    </Button>
                                )
                            }
                            {
                                user === "transporter" && showButton && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="py-2 px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        Create Quotation
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                )
            }



            {
                quotationRequestData.freight_medium === "ocean" && quotationRequestData.freight_type === "LCL" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>
                            </div>
                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">
                                    {/* <div className="">
                                        <img src={logo} alt="" className='w-[100px] h-[100px]' />
                                    </div> */}
                                    {/* middle right col-wise */}
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Loading</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.loading_port_country} {'>'} {quotationRequestData.loading_port} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Discharge</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'> {quotationRequestData.discharge_port_country} {'>'} {quotationRequestData.discharge_port} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-start justify-center">
                                            <h1>Date</h1>
                                            <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                        </div>
                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>
                                            <div className="">
                                                <h1>Cargo Unit</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight_unit}</h1>
                                            </div>
                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">
                                            <div className="">
                                                <h1>Total Package</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.total_packages}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>CBM</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cbm}</h1>
                                            </div>

                                            <div className="flex flex-row gap-5">
                                                <div className="flex-col flex gap-2">
                                                    <h1>Length</h1>
                                                    <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px] '>{quotationRequestData.dimensions_length}</h1>
                                                </div>

                                                <div className="flex-col flex gap-2">
                                                    <h1>Width</h1>
                                                    <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_width}</h1>
                                                </div>

                                                <div className="flex-col flex gap-2">
                                                    <h1>Height</h1>
                                                    <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_height}</h1>
                                                </div>
                                            </div>

                                            <div className="">
                                                <h1>Dimension Unit</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.dimensions_unit}</h1>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-2/3">
                                    {/* stackable and non-stackabel */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Non-Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.non_stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>
                                </div>


                                {/* hazardous */}
                                <div className="w-full flex-col flex gap-3 ">
                                    <div className="flex flex-row gap-3">
                                        <h1>Hazardous</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.hazardous}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    {
                                        quotationRequestData.hazardous && (

                                            <div className="grid grid-cols-3 gap-2">
                                                <>
                                                    {
                                                        quotationRequestData.hazardous_document.map((item, index) => {

                                                            let shortenedName = item.name
                                                            if (shortenedName.length >= 18) {
                                                                shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                            }

                                                            
                                                            return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                    <div className="flex flex-row gap-3 items-center ">
                                                                        <IoDocumentTextOutline size={30} />
                                                                        <div className="">
                                                                            <h1>{shortenedName}</h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="">
                                                                        <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        })
                                                    }

                                                </>
                                            </div>

                                            // <>
                                            //     {
                                            //         quotationRequestData.hazardous_document.map((item, index) => {
                                            //             
                                            //             return <div className="bg-[#F5F5F5] rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                            //                 <div className="flex flex-row gap-3 items-center ">
                                            //                     <IoDocumentTextOutline size={30} />
                                            //                     <div className="">
                                            //                         <h1>{item.name}</h1>
                                            //                         {/* <p className='text-[#929292] text-[11px]'>1.50 MB</p> */}
                                            //                     </div>
                                            //                 </div>

                                            //                 <div className="">
                                            //                     {/* <Button bgColor="text-[12px] text-[#49B5FD]">View</Button> */}
                                            //                     <a target='_blank' href={item.link} className="text-[12px] text-[#49B5FD]">View</a>
                                            //                 </div>
                                            //             </div>

                                            //         })
                                            //     }

                                            // </>
                                        )
                                    }


                                </div>

                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>


                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>

                            {/* button div */}

                        </div>

                        <div className=" w-full flex justify-end  pr-8   ">
                            {
                                user === "customer" && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        {
                                            isQuotationCreated ? "View Quotations" : "No Quotations"
                                        }
                                    </Button>
                                )
                            }
                            {
                                user === "transporter" && showButton && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor=" py:2 md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        Create Quotation
                                    </Button>
                                )
                            }
                        </div>
                    </div>

                )
            }


            {
                quotationRequestData.freight_medium === "ocean" && quotationRequestData.freight_type === "FCL" && quotationRequestData.form_type === "dry" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">

                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>

                            </div>
                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">

                                    {/* middle right col-wise */}
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">


                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Loading</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.loading_port_country} {'>'} {quotationRequestData.loading_port} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Discharge</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'> {quotationRequestData.discharge_port_country} {'>'} {quotationRequestData.discharge_port} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="flex flex-col items-start">
                                            <h1>Date</h1>
                                            <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                        </div>
                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo Unit</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight_unit}</h1>
                                            </div>
                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">
                                            <div className="">
                                                <h1>Total Package</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.total_packages}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier quantity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_quantity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier Type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Dimension Unit</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.dimensions_unit}</h1>
                                            </div>

                                        </div>

                                        {
                                            quotationRequestData.non_stackable && (
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex-col flex gap-2">
                                                        <h1>Length</h1>
                                                        <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px] '>{quotationRequestData.dimensions_length}</h1>
                                                    </div>

                                                    <div className="flex-col flex gap-2">
                                                        <h1>Width</h1>
                                                        <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_width}</h1>
                                                    </div>

                                                    <div className="flex-col flex gap-2">
                                                        <h1>Height</h1>
                                                        <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_height}</h1>
                                                    </div>
                                                </div>
                                            )
                                        }



                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-2/3">
                                    {/* stackable and non-stackabel */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Non-Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.non_stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>


                                </div>


                                {/* hazardous */}
                                <div className="w-full flex-col flex gap-3 ">
                                    <div className="flex flex-row gap-3">
                                        <h1>Hazardous</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.hazardous}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    {
                                        quotationRequestData.hazardous && (

                                            <div className="grid grid-cols-3 gap-2">
                                                <>
                                                    {
                                                        quotationRequestData.hazardous_document.map((item, index) => {

                                                            let shortenedName = item.name
                                                            if (shortenedName.length >= 18) {
                                                                shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                            }

                                                            
                                                            return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                    <div className="flex flex-row gap-3 items-center ">
                                                                        <IoDocumentTextOutline size={30} />
                                                                        <div className="">
                                                                            <h1>{shortenedName}</h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="">
                                                                        <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        })
                                                    }

                                                </>
                                            </div>

                                            // <>
                                            //     {
                                            //         quotationRequestData.hazardous_document.map((item, index) => {
                                            //             
                                            //             return <div className="bg-[#F5F5F5] rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                            //                 <div className="flex flex-row gap-3 items-center ">
                                            //                     <IoDocumentTextOutline size={30} />
                                            //                     <div className="">
                                            //                         <h1>{item.name}</h1>
                                            //                         {/* <p className='text-[#929292] text-[11px]'>1.50 MB</p> */}
                                            //                     </div>
                                            //                 </div>

                                            //                 <div className="">
                                            //                     {/* <Button bgColor="text-[12px] text-[#49B5FD]">View</Button> */}
                                            //                     <a target='_blank' href={item.link} className="text-[12px] text-[#49B5FD]">View</a>
                                            //                 </div>
                                            //             </div>

                                            //         })
                                            //     }

                                            // </>
                                        )
                                    }


                                </div>

                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>


                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>



                        </div>
                        {/* button div */}

                        <div className=" w-full flex justify-end  pr-8   ">
                            {
                                user === "customer" && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        {
                                            isQuotationCreated ? "View Quotations" : "No Quotations"
                                        }
                                    </Button>
                                )
                            }
                            {
                                user === "transporter" && showButton && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor=" py:2 md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        Create Quotation
                                    </Button>
                                )
                            }
                        </div>

                    </div>
                )
            }



            {
                quotationRequestData.freight_medium === "ocean" && quotationRequestData.freight_type === "FCL" && quotationRequestData.form_type === "open-top" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>

                            </div>
                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">

                                    {/* middle right col-wise */}
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">


                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Loading</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.loading_port_country} {'>'} {quotationRequestData.loading_port} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Discharge</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'> {quotationRequestData.discharge_port_country} {'>'} {quotationRequestData.discharge_port} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <h1>Date</h1>
                                                <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                            </div>
                                        </div>
                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>
                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">
                                            <div className="">
                                                <h1>Total Package</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.total_packages}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier Type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier quantity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_quantity}</h1>
                                            </div>
                                        </div>


                                        <div className="flex flex-row gap-5">
                                            <div className="flex-col flex gap-2">
                                                <h1>Length</h1>
                                                <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px] '>{quotationRequestData.dimensions_length}</h1>
                                            </div>

                                            <div className="flex-col flex gap-2">
                                                <h1>Width</h1>
                                                <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_width}</h1>
                                            </div>

                                            <div className="flex-col flex gap-2">
                                                <h1>Height</h1>
                                                <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_height}</h1>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-2/3">
                                    {/* stackable and non-stackabel */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Non-Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.non_stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>


                                    {/* gauge */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>In gauge</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.in_gauge}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Out gauge</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.out_gauge}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>


                                </div>

                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>

                            {/* button div */}

                            <div className=" w-full flex justify-end  pr-8   ">
                                {
                                    user === "customer" && (
                                        <Button
                                            onClick={handleSubmit}
                                            bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                            {
                                                isQuotationCreated ? "View Quotations" : "No Quotations"
                                            }
                                        </Button>
                                    )
                                }
                                {
                                    user === "transporter" && showButton && (
                                        <Button
                                            onClick={handleSubmit}
                                            bgColor="py:2  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                            Create Quotation
                                        </Button>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                )
            }


            {
                quotationRequestData.freight_medium === "ocean" && quotationRequestData.freight_type === "FCL" && quotationRequestData.form_type === "flat-rack" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>

                            </div>
                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">

                                    {/* middle right col-wise */}
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">


                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Loading</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.loading_port_country} {'>'} {quotationRequestData.loading_port} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Discharge</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'> {quotationRequestData.discharge_port_country} {'>'} {quotationRequestData.discharge_port} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <h1>Date</h1>
                                                <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                            </div>
                                        </div>
                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>
                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">
                                            <div className="">
                                                <h1>Total Package</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.total_packages}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier Type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier quantity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_quantity}</h1>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-2/3">
                                    {/* stackable and non-stackabel */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Non-Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.non_stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>


                                    {/* gauge */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>In gauge</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.in_gauge}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Out gauge</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.out_gauge}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>


                                </div>




                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>


                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>

                            {/* button div */}

                            <div className=" w-full flex justify-end  pr-8   ">
                                {
                                    user === "customer" && (
                                        <Button
                                            onClick={handleSubmit}
                                            bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                            {
                                                isQuotationCreated ? "View Quotations" : "No Quotations"
                                            }
                                        </Button>
                                    )
                                }
                                {
                                    user === "transporter" && showButton && (
                                        <Button
                                            onClick={handleSubmit}
                                            bgColor="py:2  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                            Create Quotation
                                        </Button>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                )
            }


            {
                quotationRequestData.freight_medium === "ocean" && quotationRequestData.freight_type === "FCL" && quotationRequestData.form_type === "reefer" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>

                            </div>
                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">

                                    {/* middle right col-wise */}
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">


                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Loading</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.loading_port_country} {'>'} {quotationRequestData.loading_port} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Discharge</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'> {quotationRequestData.discharge_port_country} {'>'} {quotationRequestData.discharge_port} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="">
                                                <h1>Date</h1>
                                                <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                            </div>
                                        </div>

                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>


                                            <div className="flex flex-row gap-5">
                                                <div className="flex-col flex gap-2 items-center">
                                                    <h1>Temp</h1>
                                                    <h1 className='w-[50px] border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px] '>{quotationRequestData.temperature}</h1>
                                                </div>

                                                <div className="flex-col flex gap-2 items-center">
                                                    <h1>Ventilation</h1>
                                                    <h1 className='w-[50px]  border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.ventilation}</h1>
                                                </div>

                                                <div className="flex-col flex gap-2 items-center">
                                                    <h1>Humidity</h1>
                                                    <h1 className=' w-[50px] border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.humidity}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">
                                            <div className="">
                                                <h1>Total Package</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.total_packages}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier quantity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_quantity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier Type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_type}</h1>
                                            </div>
                                        </div>


                                        {
                                            quotationRequestData.non_stackable && (
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex-col flex gap-2">
                                                        <h1>Length</h1>
                                                        <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px] '>{quotationRequestData.dimensions_length}</h1>
                                                    </div>

                                                    <div className="flex-col flex gap-2">
                                                        <h1>Width</h1>
                                                        <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_width}</h1>
                                                    </div>

                                                    <div className="flex-col flex gap-2">
                                                        <h1>Height</h1>
                                                        <h1 className='border-[1px] border-[#49B5FD] px-1 py-2 text-center rounded-[3px]'>{quotationRequestData.dimensions_height}</h1>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-2/3">
                                    {/* stackable and non-stackabel */}
                                    <div className=" w-1/2 flex flex-col gap-5 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        <div className="flex flex-row gap-3">
                                            <h1>Non-Stackable</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.non_stackable}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>
                                </div>


                                {/* hazardous */}
                                <div className="w-full flex-col flex gap-3 ">
                                    <div className="flex flex-row gap-3">
                                        <h1>Hazardous</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.hazardous}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    {
                                        quotationRequestData.hazardous && (

                                            <div className="grid grid-cols-3 gap-2">
                                                <>
                                                    {
                                                        quotationRequestData.hazardous_document.map((item, index) => {

                                                            let shortenedName = item.name
                                                            if (shortenedName.length >= 18) {
                                                                shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                            }

                                                            
                                                            return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                    <div className="flex flex-row gap-3 items-center ">
                                                                        <IoDocumentTextOutline size={30} />
                                                                        <div className="">
                                                                            <h1>{shortenedName}</h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="">
                                                                        <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        })
                                                    }

                                                </>
                                            </div>
                                        )
                                    }


                                </div>

                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>


                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>



                        </div>
                        {/* button div */}

                        <div className=" w-full flex justify-end  pr-8   ">
                            {
                                user === "customer" && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        {
                                            isQuotationCreated ? "View Quotations" : "No Quotations"
                                        }
                                    </Button>
                                )
                            }
                            {
                                user === "transporter" && showButton && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="py:2  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        Create Quotation
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                )
            }


            {
                quotationRequestData.freight_medium === "ocean" && quotationRequestData.freight_type === "FCL" && quotationRequestData.form_type === "tank" && (
                    <div className=" w-[900px] h-[700px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                        <div className="h-full w-full overflow-auto">
                            <div className="  p-4 py-10 flex flex-row justify-between w-full border-b-[1px] border-[#EDEDED]">
                                <h1 className='font-semibold' >Overview</h1>
                                <div onClick={() => setIsModalOpen(!isModalOpen)} className="flex items-center justify-center rounded-[5px]  w-[30px] h-[30px] border-[#B3B3B3] border-[1px]">
                                    <IoMdClose className='text-[20px]  text-[#B3B3B3]  ' />
                                </div>

                            </div>
                            {/* middle div */}
                            <div className="flex flex-col gap-8  p-5">
                                <div className="self-placed-start flex flex-row gap-5">
                                    <h1 className='font-semibold text-[25px]'>Basic Information</h1>
                                    <h1 className='text-[#49B5FD] bg-[#49b5fd24] border-[1px] border-[#49B5FD] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center'  >

                                        {
                                            quotationRequestData.freight_medium === "air" ? "Air Freight"
                                                : quotationRequestData.freight_medium === "ocean" ?
                                                    quotationRequestData.freight_type === "FCL" ? "Ocean Freight FCL" : "Ocean Freight LCL"
                                                    : ""
                                        }


                                    </h1>
                                </div>

                                <div className="flex flex-row justify-between gap-5 w-full">

                                    {/* middle right col-wise */}
                                    <div className="flex-col flex gap-10">
                                        {/* 1st row */}
                                        <div className="flex flex-row gap-16">


                                            <div className="">
                                                <div className="flex flex-row gap-5">
                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Loading</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                            <h1 className='font-semibold'>{quotationRequestData.loading_port_country} {'>'} {quotationRequestData.loading_port} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="mt-6">
                                                        <LiaLongArrowAltRightSolid className='text-[35px] ' />
                                                    </div>

                                                    <div className="flex flex-col gap-1">
                                                        <h1>Port of Discharge</h1>
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-semibold'> {quotationRequestData.discharge_port_country} {'>'} {quotationRequestData.discharge_port} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">

                                                <div className="">
                                                    <h1>Date</h1>
                                                    <h1 className='flex items-center justify-center gap-3'><MdDateRange /> {quotationRequestData.cargo_ready_date} </h1>
                                                </div>


                                            </div>
                                        </div>

                                        {/* 2nd row */}
                                        <div className="flex flex-row gap-16">
                                            <div className="">
                                                <h1>Commodity</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.commodity}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Hs Code </h1>
                                                <h1 className='font-semibold'>{quotationRequestData.hs_code}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Incoterm</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.incoterm}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Cargo weight</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_weight}</h1>
                                            </div>



                                        </div>
                                        {/* 3rd row */}
                                        <div className="flex flex-row gap-11">

                                            <div className="">
                                                <h1>Cargo type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.cargo_type}</h1>
                                            </div>

                                            <div className="">
                                                <h1>Contanier Type</h1>
                                                <h1 className='font-semibold'>{quotationRequestData.container_type}</h1>
                                            </div>
                                            <div className="grid grid-cols-1 gap-2">
                                                <>
                                                    {
                                                        quotationRequestData.tank_document.map((item, index) => {

                                                            let shortenedName = item.name
                                                            if (shortenedName.length >= 18) {
                                                                shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                            }

                                                            
                                                            return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                    <div className="flex flex-row gap-3 items-center ">
                                                                        <IoDocumentTextOutline size={30} />
                                                                        <div className="">
                                                                            <h1>{shortenedName}</h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="">
                                                                        <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        })
                                                    }

                                                </>
                                            </div>





                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* Additional Information */}
                            <div className=" border-t-[1px] border-[#EDEDED]  p-5 w-full flex flex-col gap-5 ">
                                <h1 className='text-[20px] font-semibold '>Additional Information</h1>

                                {/* stackable and non-stackabel and hazardous */}
                                <div className="flex flex-row justify-between items-start w-2/3">
                                    {/* stackable and non-stackabel */}


                                </div>


                                {/* hazardous */}
                                <div className="w-full flex-col flex gap-3 ">
                                    <div className="flex flex-row gap-3">
                                        <h1>Hazardous</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.hazardous}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    {
                                        quotationRequestData.hazardous && (

                                            <div className="grid grid-cols-3 gap-2">
                                                <>
                                                    {
                                                        quotationRequestData.hazardous_document.map((item, index) => {

                                                            let shortenedName = item.name
                                                            if (shortenedName.length >= 18) {
                                                                shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                            }

                                                            
                                                            return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                    <div className="flex flex-row gap-3 items-center ">
                                                                        <IoDocumentTextOutline size={30} />
                                                                        <div className="">
                                                                            <h1>{shortenedName}</h1>
                                                                        </div>
                                                                    </div>

                                                                    <div className="">
                                                                        <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        })
                                                    }

                                                </>
                                            </div>

                                            // <>
                                            //     {
                                            //         quotationRequestData.hazardous_document.map((item, index) => {
                                            //             
                                            //             return <div className="bg-[#F5F5F5] rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                            //                 <div className="flex flex-row gap-3 items-center ">
                                            //                     <IoDocumentTextOutline size={30} />
                                            //                     <div className="">
                                            //                         <h1>{item.name}</h1>
                                            //                         {/* <p className='text-[#929292] text-[11px]'>1.50 MB</p> */}
                                            //                     </div>
                                            //                 </div>

                                            //                 <div className="">
                                            //                     {/* <Button bgColor="text-[12px] text-[#49B5FD]">View</Button> */}
                                            //                     <a target='_blank' href={item.link} className="text-[12px] text-[#49B5FD]">View</a>
                                            //                 </div>
                                            //             </div>

                                            //         })
                                            //     }

                                            // </>
                                        )
                                    }


                                </div>

                                {/* tansportation */}
                                <div className="">
                                    <div className="">
                                        <div className="flex flex-row gap-3">
                                            <h1>Transportation</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.transportation}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>
                                    </div>

                                    <div className='h-[20px]'></div>

                                    <div className="">
                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Origin</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_origin}
                                                    className='border-[#201515] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_origin && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Origin Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_origin_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className='h-[20px]'></div>

                                        <div className=" flex flex-col gap-3 ">
                                            <div className="flex flex-row gap-3">
                                                <h1>Destination</h1>
                                                <input
                                                    readOnly
                                                    type="checkbox"
                                                    checked={quotationRequestData.transportation_destination}
                                                    className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                                />
                                            </div>

                                            {
                                                quotationRequestData.transportation_destination && (
                                                    <div className="flex flex-row gap-5">
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Pickup Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_pickup_address}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <LiaLongArrowAltRightSolid className='text-[35px] font-light ' />
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <h1>Destination Drop Address</h1>
                                                            <div className="flex flex-row items-center gap-2">
                                                                {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                                <h1 className='font-semibold'>{quotationRequestData.transportation_destination_drop_address} </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>

                                {/* custom clearance */}
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-row gap-3">
                                        <h1>Custom Clearance</h1>
                                        <input
                                            readOnly
                                            type="checkbox"
                                            checked={quotationRequestData.customs_clearance}
                                            className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                        />
                                    </div>

                                    <div className=" flex flex-col gap-3 ">
                                        <div className="flex flex-row gap-3">
                                            <h1>Origin</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_origin}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>


                                        {
                                            quotationRequestData.customs_clearance_origin && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_origin_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className=" flex flex-col gap-3 ">

                                        <div className="flex flex-row gap-3">
                                            <h1>Destination</h1>
                                            <input
                                                readOnly
                                                type="checkbox"
                                                checked={quotationRequestData.customs_clearance_destination}
                                                className='border-[#DDDDDD] w-[20px] h-[20px] text-[13px] outline-none border-[1px] bg-white px-[10px]'
                                            />
                                        </div>

                                        {
                                            quotationRequestData.customs_clearance_destination && (
                                                <div className="grid grid-cols-3 gap-2">
                                                    <>
                                                        {
                                                            quotationRequestData.customs_clearance_destination_document.map((item, index) => {

                                                                let shortenedName = item.name
                                                                if (shortenedName.length >= 18) {
                                                                    shortenedName = `${item.name.substring(0, 7)}.....${item.name.substring(item.name.length - 7)}`;
                                                                }

                                                                
                                                                return <a target='_blank' href={item.link} className='cursor-pointer'>
                                                                    <div className="bg-[#F5F5F5] w-full gap-5 rounded-[5px] items-center justify-between flex-row flex py-2 px-2">
                                                                        <div className="flex flex-row gap-3 items-center ">
                                                                            <IoDocumentTextOutline size={30} />
                                                                            <div className="">
                                                                                <h1>{shortenedName}</h1>
                                                                            </div>
                                                                        </div>

                                                                        <div className="">
                                                                            <Button bgColor="text-[12px] text-[#49B5FD]">View</Button>
                                                                        </div>
                                                                    </div>
                                                                </a>

                                                            })
                                                        }

                                                    </>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>

                                {/* additiona notes */}
                                <div className="flex flex-col gap-3">
                                    <h1>Additional notes</h1>
                                    <h1 className='text-[#929292] text-[13px] '>{quotationRequestData.addtional_notes}</h1>
                                </div>
                            </div>


                        </div>
                        {/* button div */}

                        <div className=" w-full flex justify-end  pr-8   ">
                            {
                                user === "customer" && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        {
                                            isQuotationCreated ? "View Quotations" : "No Quotations"
                                        }
                                    </Button>
                                )
                            }
                            {
                                user === "transporter" && showButton && (
                                    <Button
                                        onClick={handleSubmit}
                                        bgColor="py:2  md:py-2 md:px-5 2xl:h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">
                                        Create Quotation
                                    </Button>
                                )
                            }
                        </div>
                    </div>
                )
            }



        </div>
    )
}

export default ModalQuotationDetails

