import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Button from '../components/Button';
import { BsPlusLg } from "react-icons/bs";
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { CgArrowLongRight } from "react-icons/cg";
import Dropdown from '../components/Dropdown';
import { IoMdClose } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import nikeIcon from '../assets/nike.png'
import profileIcon from '../assets/uploadLogo.png'
import { MdOutlinePhone } from "react-icons/md";
import { TbMail } from "react-icons/tb";
import { IoEarthOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import axios from 'axios';
import Nav from '../components/Nav';
import SearchBar from '../components/SearchBar';
import { ThreeCircles } from 'react-loader-spinner';
import { MdLocationPin } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import SearchContext from '../SearchContext';


const DirectoryPage = () => {


    const [directoryData, setDirectoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const { searchQuery } = useContext(SearchContext);
    const [data, setData] = useState([]);

    const userData = JSON.parse(localStorage.getItem('userData'))


    const getDashboardQuotationrequest = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/directory?user_id=${userData !== null ? userData.id : ""}`)
            .then(response => {
                setLoading(false);
                // handle success



                if (response.data.success) {
                    setDirectoryData(response.data.data)
                    let data = response.data.data[0];


                }
            })
            .catch(error => {
                // handle error

            });
    }



    useEffect(() => {
        setLoading(true);
        getDashboardQuotationrequest();
        fetchData();
    }, []);


    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = data.filter(item =>
                Object.values(item).some(
                    value =>
                        value && typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]); // Clear filtered data if searchQuery is empty
        }
    }, [searchQuery, data]);


    const fetchData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/directory?user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success

                if (response.data.success) {
                    if (response.data.success) {
                        setData(response.data.data);
                        // setFilteredData(response.data.data);
                    }
                }
            })
            .catch(error => {
                // handle error

            });
    };




    return (
        <div className='flex flex-col justify-center items-center  py-[40px] xs:gap-[90px] sm:gap-[50px]' >
            <Nav />
            <SearchBar />
            <div className="grid max-md:grid-cols-1 sm:grid-cols-2 max-lg:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-0 mt-3 w-full  px-10 sm:px-5">
                {!loading && (searchQuery ? filteredData : directoryData).map((item, index) => (
                    // <div key={index} className="flex flex-col gap-3 border-[1px] border-[#E0E0E0] pt-5 pb-1 px-5 w-auto ">
                    //     <div className="h-1/3 flex flex-col gap-3 bg-red-500">
                    //         {item.is_verified && (
                    //             <h1 className="text-[#49B5FD] border-[1px] border-[#E0E0E0] py-1 w-[150px] rounded-[5px] text-center text-[12px] flex items-center justify-center">
                    //                 <MdVerified className="text-[#49B5FD]" /> Verified company
                    //             </h1>
                    //         )}
                    //     </div>

                    //     <div className="h-1/3 flex flex-col gap-3 bg-red-500">
                    //         <div className="flex flex-row justify-between items-start w-full">
                    //             <p className="font-bold text-[22px] w-5/6 tracking-wide">{item.company_name}</p>
                    //             {
                    //                 item.profile_photo ?
                    //                     <img src={item.profile_photo} alt="" className="w-[50px] h-[50px] rounded-full" />
                    //                     : <img src={profileIcon} alt="" className="w-[50px] h-[50px] rounded-full" />
                    //             }
                    //         </div>
                    //     </div>

                    //     <div className="flex flex-col h-2/3 w-full gap-3 bg-red-500  ">
                    //         <div className="flex flex-row items-center gap-2 ">
                    //             <MdLocationPin className='w-1/10' size={20} />
                    //             <h1>{item.country}</h1>
                    //         </div>
                    //         <div className=" w-full flex flex-row items-start gap-2">
                    //             <div className="w-1/10 ">
                    //                 <FaNetworkWired size={20} />
                    //             </div>
                    //             <p className='text-gray-600 text-[13px] break-all '> {item.services && Array.isArray(item.services)
                    //                 ? item.services.join(', ')
                    //                 : item.services || 'No services available'}</p>
                    //         </div>
                    //     </div>


                    //     {/* 
                    //     <div className="flex flex-row gap-2 justify-end  py-2 h-1/3 ">
                    //         <a href={`/inquiry-hub?company=${item.id}`}>

                    //         <Button bgColor="w-[130px] h-[45px] border-[2px] border-[#49B5FD] rounded-[5px] text-[14px] text-[#49B5FD] font-medium">

                    //             Send Enquiry
                    //         </Button>
                    //     </a>
                    //     </div> */}
                    // </div>

                    <div
                        key={index}
                        className="flex flex-col gap-4 border border-gray-300 p-6 w-full h-full max-w-md rounded-lg shadow-sm"
                    >
                        {/* Verified Status */}
                        <div className="w-full h-1/4 ">
                            {item.is_verified === true ?(
                                <div className="  flex items-center justify-center gap-1 border border-gray-300 text-[#49B5FD] rounded-md py-1 w-[160px] text-sm font-semibold">
                                    <MdVerified className="text-[#49B5FD]" /> Verified Company
                                </div>
                            ): null}
                        </div>

                        {/* Company Info */}
                        <div className="flex items-center justify-between h-1/4">
                            <p className="font-semibold text-lg tracking-wide text-gray-900">
                                {item.company_name}
                            </p>
                            <img
                                src={item.profile_photo || profileIcon}
                                alt="Profile"
                                className="w-12 h-12 rounded-full object-cover border border-gray-300"
                            />
                        </div>

                        {/* Location */}
                        <div className="flex items-center gap-2 text-gray-600 h-1/4">
                            <MdLocationPin size={20} className="text-gray-500" />
                            <span className="text-sm">{item.country}</span>
                        </div>

                        {/* Services */}
                        <div className="flex items-start gap-2 text-gray-600 h-1/4">
                            <div className="flex ">
                                <FaNetworkWired size={20} className="text-gray-500 " />
                            </div>
                            <p className="text-sm break-all ">
                                {item.services && Array.isArray(item.services)
                                    ? item.services.join(', ')
                                    : 'No services available'}
                            </p>
                        </div>

                        {/* Inquiry Button */}
                        {/* Uncomment and adjust the button style as needed */}
                        {/*
  <div className="flex justify-end mt-4">
    <a href={`/inquiry-hub?company=${item.id}`}>
      <Button bgColor="w-[130px] h-[45px] border-2 border-[#49B5FD] rounded-md text-sm text-[#49B5FD] font-medium">
        Send Enquiry
      </Button>
    </a>
  </div>
  */}
                    </div>

                ))}
            </div>

            {
                loading &&
                <div className=" h-full w-full bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit flex flex-col items-center justify-center gap-3">
                        <ThreeCircles
                            visible={true}
                            height="100"
                            width="100"
                            color="#142AA2"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <h1 className='text-[#142AA2]'>Loading</h1>
                    </div>
                </div>
            }
        </div >
    )
}

export default DirectoryPage