import React, { useEffect, useState } from 'react'
import logo from '../assets/LogoFinal.png'

import { useNavigate } from 'react-router-dom';
import Nav from '../components/Nav';
import { GoDotFill } from "react-icons/go";
import axios from 'axios';


const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [newNotifiaction, setNewNotification] = useState(true);
  const getData = () => {
    axios.get(`https://api.freightopedia.world/api/v1/accounts/notification`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("authToken")}`
      }
    })
      .then(response => {
        setLoading(false);


        if (response.data.success) {

          setNotificationData(response.data.data)

        }
      })
      .catch(error => {
        // handle error
      });
  }

  useEffect(() => {
    setNewNotification(false);
    setLoading(true);
    getData();
  }, [])

  return (
    <div className="">

      <div className='  flex flex-col justify-center items-center  py-[40px] px-[20px] xs:gap-[90px] sm:gap-[50px]' >
        <Nav />
      </div>

      <div className="w-full">
        <div className="h-full w-full flex flex-col justify-start items-start overflow-auto">

          {/* Today */}
          <div className="w-full px-10">
            <div className=" flex flex-row justify-Center gap-5 items-center w-full">
              <div className="border-t-[1px] border-[#EDEDED] w-full  "></div>
              <h1 className='font-semibold w-fit px-5 py-1 bg-gray-200 rounded-full text-lgntxt  ' >Today</h1>
              <div className="border-t-[1px] border-[#EDEDED] w-full  "></div>
            </div>




            {
              notificationData.map((data, index) => {
                return (
                  <div key={index} className="p-5">
                    <h1 className='text-[18px] font-semibold'>{data.heading}</h1>
                    <div className="flex flex-row justify-between items-center text-center">
                      <h1 className='text-lgntxt text-[15px]' >{data.description}</h1>
                      {
                        newNotifiaction && (
                          <h1 className='font-semibold px-2  bg-[#49b5fd26] rounded-full text-[#49B5FD] text-center  ' ><GoDotFill /></h1>
                        )
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>
    </div >
  )
}

export default Notification