import React, { Children } from 'react'

const ModalCreateQuotation = ({children}) => {
    return (
        <div className='fixed inset-0 bg-black bg-opacity-50 backdrop-brightness-100 flex items-center justify-center'>
            <div className=" m-5 w-[500px] h-[650px] bg-white rounded-[10px] p-5 flex flex-col gap-5 items-start justify-center overflow-auto">
                {children}
            </div>

        </div>
    )
}

export default ModalCreateQuotation