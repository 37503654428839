import React, { useEffect, useState } from 'react'
import logo from '../assets/LogoFinal.png'
import Button from './Button'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseLargeFill } from "react-icons/ri";
import { LuBellRing } from "react-icons/lu";
import { Link } from 'react-scroll';
import uploadLogoIcon from '../assets/uploadLogo.png'
import { IoMenu } from "react-icons/io5";
import { LiaUserCircleSolid } from "react-icons/lia";

const Nav = () => {
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false)
    const [isHovered, setIsHovered] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))
    const userData = JSON.parse(localStorage.getItem('userData'))


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = (option) => {

    };

    const funToggle = () => {
        setToggle(!toggle);
    }

    useEffect(() => {

    })


    return (
        <>
            {/* Mobile Navbar */}
            <div className={`${toggle ? 'fixed bg-black ' : ''}  inset-0 bg-opacity-30 backdrop-brightness-100 p-3 gap-3 pt-[35px] z-50        md:hidden border-[1px] py-2 w-full border-navBorder flex flex-col shadow-lg items-center justify-center ${toggle ? 'blur-[2px]' : ''}`}>
                <div className="flex flex-row items-center justify-between px-3 w-full">
                    <div className="flex flex-row items-center justify-center gap-1" onClick={()=>navigate("/")}>
                        <img src={logo} alt="logo" className='w-[18px] h-[20px]' />
                        <h1 className='text-logoText text-[17px] font-bold textShadow text-shadow-xl '>freightOpedia</h1>
                    </div>
                    {!toggle && <GiHamburgerMenu className='text-logoText' onClick={funToggle} />}
                </div>
            </div>

            {toggle &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100  md:hidden p-3 gap-3 flex flex-col pt-[35px] z-50">
                    <div className="w-full flex justify-end">
                        <RiCloseLargeFill className='text-logoText font-extrabold text-[20px]' onClick={funToggle} />
                    </div>

                    <div className="flex flex-col gap-3 items-center pt-[100px]">
                        <div className="text-[15px] gap-3 flex flex-col items-center justify-between">
                            <div className=" flex flex-row items-center justify-between gap-5  text-white ">
                                <RouterLink
                                    to="/directory"
                                    className='font-semibold text-[15px]  cursor-pointer '>Directory</RouterLink >

                                {/* <RouterLink to="/login" className='font-semibold text-[15px]'  > Request Quote </RouterLink > */}
                                <div className="font-semibold text-[15px] " onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                                    <h1>Request Quote</h1>
                                    {isHovered && (
                                        <div className="absolute bg-white w-fit px-5 py-5 rounded-[5px] flex flex-col gap-2 text-black ">
                                            <div id='FCL' className="cursor-pointer font-normal " onClick={() => navigate("/create-quote-fcl")}>Ocean Freight (FCL)</div>
                                            <div id='LCL' className="cursor-pointer font-normal " onClick={() => navigate("/create-quote-lcl")}>Ocean Freight (LCL)</div>
                                            <div id='AIR' className="cursor-pointer font-normal " onClick={() => navigate("/create-quote-air")}>Air Freight</div>
                                        </div>
                                    )}
                                </div>
                                <RouterLink
                                    to="/inquiry-hub"
                                    className='font-semibold text-[15px] cursor-pointer'>Inquiries Hub</RouterLink >

                                {/* <RouterLink to="/login" className='font-semibold text-[15px]'>Ask</RouterLink > */}

                            </div>
                        </div>

                        <div className="flex flex-row gap-2 items-center ">
                            {
                                isLoggedIn ?
                                    <>
                                        <div>
                                            {/* <img src={userData.profile_photo ? userData.profile_photo : uploadLogoIcon} alt="" className='w-[45px] h-[45px] rounded-full border-[1px] border-black' /> */}

                                        </div>
                                        <div onClick={() => setIsProfileOpen(!isProfileOpen)} className="flex items-center justify-center border-[1px] border-btnbBorder rounded-[5px] px-2 py-1 cursor-pointer ">
                                            <LiaUserCircleSolid size={25} className="text-white" />


                                        </div>
                                    </>
                                    :
                                    <>
                                        <RouterLink to="/login">
                                            <Button bgColor=" py-2 px-5 lg:py-3 lg:px-10  border-[1px] border-btnbBorder rounded-[5px] text-[14px] text-white font-medium">Login</Button>
                                        </RouterLink>

                                        <RouterLink to="/create-account">
                                            <Button bgColor="py-2 px-5 lg:py-3 lg:px-10 rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">Register</Button>
                                        </RouterLink>
                                    </>

                            }

                            {
                                isProfileOpen && (
                                    <div className="absolute bg-white text-black font-extrabold text-[20px] w-[250px] px-5 py-5 rounded-[5px] flex flex-col gap-2 mt-[190px]">
                                        <div id='FCL' className="cursor-pointer font-normal " onClick={() => navigate("/dashboard/request-quote")}>Overview Hub</div>
                                        <div id='LCL' className="cursor-pointer font-normal " onClick={() => navigate("/dashboard/profile")}>My Profile</div>

                                    </div>
                                )
                            }



                            <RouterLink to="/notification">
                                <Button bgColor="px-[15px] py-2  border-[1px] border-btnbBorder rounded-[5px] text-[14px] text-white font-medium  "><LuBellRing size={18} /></Button>
                            </RouterLink>



                        </div>
                    </div>

                </div>
            }

            {/* Desktop Navbar */}
            <div className='hidden bg-bgCol md:py-2 2xl:w-[1115px] 2xl:h-[84px] border-[1px] border-navBorder md:flex shadow-lg'>
                <div className="flex flex-row items-center justify-between md:gap-5 lg:gap-10 px-5 w-full">
                    <div
                        onClick={() => navigate("/")}
                        className="flex flex-row items-center justify-center gap-1 cursor-pointer">
                        <img src={logo} alt="logo" className='w-[20px] h-[22px]' />
                        <h1 className='text-logoText text-[17px] font-bold text-shadow-xl '>FreightOpedia</h1>
                    </div>

                    <div className="md:text-[12px] lg:text-[15px] flex flex-row items-center justify-between gap-5">
                        <RouterLink
                            to="/directory"
                            className='font-semibold text-[15px]  cursor-pointer '>Directory</RouterLink >

                        {/* <RouterLink to="/login" className='font-semibold text-[15px]'  > Request Quote </RouterLink > */}
                        <div className="font-semibold text-[15px] " onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                            <h1>Request Quote</h1>
                            {isHovered && (
                                <div className="absolute bg-white w-fit px-5 py-5 rounded-[5px] flex flex-col gap-2 ">
                                    <div id='FCL' className="cursor-pointer font-normal " onClick={() => navigate("/create-quote-fcl")}>Ocean Freight (FCL)</div>
                                    <div id='LCL' className="cursor-pointer font-normal " onClick={() => navigate("/create-quote-lcl")}>Ocean Freight (LCL)</div>
                                    <div id='AIR' className="cursor-pointer font-normal " onClick={() => navigate("/create-quote-air")}>Air Freight</div>
                                </div>
                            )}
                        </div>
                        <RouterLink
                            to="/inquiry-hub"
                            className='font-semibold text-[15px] cursor-pointer'>Inquiries Hub</RouterLink >

                        {/* <RouterLink to="/login" className='font-semibold text-[15px]'>Ask</RouterLink > */}

                    </div>

                    <div className="flex flex-row gap-2">

                        {
                            isLoggedIn ?
                                <>
                                    <div>
                                        {/* <img src={userData.profile_photo ? userData.profile_photo : uploadLogoIcon} alt="" className='w-[45px] h-[45px] rounded-full border-[1px] border-black' /> */}

                                    </div>
                                    <div onClick={() => setIsProfileOpen(!isProfileOpen)} className="flex items-center justify-center border-[1px] border-btnbBorder rounded-[5px] px-2 py-1 cursor-pointer ">
                                        <LiaUserCircleSolid size={25} className="text-logoText" />


                                    </div>
                                </>




                                :
                                <>
                                    <RouterLink to="/login">
                                        <Button bgColor="md:py-2 md:px-5 lg:py-3 lg:px-10  border-[1px] border-btnbBorder rounded-[5px] text-[14px] text-lgntxt font-medium">Login</Button>
                                    </RouterLink>

                                    <RouterLink to="/create-account">
                                        <Button bgColor="md:py-2 md:px-5 lg:py-3 lg:px-10 rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary">Register</Button>
                                    </RouterLink>
                                </>

                        }


                        {
                            isLoggedIn ?
                                <RouterLink to="/notification">
                                    <Button bgColor="px-[15px] py-2 lg:py-3  border-[1px] border-btnbBorder rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary "><LuBellRing size={18} /></Button>
                                </RouterLink>
                                :
                                <RouterLink to="/notification">
                                    <Button bgColor="px-[15px] py-2 lg:py-3  border-[1px] border-btnbBorder rounded-[5px] text-[14px] text-lgntxt font-medium   hover:text-white hover:bg-gradient-to-r  hover:from-btnPrimary hover:to-btnSecondary "><LuBellRing size={18} /></Button>
                                </RouterLink>
                        }

                        {
                            isProfileOpen && (
                                <div className="absolute bg-white w-fit px-5 py-5 rounded-[5px] flex flex-col gap-2 mt-16 mr-14 ">
                                    <div id='FCL' className="cursor-pointer font-normal " onClick={() => navigate("/dashboard/request-quote")}>Overview Hub</div>
                                    <div id='LCL' className="cursor-pointer font-normal " onClick={() => navigate("/dashboard/profile")}>My Profile</div>

                                </div>
                            )
                        }

                    </div>

                </div>

            </div >
        </>
    )
}

export default Nav

