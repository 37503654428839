import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { MdDashboard } from "react-icons/md";
import { MdOutlineStoreMallDirectory } from "react-icons/md";
import { MdVerified } from "react-icons/md";
import adminIcon from '../assets/adminIcon.jpg'
// import logo from '../assets/LogoFinal.png'
import logo from '../assets/LogoFinal.png'
import { LuLogOut } from "react-icons/lu";

import { Button } from 'react-scroll';
import uploadLogoIcon from '../assets/uploadLogo.png'
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Button as btn, Menu } from 'antd';

const items = [
  {
    key: '1',
    icon: <MdDashboard />,
    label: 'My Inquiries',
  },
  {
    key: '2',
    icon: <MdOutlineStoreMallDirectory />,
    label: 'Directoy',
  },
  {
    key: '3',
    icon: <MdDashboard />,
    label: 'My Quotation'
  },
];

const items2 = [
  {
    key: '1',
    icon: <LuLogOut />,
    label: 'Logout',
  },
]

const Sidebar = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('');

  const userData = JSON.parse(localStorage.getItem('userData'))

  const handleRequestQuote = () => {
    setActiveSection('request-quote');
    navigate('/dashboard/request-quote');
  }

  const handleQuotationRequest = () => {
    setActiveSection('quotation-request');
    navigate('/dashboard/quotation');
  }
  const handleDirectory = () => {
    setActiveSection('directory');
    navigate('/dashboard/directory');

  }

  const handleLogout = () => {
    localStorage.clear()
    window.location = "/"
  }

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className=" min-w-[80px] w-[250px]  h-screen flex flex-col justify-between gap-20 shadow-lg  md:flex-col  px-3  md:overflow-hidden overflow-auto md:hover:overflow-auto py-7 ">
      <div className="flex flex-col  justify-center">
        {/* logo */}
        <div onClick={() => navigate("/")} className="cursor-pointer">
          <div className="flex flex-row items-center  ">
          <div className="flex flex-row items-center ml-8 sm:ml-0  ">
            <img src={logo} alt="logo" className='w-[18px] h-[20px]  ' />
          </div>
            {/* <img src={logo} alt="logo" className='w-[18px] h-[20px]  ' /> */}
            <h1 className=' hidden sm:block text-logoText text-[17px] font-bold '>FreightOpedia</h1>
          </div>
        </div>

        {/* menu */}
        {/* <div className="flex flex-col  gap-5 px-5 pt-10 w-full ">
          <div onClick={handleRequestQuote}
            className={`cursor-pointer flex flex-row gap-2 py-2 pl-2 w-full items-center text-[#595959] hover:bg-[#49b5fd26] hover:rounded-[5px] hover:text-[#49B5FD] ${activeSection === 'request-quote' ? 'bg-[#49b5fd26] text-[#49B5FD] rounded-[5px]' : ''}`}
          >
            <MdDashboard />
            <h1 className={`cursor-pointer text-[#595959]  hover:rounded-[5px] hover:text-[#49B5FD] ${activeSection === 'request-quote' ? ' text-[#49B5FD] rounded-[5px]' : ''}`}  >My Inquiries</h1>
          </div>

          <div onClick={handleDirectory}
            className={`cursor-pointer flex flex-row gap-2 py-2 pl-2 w-full items-center text-[#595959] hover:bg-[#49b5fd26] hover:rounded-[5px] hover:text-[#49B5FD] ${activeSection === 'directory' ? 'bg-[#49b5fd26] text-[#49B5FD] rounded-[5px]' : ''}`}
          >
            <MdOutlineStoreMallDirectory />
            <h1>Directoy</h1>
          </div>


          <div onClick={handleQuotationRequest}
            className={`cursor-pointer flex flex-row gap-2 py-2 pl-2 w-full items-center text-[#595959] hover:bg-[#49b5fd26] hover:rounded-[5px] hover:text-[#49B5FD] ${activeSection === 'quotation-request' ? 'bg-[#49b5fd26] text-[#49B5FD] rounded-[5px]' : ''}`}
          >
            <MdDashboard />
            <h1 className={`cursor-pointer text-[#595959]  hover:rounded-[5px] hover:text-[#49B5FD] ${activeSection === 'quotation-request' ? ' text-[#49B5FD] rounded-[5px]' : ''}`}  >My Quotation</h1>
          </div>



        </div> */}
        {/* <Menu
          style={{
            paddingTop: 30,
          }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          items={items}
          onClick={({ key }) => {
            if (key === '1') {
              handleRequestQuote(); // Call the function for 'My Inquiries'
            } else if (key === '2') {
              handleDirectory(); // Call the function for 'Directory'
            } else if (key === '3') {
              handleQuotationRequest(); // Call the function for 'My Quotation'
            }
          }}
        /> */}
        <div className='pt-5'>
          {/* <div className="flex justify-start pl-8">
            <Button
              type="primary"
              onClick={toggleCollapsed}
              style={{
                marginBottom: 16,
                placeItems: 'end'
              }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </div> */}

          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items}
            onClick={({ key }) => {
              if (key === '1') {
                handleRequestQuote(); // Call the function for 'My Inquiries'
              } else if (key === '2') {
                handleDirectory(); // Call the function for 'Directory'
              } else if (key === '3') {
                handleQuotationRequest(); // Call the function for 'My Quotation'
              }
            }}
          />
        </div>
      </div>


      {/* logged in user */}
      <div className="border-t-[#F2F2F2] border-t-[1px] pt-2 mx-5 cursor-pointer flex flex-col gap-5 ">

        <div onClick={() => navigate('/dashboard/profile')} className="flex flex-col items-center gap-2 sm:flex-row sm:items-center sm:gap-2 md:gap-5 ">
          <img src={userData.profile_photo ? userData.profile_photo : uploadLogoIcon} alt="" className='w-[40px] h-[40px] rounded-full' />
          {/* <img src={userData.profile_photo ? userData.profile_photo : uploadLogoIcon} alt="" className='w-full h-full rounded-full' /> */}

          <div className="">
            <div className="flex flex-row gap-2 items-center justify-start">
              <h1 className='text-[12px] md:text-[15px] ' >{userData.name}</h1>
              {
                userData.is_verified ? <MdVerified className='text-[#49B5FD]' /> : null
              }

            </div>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Button onClick={handleLogout} className=" w-full text-[10px] md:py-2 md:px-5  border-[1px] border-red-500 rounded-[5px] sm:text-[14px] font-medium py-2 text-white bg-red-600">Logout</Button>
        </div>

        {/* <div className='pt-5'>
          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items2}
            onClick={({ key }) => {
              if (key === '1') {
                handleRequestQuote(); // Call the function for 'My Inquiries'
              } else if (key === '2') {
                handleDirectory(); // Call the function for 'Directory'
              } else if (key === '3') {
                handleQuotationRequest(); // Call the function for 'My Quotation'
              }
            }}
          />
        </div> */}

      </div>


    </div>

  )
}

export default Sidebar