import React, { useEffect, useState } from 'react'
// import Button from './Button'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Button from '../components/Button';
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { CgArrowLongRight } from "react-icons/cg";
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { RotatingLines } from 'react-loader-spinner';

import ModalQuotationDetails from '../components/ModalQuotationDetails';



const Quoet = () => {

    const [newQuote, setNewQuote] = useState(false);
    const [freightMedium, setFreightMedium] = useState('');
    const [freightType, setFreightType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeQuotationRequest, setActiveQuotationRequest] = useState('');
    const [inquiryHubData, setInquiryHubData] = useState([]);

    const userData = JSON.parse(localStorage.getItem('userData'))
    const [isExpanded, setIsExpanded] = useState(false);
    const [index, setIndex] = useState(0);  // State to track the current slide index
    const [expandedIndex, setExpandedIndex] = useState(null);  // State to track the expanded slide
    // const [loadingDetails, setLoadingDetails] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleViewDetails = (qr_id) => {
        // setLoadingDetails(true);
        
        setActiveQuotationRequest(qr_id)
        setIsModalOpen(true)
    }


    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     speed: 5000,
    //     autoplaySpeed: 500,
    //     cssEase: "linear",
    // };

    var settings = {
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 1500, // Adjust the speed as needed
        afterChange: current => setIndex(current),  // Set the index after each slide change,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // initialSlide: 0
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const getData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/inquiry-hub?user_id=${userData !== null ? userData.id : ""}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // setLoadingDetails(false);
                // handle success
                
                

                if (response.data.success) {
                    setInquiryHubData(response.data.data)
                    

                }
            })
            .catch(error => {
                // handle error
                
            });
    }

    useEffect(() => {
        setLoading(true);
        getData();
    }, [])


    return (
        <div id="inquiry-hub" className=' w-full h-full'>


            <div className="flex flex-col justify-between  w-full h-full ">
                <div className="flex flex-col items-center justify-center py-3 ">
                    <h1 className='text-[35px] font-medium text-[#000000] '>Inquiry <span className='text-[#49B5FD] font-bold'>Hub</span></h1>
                </div>

                <div className="w-full">
                    <div className="w-full overflow-hidden">
                        {
                            isModalOpen && (
                                <div className='absolute z-50 top-0 left-0'>
                                    <ModalQuotationDetails quotationRequest={activeQuotationRequest} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} user={"transporter"} showButton={true} />
                                </div>

                            )}

                        {/*  slider */}
                        {/* testing */}
                        {
                            inquiryHubData.length === 1 ?
                                <div className="p-10  flex items-center justify-center w-full  gap-5">
                                    {
                                        inquiryHubData.map((item, index) => {

                                            return (
                                                <div key={index} className="space-y-2 flex  flex-col gap-3 border-[1px] border-[#E0E0E0] pt-5 pb-1 px-5 w-[450px] ">
                                                    <h1 className='text-[#6F25EB] border-[1px] border-[#6F25EB] py-1 w-fit px-5 rounded-[5px] text-center bg-[#6f25eb14] flex items-center justify-center'  >{item.freight_medium} Freight</h1>
                                                    <div className="">
                                                        <h1>Commodity</h1>
                                                        <p className='font-medium text-[13px]'>{item.commodity}</p>
                                                    </div>
                                                    <div className="flex flex-row gap-5 items-center">
                                                        <div className="">
                                                            <label className="text-[15px] text-[#595959]" >Cargo wt</label>
                                                            <h1 className='font-medium'>{item.cargo_weight}</h1>
                                                        </div>

                                                        <div className="">
                                                            <label className=' text-[15px] text-[#595959]'>Quote no. </label>
                                                            <h1 className='font-medium'>{item.quote_no}</h1>
                                                        </div>


                                                    </div>
                                                    <div className="flex flex-row items-center gap-5">
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-bold'>{item.source}</h1>
                                                        </div>
                                                        <CgArrowLongRight className='text-[35px]' />
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={indiaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-bold'>{item.destination} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row gap-2 justify-end border-t-[1px] border-[#E0E0E0] py-2">
                                                        <Button onClick={() => handleViewDetails(item.id)} bgColor="w-[130px] h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]">View Details</Button>
                                                        {/* {loadingDetails &&
                                                    <div className="fixed inset-0 bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                                                        <div className="w-fit">
                                                            <Circles
                                                                height="80"
                                                                width="80"
                                                                color="#142AA2"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </div>
                                                } */}
                                                    </div>


                                                </div>
                                            )

                                        })
                                    }
                                </div> :




                                <Slider {...settings}>
                                    {
                                        inquiryHubData.map((item, index) => {

                                            return (
                                                <div key={index} className="space-y-2 flex  flex-col gap-3 border-[1px] border-[#E0E0E0] pt-5 pb-1 px-5 w-auto ">
                                                    <h1 className='text-[#6F25EB] border-[1px] border-[#6F25EB] py-1 w-fit px-5 rounded-[5px] text-center bg-[#6f25eb14] flex items-center justify-center'  >{item.freight_medium} Freight</h1>
                                                    <div className="">
                                                        <h1>Commodity</h1>
                                                        <p className='font-medium text-[13px]'>{item.commodity}</p>
                                                    </div>
                                                    <div className="flex flex-row gap-5 items-center">
                                                        <div className="">
                                                            <label className="text-[15px] text-[#595959]" >Cargo wt</label>
                                                            <h1 className='font-medium'>{item.cargo_weight}</h1>
                                                        </div>

                                                        <div className="">
                                                            <label className=' text-[15px] text-[#595959]'>Quote no. </label>
                                                            <h1 className='font-medium'>{item.quote_no}</h1>
                                                        </div>


                                                    </div>
                                                    <div className="flex flex-row items-center gap-5">
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-bold'>{item.source}</h1>
                                                        </div>
                                                        <CgArrowLongRight className='text-[35px]' />
                                                        <div className="flex flex-row items-center gap-2">
                                                            {/* <img src={indiaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                                            <h1 className='font-bold'>{item.destination} </h1>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row gap-2 justify-end border-t-[1px] border-[#E0E0E0] py-2">
                                                        <Button onClick={() => handleViewDetails(item.id)} bgColor="w-[130px] h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]">View Details</Button>
                                                        {/* {loadingDetails &&
                                                    <div className="fixed inset-0 bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                                                        <div className="w-fit">
                                                            <Circles
                                                                height="80"
                                                                width="80"
                                                                color="#142AA2"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </div>
                                                } */}
                                                    </div>


                                                </div>
                                            )

                                        })
                                    }
                                </Slider>

                        }
                    </div>





                </div>
            </div>

            {loading &&
                <div className="w-full h-full  bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit pt-5 ">
                        <RotatingLines
                            visible={true}
                            height="50"
                            width="50"
                            // color="#142AA2"
                            strokeColor="#20586a"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </div>
            }
        </div>
    )
}



export default Quoet