import React from 'react';
import 'tailwindcss/tailwind.css';

const Overview = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="bg-white shadow rounded-lg p-6 max-w-4xl mx-auto">
        .
        <h2 className="text-2xl font-semibold mb-6">Overview</h2>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Basic Information</h3>
          <div className="flex items-center mb-6">
            <img src="https://via.placeholder.com/50" alt="Company Logo" className="mr-4" />
            <div>
              <h3 className="text-xl font-semibold">NimbusForge CO., LTD.</h3>
              <span className="bg-blue-200 text-blue-800 text-sm font-semibold px-2.5 py-0.5 rounded">Air Freight</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
            
              <p><strong>Departure:</strong> India</p>
              <p><strong>Arrival:</strong> Dubai</p>
              <p><strong>Cargo Ready Date:</strong> 19-02-2025</p>
              <p><strong>Commodity:</strong> India</p>
              <p><strong>HS Code:</strong> 0xff01258</p>
              <p><strong>Incoterm:</strong> New User</p>
              <p><strong>Cargo Weight:</strong> 120 Tone</p>
              <p><strong>Total Package:</strong> 90</p>
              <p><strong>Cargo Type:</strong> 20 Dry</p>
              <p><strong>Volumetric Weight:</strong> 190 kg</p>
            </div>
            <div>
              <p><strong>Dimensions (L x W x H):</strong> 2 x 10 x 9</p>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-4">Additional Information</h3>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="flex items-center">
              <input type="checkbox" checked readOnly className="mr-2" />
              <label>Stackable</label>
            </div>
            <div className="flex items-center">
              <input type="checkbox" checked readOnly className="mr-2" />
              <label>Hazardous</label>
            </div>
            <div className="flex items-center">
              <input type="checkbox" readOnly className="mr-2" />
              <label>Non-Stackable</label>
            </div>
            <div className="flex items-center">
              <input type="checkbox" checked readOnly className="mr-2" />
              <label>Transportation (Destination)</label>
            </div>
          </div>
          <div className="mb-4">
            <p><strong>Custom Clearance:</strong></p>
            <div className="flex items-center mb-2">
              <input type="checkbox" readOnly className="mr-2" />
              <label>Origin</label>
            </div>
            <div className="flex items-center">
              <input type="checkbox" checked readOnly className="mr-2" />
              <label>Destination</label>
            </div>
          </div>
          <div className="mb-4">
            <p><strong>Documents:</strong></p>
            <div className="flex flex-col space-y-2">
              <a href="#" className="text-blue-600 hover:underline">Document 1 (150 MB)</a>
              <a href="#" className="text-blue-600 hover:underline">Document 2 (150 MB)</a>
              <a href="#" className="text-blue-600 hover:underline">Document 3 (150 MB)</a>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-4">Additional Notes</h3>
          <p>Enhancing visibility across the supply chain can significantly improve logistics efficiency. Technologies like IoT, blockchain, and advanced analytics help in real-time tracking of goods, ensuring transparency and reducing delays.</p>
        </div>
      </div>
    </div>
  );
};

export default Overview;