import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Button from '../components/Button';
import { BsPlusLg } from "react-icons/bs";
import indiaIcon from '../assets/indiaIcon.png';
import usaIcon from '../assets/usaIcon.png';
import { CgArrowLongRight } from "react-icons/cg";
import Dropdown from '../components/Dropdown';
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../components/Modal'
import axios from 'axios';
import { Circles } from 'react-loader-spinner';

import ModalQuotationDetails from '../components/ModalQuotationDetails';
import SearchContext from '../SearchContext';


const RequestQuote = () => {
    const [newQuote, setNewQuote] = useState(false);
    const [freightMedium, setFreightMedium] = useState('');
    const [freightType, setFreightType] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeQuotationRequest, setActiveQuotationRequest] = useState('');
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const { searchQuery } = useContext(SearchContext);
    const [data, setData] = useState([]);

    const funNewQuote = () => {
        setNewQuote(!newQuote);
    }
    const navigate = useNavigate();
    // const handleViewDetails = () => {
    //     
    //     navigate('/dashboard/request-quote/quotation')
    // }

    const handleViewDetails = (qr_id) => {
        
        setActiveQuotationRequest(qr_id)
        setIsModalOpen(true)
    }

    const freightOptions = [
        'Ocean Freight (FCL)',
        'Ocean Freight (LCL)',
        'Air Freight'
    ];
    const [selectedFreight, setSelectedFreight] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const handleSelect = (option) => {
        setSelectedFreight(option);

        
        if (option === "Ocean Freight (FCL)") {
            navigate("/create-quote-fcl")

        } else if (option === "Ocean Freight (LCL)") {
            navigate("/create-quote-lcl")

        } else if (option === "Air Freight") {
            navigate("/create-quote-air")

        }



        setIsDropdownOpen(false); // Close dropdown after selection
        
        
    }
    const funHandlefreightMediumAndType = () => {
        if (selectedFreight === 'Ocean Freight (FCL)') {
            setFreightMedium('Ocean');
            setFreightType('FCL');
        } else if (selectedFreight === 'Ocean Freight (LCL)') {
            setFreightMedium('Ocean');
            setFreightType('LCL');
        } else if (selectedFreight === 'Air Freight') {
            setFreightMedium('Air');
        }

    }


    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [isComponentVisible, setIsComponentVisible] = useState(true);
    const handleClose = () => {
        // setSelectedFreight('');
        // setIsDropdownOpen(false); // Ensure dropdown is closed when reset
        setNewQuote(!newQuote); // Close the entire component
    };


    const [quotatioRequestData, setQuotatioRequestData] = useState([]);


    const getDashboardQuotationrequest = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/dashboard`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success
                
                

                if (response.data.success) {

                    setQuotatioRequestData(response.data.data)
                    
                    

                }
            })
            .catch(error => {
                // handle error
                
            });
    }



    useEffect(() => {
        setLoading(true);
        getDashboardQuotationrequest();
        fetchData();
    }, []);

    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = data.filter(item =>
                Object.values(item).some(
                    value =>
                        value && typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]); // Clear filtered data if searchQuery is empty
        }
    }, [searchQuery, data]);


    const fetchData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/dashboard`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);
                // handle success
                
                

                if (response.data.success) {
                    if (response.data.success) {
                        setData(response.data.data);
                        // setFilteredData(response.data.data);
                    }
                }
            })
            .catch(error => {
                // handle error
                
            });
    };


    return (
        <div>
            <Outlet />
            <div className="">
                <div className="w-full">
                    <div className="flex flex-row justify-between  w-full">
                        <h1>Request Quote</h1>

                        <div className="flex flex-col items-end">

                            <Button onClick={funNewQuote}
                                bgColor="mt-4 w-[210px] h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-[#49B5FD] flex flex-row gap-2 items-center justify-center " > <span><BsPlusLg size={20} /></span> New Quote</Button>
                            {newQuote === true ?
                                <div className="border-[#E0E0E0] border-[1px] cursor-pointer w-[300px] rounded-[5px] ">
                                    <div className="p-5">
                                        <div className="dropdown">
                                            <div className="border-b-[1px] border-[#F1F1F1] flex flex-row  items-center justify-between " onClick={handleToggleDropdown}>
                                                <span className='text-[#4E4E4E]' >{selectedFreight || 'Choose Freight'}</span>

                                                {
                                                    isComponentVisible && (
                                                        <div className="" onClick={handleClose}>
                                                            <Button bgColor=" rounded-full bg-[#F6F6F6] p-2 text-[15px]" >
                                                                <IoMdClose />
                                                            </Button>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                            {isDropdownOpen && (
                                                <ul className="flex flex-col pt-2">
                                                    {freightOptions.map((option, index) => (
                                                        <li
                                                            key={index}
                                                            className={`text-[#4E4E4E] hover:text-black py-2 pl-2 rounded-[5px] hover:bg-[#F6F6F6] dropdown-item ${selectedFreight === option ? 'selected' : ''
                                                                }`}
                                                            onClick={() => handleSelect(option)}
                                                        >
                                                            {option}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>

                    </div>

                    {/* className='grid max-md:grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-3 mt-3' */}
                    <div className='grid max-md:grid-cols-1 md:grid-cols-1 md2:grid-cols-2 max-lg:grid-cols-1 lg:grid-cols-2 max-xl:grid-cols-2 xl:grid-cols-3 gap-y-8 gap-x-3 mt-3 w-full'>
                    
                        {
                            isModalOpen && <ModalQuotationDetails quotationRequest={activeQuotationRequest} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} user={"customer"} showButton={true} />
                        }



                        {/* {!loading && searchQuery && filteredData.length === 0 && (
                            <p>No results found</p>
                        )} */}

                        {loading ? (
                            <div className=" w-full h-full  bg-opacity-30 backdrop-brightness-100 flex items-center justify-end pt-[200px] ">
                                <div className="w-ull h-full  flex flex-col items-center justify-center ">
                                    <Circles
                                        height="80"
                                        width="80"
                                        color="#142AA2"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                    <h1 className='text-[#142AA2]'>Loading ...</h1>
                                </div>
                            </div>
                        ) : filteredData.length === 0 && searchQuery ? (
                            <p>No results found</p>
                        ) : (
                            (searchQuery ? filteredData : quotatioRequestData).map((item, index) => (

                                <div className=" flex flex-col gap-3 border-[1px] border-[#E0E0E0] pt-5 pb-1 px-5 w-full">
                                    <div className="flex flex-row gap-3 items-center">
                                        <h1 className='text-[#6F25EB] border-[1px] border-[#6F25EB] py-1 w-fit px-5 rounded-[5px] text-center bg-[#6f25eb14] flex items-center justify-center'  >{item.freight_medium} Freight</h1>
                                        {
                                            item.is_completed ?
                                                <h1 className='text-[#19651a] border-[1px] border-[#19651a] py-1 w-fit px-5 rounded-[5px] text-center bg-[#39803324] flex items-center justify-center'  >Completed</h1>
                                                : null
                                        }
                                    </div>
                                    <h1>Commodity</h1>
                                    <p className='font-bold'>{item.commodity}</p>
                                    <div className="flex flex-row gap-5 items-center">
                                        <div className="">
                                            <label className="text-[13px] text-[#595959]" >Cargo wt</label>
                                            <h1 className='font-bold'>{item.cargo_weight}</h1>
                                        </div>

                                        <div className="">
                                            <label htmlFor="">Quote no. </label>
                                            <h1 className='font-bold'>{item.quote_no}</h1>
                                        </div>


                                    </div>
                                    <div className="flex flex-row items-center gap-5">
                                        <div className="flex flex-row items-center gap-2">
                                            {/* <img src={usaIcon} alt="" className='w-[35px] h-[20px]' /> */}
                                            <h1 className='font-bold'>{item.source}</h1>
                                        </div>
                                        <CgArrowLongRight className='text-[35px]' />
                                        <div className="flex flex-row items-center gap-2">
                                            {/*<img src={indiaIcon} alt="" className='w-[35px] h-[20px]' />*/}
                                            <h1 className='font-bold'>{item.destination} </h1>
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-2 justify-end border-t-[1px] border-[#E0E0E0] py-2">
                                        <Button
                                            onClick={() => {
                                                if (item.quotation_count !== 0) {
                                                    window.location = `/dashboard/${item.id}/quotation`
                                                }
                                            }}
                                            bgColor="w-[150px] md2:w-[125px] md3:w-[150px]  h-[45px] border-[2px] border-[#E0E0E0] rounded-[5px] text-[14px] text-lgntxt font-medium"
                                        >
                                            Get Quotation ({item.quotation_count})
                                        </Button>
                                        <Button onClick={() => handleViewDetails(item.id)} bgColor="w-[130px] md2:w-[125px] h-[45px] rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]">View Details</Button>
                                    </div>
                                </div>
                            ))
                        )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestQuote
