import React, { useState, useEffect, useContext } from 'react'
import Button from '../components/Button';
import QuotationDetails from './QuotationDetails';
import axios from 'axios';
import ConfirmQuotation from './confirmQuotation';
import { Circles } from 'react-loader-spinner';
import SearchContext from '../SearchContext';



const GivenQuotationList = () => {

    const [quotationDetails, setQuotationDetails] = useState(false);
    const [quotation, setQuotation] = useState('')
    const [quotatioData, setQuotatioData] = useState([])
    const [quotationRequest, setQuotationRequest] = useState('')
    const [loading, setLoading] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const { searchQuery } = useContext(SearchContext);
    const [data, setData] = useState([]);
    const dataToDisplay = searchQuery ? filteredData : quotatioData;


    const handleViewDeatails = (q_id, qr_id) => {
        setQuotation(q_id)
        setQuotationRequest(qr_id)
        setQuotationDetails(!quotationDetails);

    }

    const getAllQuotation = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/given-quotation`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false)
                // handle success



                if (response.data.success) {

                    setQuotatioData(response.data.data)


                }
            })
            .catch(error => {
                // handle error

            });
    }

    const onClose = () => {
        setQuotationDetails(false)
    }

    useEffect(() => {
        setLoading(true)
        getAllQuotation();
        fetchData();

    }, []);

    useEffect(() => {
        if (searchQuery) {
            const lowercasedQuery = searchQuery.toLowerCase();
            const filtered = data.filter(item =>
                Object.values(item).some(
                    value =>
                        value && typeof value === 'string' && value.toLowerCase().includes(lowercasedQuery)
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData([]); // Clear filtered data if searchQuery is empty
        }
    }, [searchQuery, data]);


    const fetchData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/given-quotation`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false)
                // handle success



                if (response.data.success) {

                    // setQuotatioData(response.data.data)
                    setData(response.data.data);


                }
            })
            .catch(error => {
                // handle error

            });
    };



    return (
        <div className='-mt-5 w-full h-full relative' >





            {
                quotationDetails && (
                    <ConfirmQuotation quotationRequest={quotationRequest} quotation={quotation} onClose={onClose} user={"transporter"} />
                )
            }
            <table className=' w-full text-center table-auto'>
                <thead className=' text-[13px] lg:text-xl'>
                    <tr>
                        <th className='font-semibold ' >Request ID</th>
                        <th className='font-semibold ' >Company Name</th>
                        <th className='font-semibold ' >Validity Days</th>
                        <th className='font-semibold ' >Total Price</th>
                        <th  > </th>

                    </tr>
                </thead>



                <tbody className=''>
                    {dataToDisplay.map((item) => (
                        <tr key={item.id} className=' border-b-[1px] border-b-[#EDEDED]  '>
                            <td className='py-5' ><b>#{item.quote_no}</b></td>
                            <td className='py-5' >{item.requester_company}</td>
                            <td className='py-5 text-[#5C5C5C] text-[13px]' >{item.validity}</td>
                            <td className='py-5 text-[#5C5C5C] text-[15px]' >{item.total}</td>
                            <td className='py-5' ><Button onClick={() => handleViewDeatails(item.id, item.request_id)} bgColor="w-[130px] py-1 rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]">View Details</Button></td>
                        </tr>
                    ))}

                </tbody>
            </table>

            {!loading && searchQuery && filteredData.length === 0 && (
                <div className=" w-full h-full flex justify-center items-center">
                    <p className='text-center'>No results found</p>
                </div>
            )}




            {loading &&
                <div className=" w-full h-full bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-ull h-full flex flex-col items-center justify-center pt-[200px]">
                        <Circles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <h1 className='text-[#142AA2]'>Loading ...</h1>
                    </div>
                </div>
            }
        </div>
    )
}


export default GivenQuotationList