import React, { useState, useEffect } from 'react'
import Button from '../components/Button';
import QuotationDetails from './QuotationDetails';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ConfirmQuotation from './confirmQuotation';



const QuotationList = () => {

    const { qr_id } = useParams();

    const [quotationDetails, setQuotationDetails] = useState(false);
    const [quotation, setQuotation] = useState('')
    const [quotatioData, setQuotatioData] = useState([])


    const handleViewDeatails = (q_id) => {
        setQuotationDetails(!quotationDetails);
        setQuotation(q_id)

    }

    const getAllQuotation = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/quotation/${qr_id}/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                // handle success



                if (response.data.success) {

                    setQuotatioData(response.data.data)


                }
            })
            .catch(error => {
                // handle error

            });
    }

    const onClose = () => {
        setQuotationDetails(false)
    }

    useEffect(() => {
        getAllQuotation()
    }, []);



    return (
        <div className='-mt-5 w-full relative ' >
            {
                quotationDetails && (
                    // <QuotationDetails user={"customer"} quotation={quotation} quotationRequest={qr_id} quotationDetails={quotationDetails} setQuotationDetails={setQuotationDetails} />
                    <ConfirmQuotation quotationRequest={qr_id} quotation={quotation} onClose={onClose} user={"customer"} />
                )
            }
            <table className=' w-full text-center table-auto'>
                <thead className='text-xl'>
                    <tr>
                        <th className='font-semibold' >Company Name</th>
                        <th className='font-semibold' >Validity Days</th>
                        <th className='font-semibold' >Total Price</th>
                        <th  > </th>

                    </tr>
                </thead>

                <tbody className=''>

                    {quotatioData.map((item) => (
                        <tr key={item.id} className=' border-b-[1px] border-b-[#EDEDED]  '>
                            <td className='py-5' >{item.company_name}</td>
                            <td className='py-5 text-[#5C5C5C] text-[13px]' >{item.validity}</td>
                            <td className='py-5 text-[#5C5C5C] text-[15px]' >{item.total}</td>
                            <td className='py-5' ><Button onClick={() => handleViewDeatails(item.id)} bgColor="w-[130px] py-1 rounded-[5px] text-[14px] text-white font-medium bg-[#49B5FD]">View Details</Button></td>
                        </tr>
                    ))}

                </tbody>


            </table>
        </div>
    )
}


export default QuotationList