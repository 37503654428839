import React, { useEffect } from 'react'
import Button from './Button'
import { FaPhoneAlt } from "react-icons/fa";
import Dropdown from './Dropdown';
import { useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import transporterIcon from '../assets/transporterIcon.png'
import customerIcon from '../assets/customerIcon.png'
import { Circles } from 'react-loader-spinner';

const CreateAcc = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn")

  const [userType, setUserType] = useState('customer');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formError, setFormError] = useState("");
  const { action, token } = useParams();
  const [loading, setLoading] = useState(false);



  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFreight, setSelectedFreight] = useState('Customer');

  const freightOptions = [
    {
      name: 'Transporter',
      image: transporterIcon
    },
    {
      name: 'Customer',
      image: customerIcon
    }
  ];
  const handleSelect = (option) => {
    setSelectedFreight(option.name);
    setUserType(option.name.toLowerCase());
    
    

    setIsDropdownOpen(false); // Close dropdown after selection
  };
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };






  const funSelectOption = (option) => {
    // 
    setUserType(option.label.toLowerCase());
    
  }



  const handleLogin = () => {
    
    let valid = true;

    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      setLoading(true);

      
      axios.post(`https://api.freightopedia.world/api/v1/accounts/register`, {
        user_type: userType,
        email: email,
        password: password,
        username: userName,
        company_name: companyName,
        name: name
      })
        .then(response => {
          // handle success
          setLoading(false);
          alert(response.data.message)
          

          window.location = "/login"
          setFormError("");
        })
        .catch(error => {
          // handle error
          
          setFormError("Something went wrong!");
        });
    }
  };



  return (
    <>
      {
        isLoggedIn ? window.location = "/dashboard" : ""
      }

      {loading &&
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
          <div className="w-fit">
            <Circles
              height="80"
              width="80"
              color="#142AA2"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      }
      <div className=' px-14 xs:px-16 sm:px-28 sm2:px-32  md:px-36  md2:px-48 lg:flex lg:flex-row  lg:justify-center lg:px-0  xs:pb-10 '>

        <div className="rounded-[10px] border-[1px] border-[#E6E6E6] shadow-xl px-[15px] text-[20px] flex flex-col gap-5 py-[30px]  lg:w-[450px] ">
          <div className="">
            <h1 className='font-bold' >Create Account</h1>
            <h1 className='text-[15px]' > Get started for free! No credit card required.</h1>
          </div>


          <div className="">
            <div className="">
              {/* email , pass , loginBtn */}
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-[10px]">

                  <div className="flex flex-col ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Company Name</label>
                    <input type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className='border-[#DDDDDD] border-[1px] bg-[#F6F6F6] text-[13px] outline-none placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-2' />
                    {emailError && <span className='text-red-500 text-[12px]'>{emailError}</span>}
                  </div>

                  <div className="flex flex-col ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Name</label>
                    <input type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className='border-[#DDDDDD] border-[1px] bg-[#F6F6F6] text-[13px] outline-none placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-2' />
                    {emailError && <span className='text-red-500 text-[12px]'>{emailError}</span>}
                  </div>

                  <div className="flex flex-col ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Your Email</label>
                    <input type="text"
                      placeholder='email@gmail.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='border-[#DDDDDD] border-[1px] bg-[#F6F6F6] text-[13px] outline-none placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-2' />
                    {emailError && <span className='text-red-500 text-[12px]'>{emailError}</span>}
                  </div>



                  <div className="flex flex-col ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Username</label>
                    <input type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      className='border-[#DDDDDD] border-[1px] bg-[#F6F6F6] text-[13px] outline-none placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-2' />
                    {emailError && <span className='text-red-500 text-[12px]'>{emailError}</span>}
                  </div>

                  <div className="flex flex-col ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Your Password</label>
                    <input type="password"
                      placeholder='Pass@123'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='order-[#DDDDDD] border-[1px] bg-[#F6F6F6] text-[13px] outline-none placeholder:text-[#C5C7C8] placeholder:text-[13px] px-[10px] py-2' />
                    {passwordError && <span className='text-red-500 text-[12px]'>{passwordError}</span>}
                  </div>

                  {/* <div className="flex flex-col ">
                    <label htmlFor="" className='text-[13px] leading-[35px]' >Re-enter Password</label>
                    <input type="text"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className='border-[#DDDDDD] text-[13px] outline-none border-[1px] bg-[#F6F6F6] px-[10px] py-2' />
                  </div> */}
                </div>

                <Button onClick={handleLogin} bgColor="w-full h-[45px]  rounded-[5px] text-[14px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary" >Create Account</Button>

                <div className="flex flex-col gap-5">
                  {/* <Button onClick={handlePhoneLogin}
                    bgColor=" w-full h-[45px] text-black  bg-[#F6F6F6] border-[1px] border-[#DDDDDD] rounded-[5px] text-[14px] font-medium  flex flex-row items-center justify-center gap-[10px]" > <FaPhoneAlt className='text-[#142AA2]' /> Continue with phone</Button> */}
                  <h1 className='text-[13px] text-center ' >Already have an account? <span className='text-[#246BFD] underline '> <a href="/login">Login</a> </span>  </h1>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const container = [
  { label: "Select ", value: "Select" },
  { label: "Transporter", value: "transporter" },
  { label: "Customer", value: "customer" },

]
export default CreateAcc