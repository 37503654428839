import React, { useState } from 'react'
import Footer from '../components/Footer'
import Quoet from '../components/Quoet'
import Services from '../components/Services'
import NewMembers from '../components/NewMembers'
import Nav from '../components/Nav'
import Button from '../components/Button'
import hero_img from '../assets/hero_bg.jpg'
import { GoArrowRight } from "react-icons/go";
import AboutUs from '../components/AboutUs'
import { useNavigate } from 'react-router-dom'
import whatsAppIcon from '../assets/whatsApp.png'


const Hero = () => {
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();

    const handleToggle = () => {
        setToggle(!toggle)
    }

    const handleGetStarted = () => {
        navigate('/login')
    }
    return (
        <div className='relative h-screen w-screen  overflow-x-hidden hide-scrollbar ' >
            <a target="_blank" href="https://wa.me/7303007513">
                <div className="fixed top-3/4 right-0 bg-opacity-50 z-10 flex justify-end items-center cursor-pointer ">
                    <div className="-z-50  text-white bg-[#49B5FD] flex flex-row gap-2 items-center justify-center  py-3 w-[130px] place-self-end rounded-md  ">
                        <img src={whatsAppIcon} alt="" className='w-[20px] h-[20px]' />
                        <h1 className='font-semibold  ' >Ask me</h1>
                    </div>
                </div>
            </a>

            <div className={` w-screen items-center flex flex-col justify-center ${toggle ? 'blur-sm' : ''}`}>
                <div className=" bg-cover bg-center bg-no-repeat w-full py-10 items-center flex flex-col gap-10 justify-center bg-[url('/src/assets/waveImage.png')]">
                    <Nav toggle={toggle} handleToggle={handleToggle}  />
                    <div className={`flex flex-col items-center justify-center px-5 `}>
                        <h1 className=' text-[17px] leading-[30px] xs:text-[22px] xs:leading-[35px] sm:text-[30px] font-normal text-center sm:leading-[50px] text-[#35558F] sm:w-[600px] tracking-wide ' >Get <span className='font-bold'>Free Freight Quotes </span>  <span className='font-normal'  >From <br /> Extensive</span> <span className='font-bold'>Logistics Partner</span>  Directory
                            <br />   for <span className='font-bold'>Efficient</span> Shipping</h1>


                        <Button onClick={handleGetStarted} bgColor=" mt-4  px-10 w-fit h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center " >Get Started <span><GoArrowRight size={20} /></span> </Button>
                    </div>
                </div>


                <div className="w-screen relative flex justify-center">                   
                    <NewMembers  />
                </div>

                <div className="w-screen relative flex justify-center py-10 ">                   
                    <Quoet />
                </div>
                <div className="h-[430px] w-full md:h-[250px]">
                    <Footer />
                </div>

            </div>

        </div>
    )
}

export default Hero