import React, { useState, useEffect } from 'react'
import { IoClose } from "react-icons/io5";
import Button from '../components/Button';
import axios from 'axios';
import { ThreeCircles, Circles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import ty from '../assets/ty.png'


const ConfirmQuotation = ({ quotationRequest, quotation, onClose, user }) => {

    const [quotatioData, setQuotatioData] = useState({})
    const [quotatioCharges, setQuotatioCharges] = useState([])
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [confirmQuotationLoading, setConfirmQuotationLoading] = useState(false);
    const [deleteQuotationLoading, setDeleteQuotationLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const authToken = localStorage.getItem("authToken");
    const navigate = useNavigate();

    const getQuotationData = () => {
        axios.get(`https://api.freightopedia.world/api/v1/logistics/quotation/${quotationRequest}/${quotation}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                // handle success
                
                

                if (response.data.success) {

                    setQuotatioData(response.data.data)
                    setQuotatioCharges(response.data.data.charges)
                    setIsConfirmed(response.data.data.is_confirmed)
                    

                }
            })
            .catch(error => {
                // handle error
                
            });
    }


    const handleConfirmBooking = () => {
        setConfirmQuotationLoading(true);

        if (!isConfirmed) {
            axios.get(`https://api.freightopedia.world/api/v1/logistics/confirm-quotation/${quotation}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("authToken")}`
                }
            })
                .then(response => {
                    // setModal(true);
                    // handle success
                    
                    

                    if (response.data.success) {
                        setConfirmQuotationLoading(false);
                        
                        // handleOff();
                        setIsConfirmed(true)
                        setModal(true);
                        // alert(response.data.message);
                    }
                })
                .catch(error => {
                    // handle error
                    
                });
        }
    }


    const handleDeleteQuotation = () => {
        setDeleteQuotationLoading(true);
        axios.post(`https://api.freightopedia.world/api/v1/logistics/delete-quotation`, {
            q_id: quotation
        }, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        }).then(response => {
            setDeleteQuotationLoading(false);
            // handle success
            
            

            if (response.data.success) {
                
                alert(response.data.message)
                window.location = `/dashboard/quotation`
            }
        })
            .catch(error => {
                // handle error
                
            });
    }

    useEffect(() => {
        getQuotationData()
    }, []);


    // const handleOff = () => {
    //     setModal(false);
    //     navigate("/dashboard/request-quote")
    // }


    return (

        <>




            {deleteQuotationLoading &&
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                    <div className="w-fit">
                        <ThreeCircles
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </div>
            }

            {/* {
                modal && (
                    <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-brightness-100 flex items-center justify-center w-screen h-screen" >
                        <div className="">
                            <h1>Services provider details will share on your register email</h1>
                            <h1>Thankyou for support have a greate day</h1>
                        </div>
                    </div>
                )
            } */}

            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-brightness-100 flex items-center justify-center">
                {confirmQuotationLoading &&
                    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-brightness-100 flex items-center justify-center">
                        <div className="w-fit">
                            <Circles
                                height="80"
                                width="80"
                                color="#142AA2"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </div>
                    </div>
                }

                {
                    modal ? (
                        <div className=" bg-black fixed bg-opacity-50 backdrop-brightness-90 flex flex-col items-center justify-center w-screen h-screen" >
                            <div className="bg-white h-[400px] w-[600px] flex flex-col rounded-lg p-5">
                                <div className="flex justify-end">
                                    <Button bgColor="" onClick={onClose}><IoClose /></Button>
                                </div>


                                <div className=" flex flex-col items-center justify-center h-full w-full text-[15px] ">
                                    <img src={ty} alt="" className='w-[250px] h-[200px]' />
                                    <h1 className='font-bold text-[22px]' >Thank You !</h1>
                                    <h1 className='' >for support have a great day</h1>
                                    <h1 className='' >Services provided details will share on your register email</h1>
                                </div>

                            </div>
                        </div>
                    ) : null
                }


                <div className="bg-white p-5 rounded-lg ">
                    <Button bgColor="float-right" onClick={onClose}><IoClose /></Button>
                    <br /> <br />

                    <div>
                        <h5>Quotation ID : <b> #{quotatioData.quotation_id}</b></h5>
                    </div>

                    <br />

                    <table className='w-full text-center table-auto'>
                        <thead className='text-[15px]'>
                            <tr>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[170px] py-1'>Name of Items</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[100px] py-1'>Quantity</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[100px] py-1'>UOM</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[120px] py-1'>Currency</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[150px] py-1'>Exchange rate</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[100px] py-1'>Rate</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[150px] py-1'>Amount</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[150px] py-1'>Discount</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[80px] py-1'>Tax</th>
                                <th className='font-medium border-[1px] border-[#D6D6D6] px-2 min-w-[180px] py-1'>Taxable Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotatioCharges.map((item, ind) => (
                                <tr key={ind}>
                                    <td className='py-1 text-[#5C5C5C] text-[13px] border-[1px] border-[#D6D6D6] px-2'>{item.name}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[13px] border-[1px] border-[#D6D6D6] px-2'>{item.quantity}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.uom}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.currency}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.exchange_rate}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.rate}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.amount}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.discount}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.tax}</td>
                                    <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2'>{item.taxable_amount}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="9" className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2 font-semibold'>Total Quotation Amount</td>
                                <td className='py-1 text-[#5C5C5C] text-[15px] border-[1px] border-[#D6D6D6] px-2 font-semibold'>{quotatioData.total}</td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <div className='flex flex-row gap-3'>
                        <h5> <b>Remarks : </b> </h5>
                        <p>  {quotatioData.remark}</p>
                    </div>
                    <br />

                    <div className="flex flex-row gap-4 justify-end">
                        {
                            user === "transporter" && (
                                <Button
                                    onClick={handleDeleteQuotation}
                                    bgColor="md:py-2 md:px-5 border-[1px] border-btnbBorder rounded-[5px] bg-[#FF3434] text-[14px] text-white font-medium">
                                    Delete Quotation
                                </Button>
                            )

                        }

                        {
                            user === "customer" && (
                                <Button
                                    bgColor=
                                    {
                                        isConfirmed ? "md:py-2 md:px-5 rounded-[5px] text-[14px] text-white font-medium bg-gray-500" : "md:py-2 md:px-5 rounded-[5px] text-[14px] text-white font-medium bg-[#4AAC27]"
                                    }

                                    onClick={handleConfirmBooking}>
                                    {
                                        isConfirmed ? "Quotation Confirmed" : "Confirm Booking"
                                    }

                                </Button>
                            )

                        }
                    </div>
                </div>


            </div>
        </>
    );
};

export default ConfirmQuotation;
