import React, { useEffect, useState } from 'react'
import Button from '../components/Button';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MdOutlineEdit } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { Link as RouterLink } from 'react-router-dom';
import uploadLogoIcon from '../assets/uploadLogo.png'
import { LineWave } from 'react-loader-spinner';



const MyProfile = () => {


    const [profileData, setProfileData] = useState({});
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem('userData'))

    const navigate = useNavigate();
    const funEditProfile = () => {
        navigate('/dashboard/edit-profile')
    }

    useEffect(() => {
        setLoading(true);
        getItems();
    }, []);

    const getItems = () => {
        axios.get(`https://api.freightopedia.world/api/v1/accounts/profile`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                setLoading(false);

                // handle success

                if (response.data.success) {


                    setProfileData(response.data.data)

                }


            })
            .catch(error => {
                // handle error
            });

    }


    return (
        <>
            {loading &&
                <div className="fixed  h-full w-full p-5 inset-0 flex items-center justify-center rounded-sm bg-opacity-30 bg-black backdrop-brightness-100">
                    <div className=" flex flex-col items-center justify-start overflow-hidden">
                        {/* <LineWave
                            height="80"
                            width="80"
                            color="#142AA2"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /> */}

                        <LineWave
                            visible={true}
                            height="100"
                            width="100"
                            color="#142AA2"
                            ariaLabel="line-wave-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            firstLineColor=""
                            middleLineColor=""
                            lastLineColor=""
                        />
                        <h1 className='text-[#142AA2]'>Loading ...</h1>
                    </div>
                </div>
            }

            <div className='lg:px-10'>

                <h1 className='text-xl font-medium'>My Profile</h1>


                <div className='mt-8 h-full flex flex-col gap-4'>

                    <div className='border p-6 rounded-md flex flex-row items-center gap-8'>

                        <div className='w-28 h-28 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full border-[1px] border-[#246BFD] '>
                            <img src={userData.profile_photo ? userData.profile_photo : uploadLogoIcon} alt="" className='w-full h-full rounded-full' />
                        </div>


                        <div className='flex flex-col gap-2 items-center text-start'>
                            <h1 className='text-2xl lg:text-3xl font-medium' >{profileData.name}</h1>
                            <h1>{profileData.company_name}</h1>
                            <h1 className="text-[#484848] text-start">{profileData.address}</h1>


                            {/* <Button
                            className='w-full md:w-fit bg-sky-100 border border-sky-500 px-8 py-2 rounded-md text-sky-500'
                        >{services} Yang Ming Marine Transport Corporation</Button> */}
                        </div>
                    </div>

                    <div className='border p-6 rounded-md flex flex-col items-start gap-8'>
                        <div className='w-full flex items-center justify-between'>
                            <h2 className='  sm:text-lg font-medium mr-5' >
                                Company Information
                            </h2>
                            <div className="flex flex-row ice gap-5">

                                <RouterLink to="/document">
                                    <Button bgColor=" flex flex-row items-center justify-center gap-1 font-bold  py-2 px-5 lg:py-[10px] lg:px-10 rounded-[5px] text-[15px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary"><CgFileDocument size={18} /> KYC</Button>
                                </RouterLink>

                                <Button onClick={funEditProfile}
                                    bgColor=' px-5 xs:px-5 border-[1px] px-10 py-2 rounded-[5px] border-[#DDDDDD] bg-white flex flex-row gap-2 text-[#6C6C6C] items-center justify-center '
                                >
                                    <MdOutlineEdit size={18} /> Edit
                                </Button>
                            </div>
                        </div>

                        <div className='w-full max-w-4xl flex flex-wrap items-center justify-between md:gap-x-28 gap-y-10'>


                            <span>
                                <h4 className='font-medium'>Services</h4>
                                <p className='text-gray-600'> {profileData.services && Array.isArray(profileData.services)
                                    ? profileData.services.join(', ')
                                    : profileData.services || 'No services available'}</p>
                            </span>

                            <span>
                                <h4 className='font-medium'>Services Sector</h4>
                                <p className='text-gray-600'>{profileData.service_sector && Array.isArray(profileData.service_sector)
                                    ? profileData.service_sector.join(', ')
                                    : profileData.service_sector || 'No services available'}</p>
                            </span>

                            <span>
                                <h4 className='font-medium'>About</h4>
                                <p className='text-gray-600'>{profileData.about}</p>
                            </span>

                            <span>
                                <h4 className='font-medium'>Country</h4>
                                <p className='text-gray-600'>{profileData.country}</p>
                            </span>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyProfile