import React, { useState ,useContext } from 'react'
import SearchContext from '../SearchContext';
import { CiSearch } from "react-icons/ci";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Button from './Button';
import { LuBellRing } from "react-icons/lu";

const SearchBar = () => {
    const { searchQuery, setSearchQuery } = useContext(SearchContext);
    // const [search, setSearch] = useState(' ');
    // const handleSearch = (e) => {
    //     setSearch(e.target.value);

    // }

    return (
        <div className='flex flex-row items-center justify-end gap-2 h-20 max-h-20'>
            <div className="flex flex-row items-center  justify-between border-[1px] border-[#E3E6ED] rounded-[9px]  w-[250px] px-2 py-1 ">
                <input
                    type="text"
                    value={searchQuery}
                    placeholder='Search here....'
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className='text-[13px] flex flex-row items-center outline-none py-2   '
                />
                <CiSearch className='text-[#A2A6AD] font-bold' size={20} />
            </div>

        </div>
    )
}

export default SearchBar