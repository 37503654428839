import React, { useState } from 'react'
import { IoIosMail } from "react-icons/io";
import { Link } from 'react-router-dom';
import Button from './Button';
import axios from 'axios';

const Otp = ({ phoneNumber, onOtpVerified }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();

    if (!otp) {
      setError('OTP is required');
      return;
    }

    // Send OTP to the server for verification
    axios.post(`https://your-api-endpoint.com/verify-otp`, {
      phoneNumber,
      otp
    })
      .then(response => {

        setError('');
        onOtpVerified();
      })
      .catch(error => {

        setError('Invalid OTP. Please try again.');
      });
  };

  return (


    // <div className='px-5 py-5 gap-[100px]'>
    //     <div className="flex flex-col items-center justify-center gap-[25px] ">
    //         <div className="flex flex-row items-center justify-center rounded-full w-[50px] h-[50px] bg-[#49b5fd26] md: ">
    //             <IoIosMail className='text-[#49B5FD] text-[30px]' />
    //         </div>

    //         <div className="flex flex-col gap-[25px] xs:gap-[15px] ">
    //             <div className="flex flex-col items-center justify-center gap-[5px]">
    //                 <h1 className='font-semibold text-center text-[20px] leading-[30px] md:text-[25px] md:leading-[38px] ' >Check your Message</h1>
    //                 <h1 className='text-center text-[13px] leading-[20px] md:text-[16px] md:leading-[25px]' >we have send you an one time password(otp) on  +91 9082326183 this mobile number</h1>
    //             </div>


    //             <form className='flex flex-col items-center justify-center gap-[5px] xs:gap-[5px]' >
    //                 <div className="flex flex-col justify-between py-[5px] gap-[10px]">
    //                     <div className="flex flex-row gap-5">
    //                         <input type="number" className='border-[1px] border-[#B3ABC2] rounded-[10px] w-[50px] h-[50px] text-center font-semibold outline-none ' />
    //                         <input type="number" className='border-[1px] border-[#B3ABC2] rounded-[10px] w-[50px] h-[50px] text-center font-semibold outline-none ' />
    //                         <input type="number" className='border-[1px] border-[#B3ABC2] rounded-[10px] w-[50px] h-[50px] text-center font-semibold outline-none ' />
    //                         <input type="number" className='border-[1px] border-[#B3ABC2] rounded-[10px] w-[50px] h-[50px] text-center font-semibold outline-none ' />
    //                     </div>

    //                     <div className="flex flex-row gap-2 justify-center items-center ">
    //                         <h1 className='text-[13px]' >Not Received OTP yet ? <span className='text-[#246BFD]'>Resend Now</span>  </h1>
    //                     </div>
    //                 </div>

    //                 {/* <button type="submit">Submit</button> */}

    //                 <Button onClick={handleOtp}
    //                  type="submit" bgColor="mt-4 w-[210px] h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center">
    //                     Continue <span></span>
    //                 </Button>

    //             </form>
    //         </div>
    //     </div>
    // </div>

    <div className='px-5 py-5 gap-[100px]'>
      <div className="flex flex-col items-center justify-center gap-[15px]">
        <div className="flex flex-col gap-[15px] xs:gap-[15px]">
          <div className="flex flex-col items-center justify-center gap-[5px]">
            <h1 className='font-semibold text-center text-[20px] leading-[30px] md:text-[25px] md:leading-[38px]'>Enter OTP</h1>
            <h1 className='text-center text-[13px] leading-[20px] md:text-[16px] md:leading-[25px]'>
              We have sent you an OTP on your mobile number.
            </h1>
          </div>

          <form onSubmit={handleOtpSubmit} className='flex flex-col items-center justify-center gap-[5px] xs:gap-[5px]'>
            <div className="flex flex-col gap-[2px]">
              <div className="flex flex-row border-[1px] border-[#49B5FD] rounded-[5px] gap-[5px] md:py-[5px] md:justify-normal md:w-[450px]">
                <div className="flex flex-row items-center py-2">
                  <input
                    type="number"
                    value={otp}
                    onChange={handleOtpChange}
                    className='h-full outline-none px-[10px] border-l-[1px] border-[#B3ABC2] '
                    placeholder="Enter OTP"
                  />
                </div>
              </div>

              {error && <div className='text-red-600 text-[10px]'>{error}</div>}
            </div>

            <Button type="submit" bgColor="mt-4 w-[210px] h-[45px] rounded-[5px] text-[16px] text-white font-medium bg-gradient-to-r from-btnPrimary to-btnSecondary flex flex-row gap-2 items-center justify-center">
              Verify OTP
            </Button>
          </form>
        </div>
      </div>
    </div>










  )
}

export default Otp